import {Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Receipt} from './interface';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {AppApiUrls} from '../../app.api.urls';

@Component({
    selector: 'app-recipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    value = 'https://3303.ua/reviews/?order_id=';
    allData: Receipt;
    @Input() orderId: number;
    loader: boolean;
    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.loader = true;
        this.fetchAll().subscribe({
            next: json => {
                this.allData = (json as any).data;
            },
            complete: () => {
                // this.allData.totalSum = this.allData.products.reduce((a, b) => a + b.price * b.count, 0);
                this.allData.totalSum = this.allData.products.some(product => product.childs && product.childs.length > 0) ?
                    this.calculateTotalSum(this.allData.products) :
                    this.allData.products.reduce((a, b) => a + b.price * b.count, 0);
                this.allData.sale = Math.round((this.allData.totalSum - this.allData.total - this.allData.bonuses));
                // tslint:disable-next-line:max-line-length
                this.allData.salePercent = Math.round((((this.allData.totalSum - this.allData.total - this.allData.bonuses) / this.allData.totalSum) * 100));
                switch (this.allData.terminal)
                {
                    case 'cash':
                        this.allData.terminal = 'QUICK-SALE.CASH';
                        break;
                    case 'cashless':
                        this.allData.terminal = 'QUICK-SALE.CASHLESS';
                        break;
                    case 'card':
                        this.allData.terminal = 'QUICK-SALE.TERMINAL';
                        break;
                    case 'online':
                        this.allData.terminal = 'QUICK-SALE.LIQPAY';
                        break;
                }
                this.loader = false;
            }
        });
    }

    calculateTotalSum(products: any[]): number {
        let totalSum = 0;
        for (const product of products) {
            totalSum += product.price * product.count;
            if (product.childs && product.childs.length > 0) {
                totalSum += this.calculateTotalSum(product.childs);
            }
        }
        return totalSum;
    }

    fetchAll = () => {
        const params = new HttpParams()
            .set('id', String(this.orderId));
        return this.http.get(AppApiUrls.localPrint(), {
            params: {
                id: String(this.orderId)
            }
        });
    }
}
