import {Pipe, PipeTransform} from '@angular/core';
import {Orders} from '../../../quick-sale/all-quick-sale/interface';

@Pipe({
    name: 'paymentFilter'
})
export class PaymentFilterPipe implements PipeTransform {
    transform(value: Orders[], filterValue: string): Orders[] {
        if (filterValue == null) {
            return value;
        }
        return value.filter(item => item.terminalcheck == (filterValue === 'cash') ? 0 : 1);
    }

}
