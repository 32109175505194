import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SiteIndexInterface} from '../interfaces/siteIndex.interface';
import {AppApiUrls} from '../../app.api.urls';

@Injectable({
    providedIn: 'root'
})

export class SiteIndexService {
    constructor(private http: HttpClient) {
    }
    get(): Observable<SiteIndexInterface> {
       return  this.http.get<SiteIndexInterface>(AppApiUrls.siteIndex(), {
           params: {
               city_id: localStorage.getItem('city')
           }
       });
    }
    put(siteIndex: string): Observable<SiteIndexInterface> {
        return this.http.put<SiteIndexInterface>(AppApiUrls.siteIndex(), {
            city_id: localStorage.getItem('city'),
            site_index: siteIndex
        });
    }
}
