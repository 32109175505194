import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reducePipeT'
})
export class ReducePipeTPipe implements PipeTransform {

    transform(value: any, data: { keyArray: string; keySum: string, array: boolean }): number {
        if (!value) {
            return 0;
        }
        let sum: number;
        if (data.array === true) {
            sum = value.map(element => element[data.keyArray].reduce((a, b) =>
                a + b[data.keySum], 0)).reduce((a, b) => a + b, 0).toFixed(2);
        } else if (data.array === false) {
            sum = value[data.keyArray].reduce((a, b) => a + b[data.keySum], 0).toFixed(2);
        }
        return sum;
    }

}
