import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

export interface SearchInterface {
    search: string;
    searchBy: string;
}

@Pipe({
    name: 'searchPipe'
})
export class searchPipe implements PipeTransform {
    transform(obj: any[], data: SearchInterface): any[] {
        if (!data.search) {
            return obj;
        }
        return obj.filter(order => {
            return JSON.stringify(order[data.searchBy])?.toLowerCase().indexOf(String(data.search).toLowerCase()) !== -1;
        });

    }
}
