<div class="container-fluid">
<div class="row">
<div class="col-sm-3">
<ng-container *ngFor="let object of allManuals$ | async; i as index">

    <div (click)="scrollTo('section'+object.id+'')" [ngClass]="{ 'alert-dark': currentSection === 'section'+object.id+''}" class="alert alert-light" role="alert">
      {{object.title}}
    </div>
</ng-container>
</div>

<div class="col-sm-9" >
  <div id="parentDiv" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)" style="height:100%;overflow-y: scroll;width: 100%;word-wrap: break-word;" >
    <ng-container *ngFor="let object of allManuals$ | async; i as index">
    <div id="section{{object.id}}">
      <div class="alert alert-light" role="alert"><h2 style="margin:0">{{object.title}}</h2></div>
       <p style="white-space: pre-wrap"> {{object.description}}</p>
      <div style="width: 100%; text-align: center" ><iframe width="420" height="345" [src]="object.video_link" *ngIf="object.video_link"
                                                            allowfullscreen>
      </iframe></div>

    </div>
    </ng-container>
</div>
</div>
</div>
</div>




