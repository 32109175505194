import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Alltoasts} from '../toasts/alltoasts';
import {tap} from 'rxjs/operators';
import { TextAnswer } from '../system/interface';
import { Observable } from 'rxjs';
import { AppApiUrls } from '../app.api.urls';

@Injectable({providedIn: 'root'})
export class RequestService {
    constructor(private http: HttpClient, private toast: Alltoasts) {
    }
    public loader: boolean;
    getRequest = (url, data?) => {
        this.loader = true;
        data = (data) ? {
            params: data.params ?? '',
            headers: data.headers ?? '',
            toasts: data.toasts ?? null
        } : {
            params:  '',
            headers: '',
            toasts: null
        };
        return this.http.get<any>(url, {
            headers: data.headers,
            params:  data.params
        }).pipe(tap(json => {
        }, response => {
            if (data.toasts){
                this.toast.showError();
            }
            this.loader = false;
        }, () => {
            if (data.toasts){
                this.toast.showSuccess();
            }
            this.loader = false;
        }));
    }

    postRequest = (url, data?) => {
        data = (data) ? {
            params: data.params ?? '',
            headers: data.headers ?? '',
            toasts: data.toasts ?? null
        } : {
            params:  '',
            headers: '',
            toasts: null
        };
        this.loader = true;
        return this.http.post<any>(url, data.params).pipe(tap(() => {
        }, response => {
            this.toast.showError();
            this.loader = false;
            console.log(response);
        }, () => {
            if (data.toasts){
                this.toast.showSuccess();
            }
            this.loader = false;
        }));
    }

    putRequest = (url, data?) => {
        data = (data) ? {
            params: data.params ?? '',
            headers: data.headers ?? '',
            toasts: data.toasts ?? null
        } : {
            params:  '',
            headers: '',
            toasts: null
        };
        this.loader = true;
        return this.http.put<any>(url, data.params).pipe(tap(() => {
        }, response => {
            this.toast.showError();
            this.loader = false;
            console.log(response);
        }, () => {
            if (data.toasts){
                this.toast.showSuccess();
                this.getRequest(url);
            }
            this.loader = false;
        }));
    }
    deleteRequest = (url, data?) => {
        data = (data) ? {
            params: data.params ?? '',
            headers: data.headers ?? '',
            toasts: data.toasts ?? null
        } : {
            params:  '',
            headers: '',
            toasts: null
        };
        this.loader = true;
        return this.http.delete<any>(url, data.params).pipe(tap(() => {
        }, response => {
            this.toast.showError();
            this.loader = false;
            console.log(response);
        }, () => {
            if (data.toasts){
                this.toast.showSuccess();
            }
            this.loader = false;
        }));
    }

    create(body: TextAnswer): Observable<TextAnswer>{
        return this.http.post<TextAnswer>(AppApiUrls.createAnswer(), body);
    }

    

}
