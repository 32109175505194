<div class="container" #collapses="ngbCollapse" [(ngbCollapse)]="reportStorage.isActivated">
    <div class="row justify-content-center">
        <div class="col-sm-4">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{'MODULES.PLAN' | translate}}</h5>
                </div>
                <div class="card-body">
                    <img src="../../../../assets/img/invoices.png" width="30%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="[{ outlets: { reportStorage: ['planned'] }}]" skipLocationChange>{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-sm-4">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{'MODULES.FACT' | translate}}</h5>
                </div>
                <div class="card-body">
                    <img src="../../../../assets/img/invoices.png" width="30%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="[{ outlets: { reportStorage: ['stocks'] } }]" skipLocationChange>{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="col-sm-4">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{'MODULES.COLLECTION' | translate}}</h5>
                </div>
                <div class="card-body">
                    <img src="../../../../assets/img/invoices.png" width="30%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="[{ outlets: { reportStorage: ['collection'] } }]" skipLocationChange>{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>


        <div class="col-sm-4">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{'MODULES.DEVIATION' | translate}}</h5>
                </div>
                <div class="card-body">
                    <img src="../../../../assets/img/invoices.png" width="30%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="[{ outlets: { reportStorage: ['deviation'] } }]" skipLocationChange>{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="col-sm-4">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{'WAYBILLS.WAYBILL' | translate}}</h5>
                </div>
                <div class="card-body">
                    <img src="../../../../assets/img/invoices.png" width="30%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="[{ outlets: { reportStorage: ['waybills'] } }]" skipLocationChange>{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid mt-3">
    <button class="btn btn-outline-success" *ngIf="reportStorage.isActivated" [routerLink]="[{ outlets: { reportStorage: null } }]">{{'GLOBAL.ALL_WAREHOUSE_DOCUMENTS' | translate}}</button>
</div>
<router-outlet name="reportStorage" #reportStorage="outlet"></router-outlet>


