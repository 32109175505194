import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Users} from '../@users/interfaces/users.interface';
import {AppApiUrls} from '../../app.api.urls';
import {EntityInterface} from '../entity.interface';
import {TechCard} from '../../_product-management/interfaces/techcards-product.interface';
import {Personnel} from '../reports/reports-personnel.interface';

@Injectable({
    providedIn: 'root'
})

export class ProfileService implements Omit<EntityInterface<Users>, 'create'|'delete'|'getById'> {

  constructor(
      private http: HttpClient
  )
  {
  }
    update(id, body): Observable<Users> {
        return this.http.put<Users>(AppApiUrls.getAllUsers() + id, body);
    }

    get(): Observable<Users[]> {
      const id = localStorage.getItem('id');
      return this.http.get<Users[]>(AppApiUrls.getPlacesById() + id);
    }

    getCityGroup(): Observable<any[]> {
    return this.http.get<any[]>(AppApiUrls.cityGroup(), {
      params: {
        city_id: localStorage.getItem('city')
      }
    });
    }

    updateCityGroup(idUser, id): Observable<any> {
    const body = {
      group: id
    };
    return this.http.put<any>(AppApiUrls.getAllUsers() + idUser, body);
    }
}

