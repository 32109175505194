import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AppApiUrls} from '../../app.api.urls';
import {RouteListBuilderInterface} from './route-list-builder.interface';
import { Users } from '../@users/interfaces/users.interface';
import { Orders } from 'src/app/quick-sale/all-quick-sale/interface';

@Injectable({
    providedIn: 'root'
})
export class RouteListBuilderService {

    needUpdateRouteList = new Subject<boolean>()

    constructor(private http: HttpClient) {
    }

    create(body: RouteListBuilderInterface): Observable<void> {
        return this.http.post<void>(AppApiUrls.rlBuilder, body);
    }

    getPendingOrders(): Observable<Orders[]> {
        return this.http.get<Orders[]>(AppApiUrls.pendingOrders(),
            {
                params: {
                    place_id: localStorage.getItem('place')
                }
            });
      }

    
    getUsers(): Observable<Users[]> {
        return this.http.get<Users[]>(AppApiUrls.getAllUsers(),{
            params: {
                place_id: localStorage.getItem('place'),
            }
        });
    }    

    setToNeedUpdateRoutList(): void{
        this.needUpdateRouteList.next(true)
    }
}
