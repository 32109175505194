import {ToastService} from './toast-service';
import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})

export class Alltoasts {
    constructor(private toastService: ToastService,
                private translateService: TranslateService) {
    }
    showSuccess = (text?) => {
        this.toastService.show((text) ?? this.translateService.instant('GLOBAL.THAT_GREAT'),
            {classname: 'bg-success text-light', delay: 1500},
            'success');
    }
    showDanger = (text?) => {
        this.toastService.show((text) ?? this.translateService.instant('GLOBAL.ERROR'),
            {classname: 'bg-danger text-light', delay: 15000},
            'danger');
    }
    showError = () => {
        this.toastService.show(this.translateService.instant('GLOBAL.DATA_LOADING_ERROR_PLEASE_CONTACT_THE_ADMINISTRATOR'),
            {classname: 'bg-danger text-light', delay: 15000}, 'error_loading');
    }
}
