import {Component, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {NgxCaptureService} from 'ngx-capture';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Alltoasts} from '../toasts/alltoasts';
import {ExcelExportService} from '../@shared/excel.export.service';
import {NavbarService} from '../@shared/navbar/navbar.service';

@Component({
    selector: 'app-dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.scss']
})
export class DevToolsComponent implements OnInit {

    constructor(private captureService: NgxCaptureService,
                public modalService: NgbModal,
                private toast: Alltoasts,
                public navbarService: NavbarService,
                private excelService: ExcelExportService
    ) {
    }
    public UserPlace: number = Number(localStorage.getItem('place'));
    public exportExcel: { img: string; id: string }[];

    changePlace = () => {
        this.navbarService.changePlace(this.UserPlace);
    }

    ngOnInit(): void {
    }

    excel(id: string): void {
        this.excelService.excel(id);
    }

    findAllTablesOnPage(content): void {
        this.exportExcel = [];
        const allImages = [];
        const tables = Array.from(document.getElementsByTagName('table'));
        tables.forEach(((item, index) => {
            const id = String(Date.now()) + index;
            item.setAttribute('id', id);
            allImages.push(this.captureService.getImage(item, true)
                .pipe(
                    tap(img => {
                        this.exportExcel.push({
                            img,
                            id
                        });
                    })
                ));
        }));
        combineLatest(allImages).subscribe({
            complete: () => {
                this.modalService.open(content, {size: 'xl', scrollable: true});
            }
        });
    }
}
