import {Injectable} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {DateInterface} from './date.interface';

@Injectable({
    providedIn: 'root'
})
export class DateService implements DateInterface {
    model: NgbDate;

    constructor(private formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {
        this.model = calendar.getToday();
    }

    getCurrentDate(): string {
        return this.formatter.format(this.calendar.getToday());
    }

    getFormatDate(): string {
        return this.formatter.format(this.model);
    }

    get FormatDate(): string {
        return this.formatter.format(this.model);
    }

    getJsonDate(): NgbDate {
        return this.model;
    }
}
