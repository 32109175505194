import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AppApiUrls} from '../../../app.api.urls';
import {DateService} from '../../@date/date.service';
import {
    Bills,
    CashInfo,
    CashInfoRL,
    ClientCashAccountingInterface,
    Collections
} from './client-cash-accounting.interface';
import {Collection} from '../../@collection/collection.interface';


@Injectable({providedIn: 'root'})

export class ClientCashAccountingService implements ClientCashAccountingInterface {
    public sumBills: number;
    public bills: Bills[];
    public cashInfo: any;

    constructor(private http: HttpClient, private dateService: DateService) {
    }

    sum = i => {
        this.bills[i].sum = this.bills[i].count * this.bills[i].bills;
        this.sumBills = this.bills.reduce((a, b) => a + (b.sum || 0), 0);
    }

    createCollection(colId: number, billType: string): Observable<any> {
        const body = {
            collection_id: colId,
            state: 'pending',
            payment_method: 'cash',
            bills: this.bills.filter(bill => bill.bill_type == billType).map(bill => {
                bill.bill_id = bill.id;
                bill.bill_rate = bill.bills;
                return bill;
            })
        };
        return this.http.post(AppApiUrls.createCollection(), body).pipe(tap({
            next: value => console.log(value)
        }));
    }

    getBills(): Observable<Bills[]> {
        this.sumBills = 0;
        return this.http.get<Bills[]>(AppApiUrls.GetBills)
            .pipe(tap({
                next: value => this.bills = value
            }));
    }

    getCollections(): Observable<Collections[]> {
        return this.http.get<Collections[]>(AppApiUrls.StartShiftForCollection(), {
            params: {
                place: localStorage.getItem('place'),
                from: this.dateService.getCurrentDate(),
                to: this.dateService.getCurrentDate()
            }
        });
    }

    getCollectionsInfo(id: number): Observable<Collections> {
        return this.http.get<Collections>(AppApiUrls.StartShiftForCollection() + '/' + id, {
            params: {
                place: localStorage.getItem('place'),
                from: this.dateService.getCurrentDate(),
                to: this.dateService.getCurrentDate(),
                user_id: localStorage.getItem('id')
            }
        });
    }

    updateCollections(id: number): Observable<Collections> {
        return this.http.put<Collections>(`${AppApiUrls.StartShiftForCollection()}/${id}`, {
            state: 'out'
        });
    }

    startShift(mode: string): Observable<any> {
        const json = {
            operation_id: 1,
            place_id: localStorage.getItem('place'),
            user_id: localStorage.getItem('id'),
            type: (mode !== 'pickup') ? 'route_list' : 'quick_sale'
        };
        return this.http.post(AppApiUrls.StartShiftForCollection(), json);
    }

    updateCollection(collection: Collection): Observable<any> {
        return this.http.put(`${AppApiUrls.createCollection()}/${collection.id}`, {
            state: 'done',
            ...collection
        });
    }

    createTransfer(currentShiftId: number, targetShiftId: number): Observable<{ message: string }> {
        const data = {
            current_collection_id: currentShiftId,
            place_id: localStorage.getItem('place'),
            city_id: localStorage.getItem('city'),
            target_collection_id: targetShiftId,
            bills: this.bills.map(bill => {
                bill.bill_id = bill.id;
                bill.bill_rate = bill.bills;
                return bill;
            })
        };
        return this.http.post<{ message: string }>(AppApiUrls.cashTransfer(), data);
    }

    getTransfer(currentShiftId: number): Observable<{ cash: number }> {
        return this.http.get<{ cash: number }>(AppApiUrls.cashTransfer() + '/' + currentShiftId);
    }

    getCashInfo(mode: string): Observable<CashInfo | CashInfoRL> {
        return this.http.get<CashInfo>(AppApiUrls.getCashInfo(), {
            params: {
                from: this.dateService.FormatDate,
                to: this.dateService.FormatDate,
                place_id: localStorage.getItem('place'),
                report_type: (mode !== 'pickup') ? 'route_list' : 'quick_sale',
                city_id: localStorage.getItem('city')
            }
        });
    }
}
