import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'QuickSaleSearchPipe'
})
export class QuickSaleSearchPipe implements PipeTransform{
    transform(obj: any[], search: string= ''): any[] {
        if (!search.trim()){
            return obj;
        }
        return  obj.filter(order => {
            return order.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
    }

}
