import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class ExcelExportService {
    constructor(private calendar: NgbCalendar) {
    }

    excel = content => {
        const fileName = `${content}_${this.calendar.getToday().day}.${this.calendar.getToday().month}.${this.calendar.getToday().year}.xlsx`;
        const element = document.getElementById(content);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        ws['!cols'] = [
            {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}
        ];

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    }
}
