<form [formGroup]="form">
    <hr *ngIf="sourceModule !== moduleSource.answers">
    <label class="font-weight-bold">{{ 'GLOBAL.ADD_TITLES_IN_DIFFERENT_LANGUAGES' | translate }}</label>
    <div>
        <ng-container *ngIf="formArrayNameDescription">
            <ng-container formArrayName="descriptions"
                          *ngFor="let object of formArrayNameDescription.controls; index as i">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-sm-3 mb-3 pr-0">
                            <select class="form-control" formControlName="lang">
                                <option [value]="null" disabled selected>
                                    {{ 'GLOBAL.SELECT_A_LANGUAGE' | translate }}
                                </option>
                                <ng-container *ngFor="let obj of getFilteredLanguages(i);">
                                    <option [value]="obj.code">
                                        {{ obj.name }}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div *ngIf="sourceModule === moduleSource.answers" class="col-sm-6">
                            <textarea  class="form-control" formControlName="content" placeholder="{{'SITE.TEXT_OF_REASONS' | translate}}"></textarea>
                        </div>
                        <div *ngIf="sourceModule !== moduleSource.answers" [ngClass]="sourceModule === 'techcards' ? 'col-sm-2' : 'col-sm-4'" class="px-2">
                            <input type="text" class="form-control" formControlName="name" placeholder="{{'INGREDIENTS.TITLE' | translate}}">
                        </div>
                        <div *ngIf="sourceModule === moduleSource.ingredients || sourceModule === moduleSource.semifinish" class="ml-2">
                            <input type="text" class="form-control" formControlName="site_name" placeholder="{{'INGREDIENTS.NAME_FOR_THE_SITE' | translate}}">
                        </div>
                        <div  *ngIf="sourceModule === moduleSource.techcards" class="ml-2">
                            <input type="text" class="form-control" formControlName="crm_name" placeholder="{{'INGREDIENTS.NAME_FOR_THE_SITE' | translate}}">
                        </div>
                        <div  *ngIf="sourceModule === moduleSource.techcards" class="col-sm-4 mb-2 ml-2">
                            <textarea  class="form-control" formControlName="description" placeholder="{{ 'PRODUCT_MANAGEMENT.DESCRIPTION' | translate }}"></textarea>
                        </div>
                        <div *ngIf="sourceModule === moduleSource.city" class="col-sm-4 ml-2 p-0">
                            <input type="text" class="form-control" formControlName="address" placeholder="{{'CITY.ADDRESS' | translate}}">
                        </div>
                        <div class="ml-2">
                            <button class="btn btn-danger"
                                    (click)="removeItem(i)" [disabled]="formArrayNameDescription.controls.length === 1">
                                🗑
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <button type="button" class="btn btn-success"
                    (click)="addItem()" [disabled]="isAddButtonDisabled()"> <p *ngIf="sourceModule !== moduleSource.cityMain && sourceModule !== moduleSource.city" class="mb-0">{{ 'GLOBAL.BUTTONADD' | translate }}</p>
                <svg *ngIf="sourceModule === moduleSource.cityMain || sourceModule === moduleSource.city" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
            </button>
            <button *ngIf="source === 'main'" [hidden]="sourceModule === moduleSource.semifinish" class="btn btn-primary mx-2 float-right"
                    (click)="updateDescription(info.id)"
                    [disabled]="form.invalid || formArrayNameDescription.controls.length === 0"> {{ 'GLOBAL.UPDATE' | translate }}
            </button>
            <button *ngIf="source === 'modal'" type="submit" class="btn btn-success float-right"
                    [disabled]="form.invalid || formArrayNameDescription.controls.length === 0"
                    (click)="createDescription()">{{ 'PAYROLL.CREATE' | translate }}
            </button>
        </ng-container>
    </div>
</form>