import {
    Component, EventEmitter,
    Input, OnChanges, Output
} from "@angular/core";
import {FormControl} from "@angular/forms";


@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnChanges {


    @Input() source: string;
    @Input() imageSrc: string;
    @Input() controlName: string;
    @Input() formControl: FormControl;
    @Output() fileChanged = new EventEmitter<{ image: string, fileName: string }>();

    imageInput: string;
    fileName: string;


    ngOnChanges(): void {
        this.fileName = '';
    }

    handleFileInput(event: Event): void {
        const file = (event.target as HTMLInputElement).files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            this.imageInput = e.target.result;
            this.fileName = file.name;
            this.fileChanged.emit({image: this.imageInput, fileName: this.fileName});
        };

        reader.readAsDataURL(file);
    }
}
