import { Injectable } from '@angular/core';
import { FormArray} from '@angular/forms';
import {LANG, LangData, SelectedLang} from './lang.interface';
import {Observable} from "rxjs";
import {AppApiUrls} from "../../app.api.urls";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class LanguageLogicService {

    constructor(private http: HttpClient) { }

    getFilteredLanguages(formArray: FormArray, langs: LANG[], index: number): LANG[] {
        const existingLanguages = formArray.controls
            .filter((_, i) => i !== index) // Исключаем текущий индекс
            .map(control => control.get('lang')?.value); // Получаем выбранные языки

        return langs?.filter(lang => !existingLanguages.includes(lang.code)); // Фильтруем языки
    }

    isAddButtonDisabled(formArray: FormArray, languages: SelectedLang[]): boolean {
        return formArray.length >= languages?.length;
    }


    getLang(): Observable<LangData> {
        return this.http.get<LangData>(AppApiUrls.lang());
    }
}