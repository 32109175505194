<table *ngIf="service.routeList" style="font-size: 24px; max-width: 300px; min-width: 298px">
    <tbody>
    <tr>
        <td colspan='3'>{{'RL.RL_INFO' | translate}}: {{service.routeList.route_list_id}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.DATE' | translate}}</td>
        <td>{{service.routeList.date_creation}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.ORDERS_NUMBER' | translate}}</td>
        <td>
            {{service.routeList.task}}
        </td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.RL_NUMBER' | translate}}</td>
        <td>{{service.routeList.order_number}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.KM' | translate}}</td>
        <td>{{service.routeList.distance}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.TOTAL_DELIVERY' | translate}}</td>
        <td> {{service.routeList.delivery_total}}</td>
    </tr>
    <tr>
        <td  colspan='2'>{{'RL.TOTAL_NOT_CASH' | translate}}</td>
        <td>{{service.routeList.cashless}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.TOTAL_TO_CASHIER' | translate}}</td>
        <td>{{service.routeList.return_sum}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.COMMENT' | translate}}</td>
        <td></td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.CASHIER' | translate}}:</td>
        <td>{{service.routeList.manager_name}}</td>
    </tr>
    <tr>
        <td colspan='2'>{{'RL.COURIER' | translate}}:</td>
        <td>{{service.routeList.courier_name}}</td>
    </tr>
    </tbody>
</table>
