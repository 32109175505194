import {domains} from '../../environments/environment';

export interface Environment {
    server: string;
    domain: string;
}

export function selectDomain(): string {
    return domains.find(item => item.domain === window.location.origin).server;
}
