import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            const newReq = req.clone({
                params: (req.params ? req.params : new HttpParams())
                    .set('lang', localStorage.getItem('lang') || '')
                .set('login_user_id', localStorage.getItem('id') || '')
                .set('session_time', localStorage.getItem('session_time') || '')
            });
            return next.handle(newReq);
    }
}
