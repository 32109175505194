import {Component} from '@angular/core';
import {HelpPageService} from '../@shared/@help-page/help-page.service';
import {Observable} from 'rxjs';
import {Manuals} from '../@shared/@help-page/manuals.interface';

@Component({
    selector: 'app-help-page',
    templateUrl: './help-page.component.html',
    styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent {
    public allManuals$: Observable<Manuals[]>;
    constructor(private ManualsService: HelpPageService) {
        this.allManuals$ = ManualsService.fetchManuals();
    }
    currentSection = 'section1';
    onSectionChange = (sectionId: string) => {
        this.currentSection = sectionId;
    }
    scrollTo = section => {
        document.querySelector('#' + section)
            .scrollIntoView();
    }
}
