<div class="container">
    <div class="row justify-content-center">
        <ng-container *ngIf="module">
            <ng-container *ngFor="let object of module">
        <div [ngClass]="{'col-sm-4': module.length == 3, 'col-sm-6': module.length == 2, 'col-sm-12': module.length == 1}" class="mx-auto">
            <div style="width: 80%" class="mx-auto card">
                <div class="card-header">
                    <h5 class="card-title">{{object.name}}</h5>
                </div>
                <div class="card-body">
                    <img [ngClass]="{'width-25': module.length == 3, 'width-30': module.length == 2, 'width-10': module.length == 1}"  src="../../assets/img/iconModule/{{object.img}}" alt="">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" [routerLink]="object.url_ang">{{'GLOBAL.TOGO' | translate}}</button>
                </div>
            </div>
        </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!module">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                    <div class="text-center mb-3">
                        <img style="width: 30%;" src="/assets/img/logo.svg" alt="">
                    </div>
                    <div class="font-weight-bold text-center">
                        <span>{{'HOME.WELCOME_TO_CRM' | translate}}</span>
                    </div>
                </div>
                <div class="col-2"></div>
            </div>
        </ng-container>
    </div>
</div>
