import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {  AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { tileLayer, latLng, marker, polyline,  icon  } from 'leaflet';
import { takeUntil } from 'rxjs/operators';
import * as L from 'leaflet';

import { Alltoasts } from 'src/app/toasts/alltoasts';
import { Unsubscribe } from 'src/app/@shared/unsubscribe';
import { RouteListEditService } from '../route-list-edit.service';
import { Calculate, CalculateOrder, CalculateSteps} from '../interface/routlist.interface';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-route-list',
  templateUrl: './edit-route-list.component.html',
  styleUrls: ['./edit-route-list.component.scss' ]
})

export class EditRouteListComponent extends Unsubscribe implements OnInit {
  checks: CalculateOrder[];
  routeId: number;
  calculeteOrder: Calculate;
  form: FormGroup;
  formDistance: FormGroup;
  planTime: string;
  customOrder = 0;
  map: L.Map;
  mapModal: L.Map;
  showMapModal: boolean;
  customMarker: any;
  save: AbstractControl;

  constructor(
    private service: RouteListEditService,
    public modalService: NgbModal,
    private toast: Alltoasts,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRouteId();
    this.calculateOrder(false);
    this.formInit();
    this.formInitDistance();
    this.initializeMap();
  }

  formInit(): void{
    this.form = this.fb.group({
      checks: this.fb.array([])
    });
  }

  setDataToForm(): void{
    const checksFormArray = this.form.get('checks') as FormArray;
    checksFormArray.clear();
    if (this.checks) {
      this.checks.forEach(item => {
        checksFormArray.push(this.generateChecksGroupElement(item));
      });
    }
  }

  generateChecksGroupElement(item: any): FormGroup {
    return this.fb.group(item);
  }

  get formArrayChecks(): FormArray {
    return this.form.get('checks') as FormArray;
  }

  formInitDistance(): void{
    this.formDistance = this.fb.group({
      plan_time: [null]
    });
  }

  setValueForm(): void{
    this.formDistance.get('plan_time').setValue(this.calculeteOrder.plan_time);
  }

  onExecutionTimeChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const selectedDateTime = inputElement.value;
    const formattedDateTime = this.datePipe.transform(selectedDateTime, 'yyyy-MM-dd HH:mm:00');

    this.planTime = formattedDateTime;
    this.formDistance.get('plan_time').setValue(formattedDateTime);
    this.calculateOrder(true);
  }

  calculateOrder(datechanged: boolean): void{
    this.service.calculate(this.routeId , this.customOrder, datechanged, this.planTime)
    .pipe(takeUntil(this.$destroy))
    .subscribe({
      next: value => {
        this.calculeteOrder = value,
        this.checks = value.orders,
        this.setDataToForm();
        this.setValueForm();
        this.addMarkers();
        this.addPolyline();
      },
    });
}

  getRouteId(): void{
    this.route.queryParams.pipe(takeUntil(this.$destroy)).subscribe(params => {
      this.routeId = params.id;
    });
  }

  formatTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    const translatedHours = this.translateService.instant('ROUTLISTEDIT.HOURS');
    const translatedMinutes = this.translateService.instant('ROUTLISTEDIT.MINUTES');
    return `${hours} ${translatedHours} ${remainingMinutes} ${translatedMinutes}`;
  }

  formatedTime(minutes: number): string {
    const hours = Math.trunc(minutes / 60);
    const remainingMinutes = Math.abs(minutes) % 60;
    let formattedTime = '';
    const translatedHours = this.translateService.instant('ROUTLISTEDIT.HOURS');
    const translatedMinutes = this.translateService.instant('ROUTLISTEDIT.MINUTES');
    if (Math.abs(hours) !== 0) {
      formattedTime += `${Math.abs(hours)} ${translatedHours} `;
    }
    formattedTime += `${remainingMinutes} ${translatedMinutes} `;
    return formattedTime;
  }

  drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.formArrayChecks.controls, event.previousIndex, event.currentIndex);
    this.updateOrderChecks();
  }

  updateOrderChecks(): void{
    this.customOrder = 1;
    const updatedOrders = this.formArrayChecks.controls.map((control) => ({
      order_id: control.get('order_id').value,
      route_list_id: this.routeId,
    }));

    this.service.updateOrderNumber({
      route_list_id: this.routeId,
      orders: updatedOrders,
    })
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        complete: () => {
          this.toast.showSuccess();
          this.calculateOrder(false);
        },
      });
  }

  mirrorRoute(): void {
    this.customOrder = 1;

    const updatedOrders = this.formArrayChecks.controls.map((control) => ({
      order_id: control.get('order_id').value,
      route_list_id: this.routeId,
    })).reverse(); // Expanding the array

    this.service.updateOrderNumber({
      route_list_id: this.routeId,
      orders: updatedOrders,
    })
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        complete: () => {
          this.toast.showSuccess();
          this.calculateOrder(false);
        },
      });
  }

  deleteFromRoute(id: number): void{
    this.service.deleteFromRoute(id)
    .pipe(takeUntil(this.$destroy))
    .subscribe({
        complete: () => {
          if (this.calculeteOrder.orders.length - 1 === 0) {
            this.navigateToCreate();
          }else{
            this.calculateOrder(false);
            this.toast.showSuccess();
          }
        },
    });
  }

  readyToSend(routeId: number, distance: number, estimated_turnover: number ): void{
    this.service.readyToSend(routeId, distance , estimated_turnover)
    .pipe(takeUntil(this.$destroy))
    .subscribe({
        complete: () => {
          this.navigateToCreate();
        },
    });
  }

  navigateToCreate(): void{
    this.router.navigate(['routelist/create']);
  }

  getLatenessOrderCount(): number {
    return this.calculeteOrder?.orders.filter(order => order.deadline_deviation < 0).length;
  }

  updateCoordinate(index: number, id: number): void {
    const coordinatesControl = this.formArrayChecks.at(index).get('coordinates');
    if (coordinatesControl) {
      const coordinates = coordinatesControl.value.replace(' ', '');

      this.service.updateLocation(id, coordinates)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          complete: () => {
            this.calculateOrder(false);
          }
        });
    }
  }

  initializeMap(): void {
      this.map = L.map('map').setView([47.8277464, 35.1562575], 13);
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'OpenStreetMap'
    }).addTo(this.map);
  }


  addMarkers(): void {

    const translatedPIN = this.translateService.instant('ROUTLISTEDIT.POINTOFDEPARTURE');

    this.map.eachLayer(layer => {
      if (layer instanceof L.Marker || layer instanceof L.Polyline) {
        this.map.removeLayer(layer);
      }
    });

    if (this.calculeteOrder?.steps) {
      this.checks.forEach(step => {
          const coordinates = step.coordinates.split(',');
          const lat = parseFloat(coordinates[0]);
          const lng = parseFloat(coordinates[1]);
          marker([lat, lng]
          , {
          icon: icon({
            iconSize: [ 40, 40 ],
            iconAnchor: [ 20, 40 ],
            iconUrl: '/assets/img/marker-icon-blue.png',
            shadowUrl: '/assets/img/marker-shadow.png',
          }, )
        }
        ).addTo(this.map!).bindPopup(step.address);
      });
      //
      marker([this.calculeteOrder.steps[0].lat, this.calculeteOrder.steps[1].lng]
        , {
        icon: icon({
          iconSize: [ 40, 40 ],
          iconAnchor: [ 20, 40 ],
          iconUrl: '/assets/img/marker-icon-green.png',
          shadowUrl: '/assets/img/marker-shadow.png',
        }, )
      }
      ).addTo(this.map!).bindPopup(translatedPIN);

    }
  }

  addPolyline(): void {
    if (this.calculeteOrder?.steps) {
      const latLngs = this.calculeteOrder.steps.map(step => latLng(step.lat, step.lng));
      const route = polyline(latLngs, { color: 'red' }).addTo(this.map!);
      this.map!.fitBounds(route.getBounds());
    }
  }


  openMapModal(showMapModal: any, index: number) {
    this.save = this.formArrayChecks.at(index).get('coordinates');
    this.modalService.open(showMapModal, {size: 'xl'});
    this.initializeMapModal();
  }

  closeMapModal(): void {
    this.modalService.dismissAll();
  }


  initializeMapModal(): void {

    this.mapModal = L.map('mapModal').setView([47.8277464, 35.1562575], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'OpenStreetMap'
      }).addTo(this.mapModal);


    const customIcon = L.icon({
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        iconUrl: '/assets/img/marker-icon-purple.png',
        shadowUrl: '/assets/img/marker-shadow.png'
      });

    const [lat, lng] = this.save.value.split(',');
    this.customMarker = L.marker([lat, lng], { icon: customIcon }).addTo(this.mapModal);

      // Focus on the placed marker
    this.mapModal.setView([lat, lng], 13);

      // Adding a map click event handler
    this.mapModal.on('click', (event: L.LeafletMouseEvent) => {
        const latLng = event.latlng;
        const coordinates = `${latLng.lat.toFixed(6)}, ${latLng.lng.toFixed(6)}`;
        this.save.setValue(coordinates);

        // Deleting the previous marker if it exists
        if (this.customMarker) {
          this.mapModal.removeLayer(this.customMarker);
        }

        // Adding a new marker to the map
        this.customMarker = L.marker(latLng, { icon: customIcon }).addTo(this.mapModal);
      });
  }
  copyText(text: string): void {
    const translatedAdress = this.translateService.instant('REPORT_EDITED_HISTORY.ADDRESS');
    const translatedCopy = this.translateService.instant('ROUTLISTEDIT.COPIED');
    navigator.clipboard.writeText(text)
      .then(() => {
        this.toast.showSuccess(translatedAdress + ': ' + text + ' ' + translatedCopy);
      });
  }


}
