import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-packagepanel',
  templateUrl: './packagepanel.component.html',
  styleUrls: ['./packagepanel.component.scss']
})
export class PackagepanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
