<div class="modal-header">
    <h4 class="font-weight-bold">{{ 'QUICK_SALE.QUALITY_CONTROL_CALL' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col d-flex justify-content-between align-items-center">
            <p>{{ 'QUICK_SALE.ORDER' | translate }}{{ orderInfo.order_number }}</p>
            <p>{{ orderInfo.phone }}</p>
            <a href="tel:{{orderInfo.phone}}" *ngIf="this.orderInfo?.quality_recall?.status === 'rejected' || !this.orderInfo?.quality_recall" class="btn btn-success mr-2">{{ 'QUICK_SALE.CALL_BACK' | translate }}</a>
            <div class="d-flex flex-column align-items-center" *ngIf="this.orderInfo?.quality_recall?.status === 'done'">
                <p>{{ 'QUICK_SALE.DATE_AND_TIME_OF_THE_CALL' | translate }}</p>
                <p>{{this.orderInfo?.quality_recall?.created_at | date:'dd.MM.yyyy HH:mm'}}</p>
            </div>
        </div>
        <div class="col">
            <button class="float-right btn btn-success" (click)="openLink()">{{ 'QUICK_SALE.GIFTS_AND_COMPENSATIONS' | translate }}</button>
        </div>
    </div>
    <hr class="my-4">
    <form [formGroup]="form">
        <div class="row">
            <div class=" col-4 form-group">
                <label>{{ 'QUICK_SALE.EVALUATION' | translate }}:</label>
                <div *ngFor="let rating of ratings" class="form-check mt-3">
                    <input
                            type="radio"
                            formControlName="value"
                            class="form-check-input"
                            [value]="rating.value"
                    />
                    <label class="form-check-label ml-2">
                        {{ rating.label }}
                    </label>
                </div>
                <button class="mt-4 btn btn-danger"  *ngIf="this.orderInfo?.quality_recall?.status === 'rejected' || !this.orderInfo?.quality_recall" (click)="postQualityControl('rejected')">{{ 'QUICK_SALE.DOES_NOT_PICK_UP_THE_PHONE' | translate }}</button>
            </div>

            <div class="col-6 form-group">
                <label>{{ 'QUICK_SALE.COMMENT_OPTIONAL' | translate }}:</label>
                <textarea
                        formControlName="comment"
                        class="form-control"
                        rows="3"
                ></textarea>
            </div>
        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-success" *ngIf="this.orderInfo?.quality_recall?.status === 'rejected' || !this.orderInfo?.quality_recall" [disabled]="form.invalid" (click)="postQualityControl('done')">{{ 'QUICK_SALE.SEND' | translate }}</button>
</div>