export interface PendingOrders {
    id: number;
    date_added: string;
    site_number: string;
    phone: string;
    district_id: number;
    zone_id: number;
    street: string;
    house: string;
    attic: string;
    apt: string;
    floor: string;
    stick: string;
    stick_edu: string;
    terminalcheck: number;
    terminal: number;
    sale: number;
    pickupcheck: number;
    birthdaycheck: number;
    futurecheck: number;
    futuredate: string;
    futuretime: string;
    sale_data: any;
    comment: string;
    document_status: string;
    author_id: string;
    author_name: string;
    total: string;
    totalwithoutsale: string;
    place: number;
    city: number;
    one_c_status: string;
    editor: number;
    edit: string;
    route_status: string;
    action: string;
    order_number: number;
    rarus_id: string;
    pickup_pay: number;
    user_delete: number;
    delete_time: string;
    created_at: string;
    updated_at: string;
    google_location:string;
    google_place_id: string;
    checkbox_id: string;
    checkbox_status:string;
    site_user_id:string;
    promocode_id?: number;
    bonuses:number;
    route_list_order:string;
    delivery_zone: DeliveryZone   
}

export interface DeliveryZone{
    id: number,
    name: string,
    city: number,
    place_id: number
}

export interface PendingRoutesData{
    success: true;
    data:PendingRoutes[]
}

export interface PendingRoutes{
    id: number;
    id_manager: number;
    id_courier:string;
    date_creation: string;
    distance: number;
    state:string;
    pay_state:string;
    route_state:string;
    id_cc:number;
    order_number:number;
    place_id:number;
    city_id:number;
    courier_price:string;
    city_price:string;
    created_at:string;
    updated_at:string;
    plan_time:string;
    finish_time:string;
    orders: Orders[];
}

export interface Orders{
    id: number;
    order_id: number;
    route_list_id: number;
    created_at: string;
    updated_at: string;
    plan_time:string;
    real_time:string;
    plan_location:string;
    real_location:string;
    status: string;
    packed?: number;
    order: PendingOrders;
}

export interface SetOrders{
    route_list_id: number;
    orders: SetOrdersData[]
}

export interface SetOrdersData{
    order_id: number;
    route_list_id: number;    
}

export interface SetOrdersToRoute{
    order_id: number;
    routelist_id: number;    
}

export interface Couriers{
    id: number;
    name: string;
}

export interface Calculate{
    routelist_id: number;
    orders: CalculateOrder[]
    distance: number;
    estimated_turnover: number;
    steps: CalculateSteps[]
    courier?: Couriers;
    plan_time: string;
}


export interface CalculateOrder{
    id?: number;
    order_id?: string;
    address?:string;
    phone?: string;
    coordinates: string;
    delivery_deadline?: string;
    deadline_deviation?: number;
    comment?: string;
}

export interface CalculateSteps{
    lat: number;
    lng: number;
}

export interface Location{
    quicksale_id: number;
    google_location: string;
}

export enum OrderEvent {
    LeftBlock = 'leftblock',
    RightBlock = 'rightblock',
    Add = 'add',
    Edit = 'edit'
} 

export enum FilterType {
    All = 'all',
    FutureCheck1 = 'futurecheck1',
    FutureCheck0 = 'futurecheck0'
}

