import {Pipe, PipeTransform} from '@angular/core';

// import {arrayTechCards} from '../../../_product-management/services/techcards-product.service';

@Pipe({
    name: 'techcardsFilter'
})
export class techcardsFilterPipe implements PipeTransform {
    transform(obj: any[], search: string = ''): any[] {
        if (!search.trim()) {
            return obj;
        }
        return obj.filter(order => {
            return order.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
    }

}
