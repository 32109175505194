<div *ngIf="!service.saleData">
    <hr>
    <div><br> <br> <br></div>
    <div class="calculator-container text-center">
        <div class="input-group input-search">
            <input type="text" class="form-control" [(ngModel)]="pservice.searchTerm" (input)="performLiveSearch()">
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" (click)="removeLastDigit()">&#9003;</button>
            </div>
        </div>

        <div class="digits-panel mt-3">
            <div class="row" *ngFor="let row of digitRows">
                <button class="btn btn-secondary digit-button" *ngFor="let digit of row" (click)="addDigitToSearch(digit)">{{ digit }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="service.saleData" >
    <div style="margin-top: 1rem">
        <div class="alert alert-danger">
            <div>
                <span>
                    <b>{{'PACKAGE_PANEL.ALERT' | translate}}</b>
                    {{'PACKAGE_PANEL.ALERT_MESSAGE' | translate: { stick: service.saleData.stick, stick_edu: service.saleData.stick_edu } }}</span>
            </div>
        </div>
        <div class="flex">
            <button type="button" class="btn btn-primary" data-toggle="modal" style="float: left"
                    (click)="open(ModalCookers)"
                    [disabled]="service.saleData.document_status == 4 || service.saleData.document_status == 0">
                {{'PACKAGE_PANEL.CHOOSE_COOK' | translate}}
            </button>
            <button type="button" class="btn btn-primary ml-2" data-toggle="modal" data-target="#packagers"
                    (click)="open(ModalPackagers)"
                    [disabled]="service.saleData.document_status == 4 || service.saleData.document_status == 0">
                <span *ngIf="!service.packagerName">{{'PACKAGE_PANEL.CHOOSE_PACKER' | translate}}</span>
                <span *ngIf="service.packagerName && service.saleData.document_status != 4">{{service.packagerName}}</span>
            </button>
            <button type="button" class="btn btn-danger" data-toggle="modal" style="float: right"
                    (click)="closeModal()">
                X
            </button>
        </div>
    </div>
    <table class="table">
        <thead>
        <tr>
            <th>{{'PACKAGE_PANEL.PRODUCTS' | translate}}</th>
            <th>{{'PACKAGE_PANEL.CHIEF' | translate}}</th>
            <th></th>
            <th *ngIf="service.saleData.document_status == 4">{{'PACKAGE_PANEL.PACKAGER' | translate}}</th>
        </tr>
        </thead>
        <ng-container *ngFor="let order of service.saleData.products">
            <tr>
                <td>{{order.name}}</td>
                <td>
                    <select class="form-control w-100" [(ngModel)]="order.cooker_id"
                            [disabled]="service.saleData.document_status == 4" *ngIf="service.saleData.document_status != 4">
                        <ng-container *ngFor="let cooker of service.arrayCookers?.data">
                            <option  [value]="cooker.id" *ngIf="cooker.alias"> {{cooker.name}} {{cooker.surname}} {{cooker.alias}}</option>
                            <option  [value]="cooker.id" *ngIf="!cooker.alias"> {{cooker.name}} {{cooker.surname}}</option>
                        </ng-container>
                    </select>
                    <span *ngIf="service.saleData.document_status == 4">{{order.cooker_name}}</span>
                </td>
                <td class="text-right" style="padding-right: 0;">
                    <button
                            *ngIf="order.set_products.length"
                            type="button" class="btn btn-outline-primary" (click)="collapse.toggle()"
                            [attr.aria-expanded]="order.collapsed"
                            aria-controls="collapseExample">
                        {{'PACKAGE_PANEL.EXPAND' | translate}}
                    </button>
                </td>
                <td *ngIf="service.saleData.document_status == 4">
                    {{service.saleData.packager_info.name}} {{service.saleData.packager_info.surname}}
                </td>
            </tr>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="order.collapsed" >
                <tr *ngFor="let obj of order.set_products" style="background: #f6f6f6">
                    <td>{{obj.name}}</td>
                    <td>
                        <select class="form-control w-100" [(ngModel)]="obj.cooker_id" *ngIf="service.saleData.document_status != 4"
                                [disabled]="service.saleData.document_status == 4">
                            <ng-container *ngFor="let cooker of service.arrayCookers?.data">
                                <option  [value]="cooker.id" *ngIf="cooker.alias"> {{cooker.name}} {{cooker.surname}} {{cooker.alias}}</option>
                                <option  [value]="cooker.id" *ngIf="!cooker.alias"> {{cooker.name}} {{cooker.surname}}</option>
                            </ng-container>
                        </select>
                        <span *ngIf="service.saleData.document_status == 4">{{obj.cooker_name}}</span>
                    </td>
                </tr>
            </div>
        </ng-container>
    </table>
    <div class="row">
        <div class="col-sm-6 text-center">
            <button class="btn btn-outline-primary mt-2 mb-5 w-100" (click)="packageOrder()"  [disabled]="service.saleData.document_status == 4 || service.saleData.document_status == 0">{{'PACKAGE_PANEL.PACK' | translate}}</button>
        </div>
        <div class="col-sm-6 text-center">
            <button class="btn btn-outline-danger mt-2 mb-5 w-100" (click)="unpackageOrder()"  [disabled]="service.saleData.document_status != 4 || service.saleData.document_status == 0">{{'PACKAGE_PANEL.UNPACK' | translate}}</button>
        </div>
    </div>
    <div class="alert alert-light" role="alert" >
        <span>{{'PACKAGE_PANEL.ADDRESS' | translate}}: {{service.saleData.street}}</span>
        <hr>
        <span>{{'PACKAGE_PANEL.PHONE' | translate}}: {{service.saleData.phone}}</span>
        <hr>
        <span>{{'PACKAGE_PANEL.COMMENT' | translate}}: {{service.saleData.comment}}</span>
    </div>

</div>
<div class="loading" *ngIf="service.loader === true">Loading&#8230;</div>




<ng-template #ModalCookers let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'PACKAGE_PANEL.CHOOSE_COOK' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <div class="row">
           <div class="col-sm-6" *ngFor="let cooker of service.arrayCookers?.data" style="margin-bottom: 0.3rem">
               <button class="btn btn-outline-primary" style="width: 100%" (click)="selectCooker(cooker.id)" *ngIf="cooker.alias">{{cooker.name}} {{cooker.surname}} {{cooker.alias}}</button>
               <button class="btn btn-outline-primary" style="width: 100%" (click)="selectCooker(cooker.id)" *ngIf="!cooker.alias">{{cooker.name}} {{cooker.surname}}</button>
           </div>
       </div>
    </div>
</ng-template>

<ng-template #ModalPackagers let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'PACKAGE_PANEL.CHOOSE_PACKER' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6" *ngFor="let packager of service.arrayPackagers?.data" style="margin-bottom: 0.3rem">
                <button class="btn btn-outline-primary" style="width: 100%" (click)="selectPackager(packager.id, packager.name)">{{packager.name}}</button>
            </div>
        </div>
    </div>
</ng-template>


