import {Injectable} from '@angular/core';
import {User, UserData} from '../interfaces';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppApiUrls} from '../../app.api.urls';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Md5} from 'ts-md5/dist/md5';
import {Alltoasts} from "../../toasts/alltoasts";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) {
    }

    login(user: User): Observable<any> {
        return this.http.get<any>(AppApiUrls.Auth, {
            params: {
                login: String(user.phone),
                password: user.password as string
            }
        })
            .pipe(
                tap(
                    ({data}) => {
                        if (data.id) {
                            localStorage.setItem('id', String(data.id));
                            localStorage.setItem('city', data.user_settings.city);
                            localStorage.setItem('place', data.user_settings.place_info.id);
                            localStorage.setItem('phone', String(data.telephone));
                            localStorage.setItem('profs', data.user_profs.prof_id);
                            localStorage.setItem('name', data.name);
                            localStorage.setItem('image', data.image);
                            localStorage.setItem('prof_name', data.user_profs.prof_info.description.name);
                            localStorage.setItem('type', data.user_settings.place_info.check_type);
                            localStorage.setItem('group', data.user_settings.group);
                            localStorage.setItem('login-time', String(new Date().getTime()));
                            localStorage.setItem('session_time', data.web_session.session_time);
                            localStorage.setItem('city-name', data.user_settings.city_info.description.name);
                            localStorage.setItem('lang', 'ua');
                        }
                    }
                )
            )
            ;
    }

    isAuthenticated(): boolean {
        return !!localStorage.getItem('id');
    }

    logout = () => {
        localStorage.clear();
    }

    fetchModels(): Observable<any>{
        return this.http.get<any>(AppApiUrls.GetAllModules(),
            {
                params: {
                    prof: localStorage.getItem('profs'),
                    city: localStorage.getItem('city')
                }
            });
    }
}
