import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AppApiUrls} from '../../app.api.urls';
import {
  CheckboxCashboxData, CheckboxCashboxInfo,
  CheckboxCashierData, CheckboxCashierInfo,
  CheckboxOrganizationData, CheckboxOrganizationInfo, CheckboxPropertyData, CheckboxPropertyInfo,
} from '../interface/cash-accounting.interface';
import {Place} from '../../system/interface';

@Injectable()

export class CashAccountingCheckboxService {

  placeId = localStorage.getItem('place');
  constructor(private http: HttpClient) { }

  getCheckboxCashbox(): Observable<CheckboxCashboxData> {
    return this.http.get<CheckboxCashboxData>(AppApiUrls.checkboxCashbox());
  }

  getCheckboxCashesByID(id: number): Observable<CheckboxCashboxInfo> {
    return this.http.get<CheckboxCashboxInfo>(AppApiUrls.checkboxCashbox() + '/' + id);
  }

  updateCheckboxCashes(id: number, body: CheckboxCashboxData): Observable<CheckboxCashboxData>{
    return this.http.put<CheckboxCashboxData>(AppApiUrls.checkboxCashbox() + '/' + id , body);
  }

  postCheckboxCashes(body: Partial<CheckboxCashboxData>): Observable<CheckboxCashboxData> {
    return this.http.post<CheckboxCashboxData>(AppApiUrls.checkboxCashbox(), body);
  }
  getCheckboxOrganization(): Observable<CheckboxOrganizationData> {
    return this.http.get<CheckboxOrganizationData>(AppApiUrls.checkboxOrganization());
  }

  getCheckboxOrganizationByID(id: number): Observable<CheckboxOrganizationInfo> {
    return this.http.get<CheckboxOrganizationInfo>(AppApiUrls.checkboxOrganization() + '/' + id);
  }

  updateCheckboxOrganization(id: number, body: CheckboxOrganizationData): Observable<CheckboxOrganizationData>{
    return this.http.put<CheckboxOrganizationData>(AppApiUrls.checkboxOrganization() + '/' + id , body);
  }

  postCheckboxOrganization(body: Partial<CheckboxOrganizationData>): Observable<CheckboxOrganizationData> {
    return this.http.post<CheckboxOrganizationData>(AppApiUrls.checkboxOrganization(), body);
  }

  getCheckboxCashier(): Observable<CheckboxCashierData> {
    return this.http.get<CheckboxCashierData>(AppApiUrls.checkboxCashier());
  }

  getCheckboxCashierByID(id: number): Observable<CheckboxCashierInfo> {
    return this.http.get<CheckboxCashierInfo>(AppApiUrls.checkboxCashier() + '/' + id);
  }

  updateCheckboxCashier(id: number, body: CheckboxCashierData): Observable<CheckboxCashierData>{
    return this.http.put<CheckboxCashierData>(AppApiUrls.checkboxCashier() + '/' + id , body);
  }

  postCheckboxCashier(body: Partial<CheckboxCashierData>): Observable<CheckboxCashierData> {
    return this.http.post<CheckboxCashierData>(AppApiUrls.checkboxCashier(), body);
  }
  getCheckboxProperty(): Observable<any> {
    return this.http.get<any>(AppApiUrls.checkboxPropertyGrouped(), {
      params: {
          place_id: this.placeId
      }
    });
  }

  postCheckboxProperty(body: Partial<CheckboxPropertyInfo>): Observable<CheckboxPropertyInfo> {
    return this.http.post<CheckboxPropertyInfo>(AppApiUrls.checkboxPropertyGrouped(), {
      ...body,
      place_id: this.placeId
    });
  }

  getPlace(): Observable<Place[]> {
    return this.http.get<Place[]>(AppApiUrls.getAllPlaces());
  }

  setWebhook(id: number): Observable<any>{
    return this.http.post<any>(AppApiUrls.setWebhook() + '/' + id, {});
  }

}
