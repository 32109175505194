import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EntityInterface} from '../../@shared/entity.interface';
import {Terminal} from '../interfaces/terminals.interface';
import {HttpClient} from '@angular/common/http';
import {AppApiUrls} from '../../app.api.urls';

@Injectable({
    providedIn: 'root'
})
export class TerminalsService implements EntityInterface<Terminal> {
    constructor(private http: HttpClient) {
    }

    create(body: Partial<Terminal>, ...args): Observable<Terminal> {
        return this.http.post<Terminal>(AppApiUrls.Terminals, body);
    }

    delete(id: number, ...args): Observable<Terminal> {
        return this.http.delete<Terminal>(AppApiUrls.Terminals + id);
    }

    get(...args): Observable<Terminal[]> {
        return this.http.get<Terminal[]>(AppApiUrls.Terminals, {
            params: {
                city_id: localStorage.getItem('city')
            }
        });
    }

    getById(id: number, ...args): Observable<Terminal> {
        return this.http.get<Terminal>(AppApiUrls.Terminals + id, {
            params: {
                city_id: localStorage.getItem('city')
            }
        });
    }

    update(id: number, body: Partial<Terminal>, ...args): Observable<Terminal> {
        return this.http.put<Terminal>(AppApiUrls.Terminals + id, body);
    }

}
