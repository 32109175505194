<thead>
<tr>
    <th>ID</th>
    <th>{{'ROUTLIST-REPORTS.ROUTE_NUMBER'| translate}}:</th>
    <th>{{'ROUTLISTEDIT.DELIVERIES'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.COURIER'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.CREATED'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.DISTANCE_KM'| translate}}</th>
    <th>{{'ROUTLIST-REPORTS.ESTIMATED_TURNOVER_IN_MINUTES'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.DEPARTURE_IN'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.ACTUAL_DELIVERY_TIME'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.ACTUAL_TURNOVER_IN_MINUTES'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.NUMBER_OF_DELAYS'| translate}}:</th>
    <th>{{'ROUTLIST-REPORTS.MANAGER'| translate}}:</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let report of reportsRoutes">
    <td>{{report.id}}</td>
    <td>{{report.order_number}}</td>
    <td>{{report.orders_count}}</td>
    <td>{{report.courier}}</td>
    <td>{{report.created_at| date:'dd.MM.yyyy HH:mm'}}</td>
    <td>{{report.distance}}</td>
    <td>{{report.estimated_turnover}}</td>
    <td>{{report.start_time | date:'dd.MM.yyyy HH:mm'}}</td>
    <td>{{report.finish_time | date:'dd.MM.yyyy HH:mm'}}</td>
    <td>{{report.real_turnover}}</td>
    <td>{{report.violations_count }}</td>
    <td>{{report.manager }}</td>
</tr>
</tbody>