<div class="main">
    <form class="form-signin">
        <div class="row">
            <div class="col-sm-4 pagination-centered" style="background: #2d4855;">

                <img src="../../assets/img/white-logo.png" class="center-block img-responsive d-block mx-auto"
                     width="80%" style="    margin-top: 50%;
    margin-bottom: 50%;">

            </div>
            <div class="col-sm-8 text-center">

                <div ngbDropdown class="nav-item my-2 my-sm-0">
                    <a href (click)="false" class="nav-link"
                       ngbDropdownToggle>{{ 'GLOBAL.LANGUAGE' | translate }}</a>
                    <div ngbDropdownMenu>
                        <button ngbDropdownItem #langSelect *ngFor="let lang of translate.getLangs()"
                                (click)="changeLang(langSelect, lang)" [value]="lang"
                                [class.active]="lang === translate.currentLang">{{ 'GLOBAL.' + lang | translate }}</button>
                    </div>
                </div>

                <h1 class="h3 mb-3 font-weight-normal">{{'LOGIN.SIGN_IN' | translate}}</h1>


                <form
                        [formGroup]="form"
                        (ngSubmit)="onSubmit()">
                    <div class="input-field">
                        <label for="phone"></label>
                        <input id="phone" type="text"
                               formControlName="phone"
                               placeholder="Номер телефону"
                               autocomplete="off">

                        <small class="form-text text-danger"
                               *ngIf="form.get('phone').invalid && form.get('phone').touched ">
                            <small class="form-text text-danger"
                                   *ngIf="form.get('phone').errors['required']">
                                {{'LOGIN.WARNING_EMPTY_PHONE' | translate}}
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="form.get('phone').errors['minlength'] && form.get('phone').errors['minlength']['requiredLength']">
                                {{'LOGIN.WARNING_LENGTH_PHONE' | translate: form.get('phone').errors['minlength']['actualLength'] }}
                            </small>
                        </small>
                    </div>
                    <div class="input-field">
                        <label for="password"></label>
                        <input id="password" type="password"
                               formControlName="password"
                               placeholder="{{'LOGIN.PASSWORD' | translate}}"
                               autocomplete="off"
                        >

                        <small class="form-text text-danger"
                               *ngIf="form.get('password').invalid && form.get('password').touched ">
                            <small class="form-text text-danger"
                                   *ngIf="form.get('password').errors['required']">
                                {{'LOGIN.WARNING_EMPTY_PASSWORD' | translate}}
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="form.get('password').errors['minlength'] && form.get('password').errors['minlength']['requiredLength']">
                                {{'LOGIN.WARNING_LENGTH_PASSWORD' | translate: form.get('password').errors['minlength']['actualLength']}}
                            </small>
                        </small>

                    </div>

                    <button type="submit" class="btn btn-success"
                            [disabled]="form.invalid || form.disabled"
                            style="width: 70%; margin-top: 1rem">
                        {{'LOGIN.LOGIN' | translate}}
                    </button>
                </form>
                <div class="d-flex mt-2 justify-content-center">
                    <a href="https://play.google.com/store/apps/details?id=com.ruslantereshchenko.a3303internal&hl=uk&gl=US#" >  <img class="font-size-img mr-1" src="./assets/img/AndroidBadge.jpg"> </a>
                    <a href="https://apps.apple.com/ua/app/crm-3303/id1551791927?l=ru"><img class="font-size-img ml-1" src="./assets/img/iOSBadge.jpg"></a>
                </div>
            </div>
        </div>

    </form>


</div>





