import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

export interface SearchInterface {
    search: string;
    searchBy: string;
}

@Pipe({
    name: 'searchTwoArgPipe'
})
// tslint:disable-next-line:class-name
export class searchTwoArgPipe implements PipeTransform {
    transform(obj: any[], data: SearchInterface): any[] {
        if (!data.search || !data.searchBy || data.searchBy.length === 0) {
            return obj;
        }

        return obj.filter(order => {
            for (const field of data.searchBy) {
                const fieldValue = order[field];
                if (fieldValue && JSON.stringify(fieldValue).toLowerCase().indexOf(String(data.search).toLowerCase()) !== -1) {
                    return true;
                }
            }
            return false;
        });
    }
}