<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>
          {{'NOT_FOUND.OOPS' | translate}}</h1>
        <h2>
          {{'NOT_FOUND.PAGE_NOT_FOUND' | translate}}</h2>
        <div class="error-details">
          {{'NOT_FOUND.MESSAGE' | translate}}
        </div>
        <div class="error-actions">
          <a routerLink="" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
            {{'NOT_FOUND.MAIN' | translate}} </a><a routerLink="/home" class="btn btn-secondary btn-lg"><span
          class="glyphicon glyphicon-envelope"></span> {{'NOT_FOUND.TECH_SUPPORT' | translate}} </a>
        </div>
      </div>
    </div>
  </div>
</div>
