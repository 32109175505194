import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppApiUrls} from '../../app.api.urls';
import {CallBackData, HistoryOrderById, HistoryOrders} from './history-orders.interface';

@Injectable()
export class HistoryOrdersService {

  constructor(private http: HttpClient) { }

  getOrders(from: string, to: string): Observable<HistoryOrders[]> {
    return this.http.get<HistoryOrders[]>(AppApiUrls.historyOrders(), {
      params: {
        date_from: from,
        date_to: to,
        city_id: localStorage.getItem('city')
      }
    });
  }
  getOrderById(id): Observable<HistoryOrderById> {
    return this.http.get<HistoryOrderById>(AppApiUrls.submitOrderInQS(),
        {
          params: {
            order_id: id
          }
        });
  }

  getCallBack(from: string, to: string): Observable<CallBackData> {
    return this.http.get<CallBackData>(AppApiUrls.callbackRequest(), {
      params: {
        from,
        to,
        city_id: localStorage.getItem('city')
      }
    });
  }
}
