import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, NavigationEnd, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {
    currentRoute: string;
    granted = false;
    dataSource;

    constructor(private auth: AuthService,
                private router: Router) {
        router.events.subscribe((val) => {
            this.checkOnAuth();
        });
    }

    canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
        if (this.auth.isAuthenticated()) {
            return of(true);
        } else {
            this.router.navigate(['/login'],
                {
                    queryParams: {
                        accessDenied: true
                    }
                });
            return of(false);
        }
    };

    checkOnAuth(): void {
        if (localStorage.getItem('login-time') != null)
        {
            if ((Number(String(new Date().getTime())) - Number(localStorage.getItem('login-time')) > 57600000)) {
                localStorage.clear();
                this.router.navigate(['/login'],
                    {
                        queryParams: {
                            accessDenied: true
                        }
                    });
            }
        }
        else {
            localStorage.clear();
            this.router.navigate(['/login'],
                {
                    queryParams: {
                        accessDenied: true
                    }
                });
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }

    test(array, url): void {
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            if (obj.type === 1) {
                if (obj.url_ang === url) {
                    this.granted = true;
                }
            } else {
                this.test(obj.children, url);
            }
        }
    }
    get(): void {
        this.auth.fetchModels()
            .subscribe({
                next: value => this.dataSource = value,
                complete: () => {

                    if (this.dataSource) {
                        this.test(this.dataSource, this.router.url);
                        if (!this.granted) {
                            this.router.navigate(['/'],
                                {
                                    queryParams: {
                                    }
                                });
                        }
                    }
                }
            });
    }
}
