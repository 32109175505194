
<div class="container">
    <hr>
    <button class="btn btn-success mr-2" (click)="navigateToCreate()" >←</button>
    <span class="font-weight-bold h3 align-items-center">{{'ROUTLISTEDIT.ROUTE_CALCULATION' | translate}}: {{routeId}}</span>
    <hr>    
    
    <div class="overflow"
        cdkDropList (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="checks">
        <form [formGroup]="form">
            <div formArrayName="checks" >
                <div *ngFor="let order of formArrayChecks.controls; index as i" [formGroupName]="i">
                    <div class="alert alert-light mx-2" cdkDrag>                
                        <div class="d-flex justify-content-between text-center mb-2">
                            <div>
                                <span class="font-weight-bold">{{'ROUTLISTCREATE.CHECK_NUMBER' | translate}}: </span>
                                <div class="mt-2">{{order.value.order_number}} / id: {{order.value.order_id}}</div>
                            </div>
                            <div>
                                <span class="font-weight-bold ">{{'ROUTLISTEDIT.DELIVERY' | translate}}: </span>
                                <div class="mt-2 copy-text" (click)="copyText(order.value.address)"> {{order.value.address}}</div>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{'WINNER_LIST.PHONE' | translate}}: </span>
                                <div class="mt-2"> {{order.value.phone}}</div>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{'WINNER_LIST.DATE' | translate}}: </span>
                                <div class="mt-2"> {{ order.value.delivery_deadline | date:'dd.MM.yyyy HH:mm' }}</div>
                            </div>
                            <div class="mx-2 " *ngIf="order.value.deadline_deviation < 0">       
                                <span class="text-danger">{{'ROUTLISTEDIT.BEING_LATE' | translate}}: </span>
                                <p class=" mt-2">{{formatedTime(order.value.deadline_deviation)}}</p>
                                
                            </div>
                            <div class="mx-2 " *ngIf="order.value.deadline_deviation >= 0">
                                <span class="text-success mt-2">{{'ROUTLISTEDIT.STOCK' | translate}}: </span>
                                <p class=" mt-2">{{formatedTime(order.value.deadline_deviation)}}</p> 
                            </div>
                            <div>
                                <button class="btn btn-danger trash-icon" (click)="deleteFromRoute(order.value.order_id)" >🗑</button>
                            </div>
                        </div>
                        <hr> 
                        <div class="d-flex justify-content-between ">
                            <div class="mb-2">
                                <span class="font-weight-bold text-danger">{{'ROUTLISTEDIT.SPECIFY_THE_COORDINATES_FOR_ACCURATE_DELIVERY' | translate}}: </span>
                                <input type="text" class="form-control" formControlName = "coordinates" >
                                <button class="btn btn-primary mt-2" (click)="updateCoordinate(i , order.value.order_id)">{{'ROUTLISTEDIT.SAVE_COORDINATES' | translate}}</button>
                                <button class="btn btn-outline-success mt-2 ml-2" (click)="openMapModal(showMapModal, i)">🚩</button>
                            </div>
                            <div class="mb-2 ml-2">
                                <span class="font-weight-bold ">{{'SCHEDULE.COMMENT' | translate}}: </span>
                                <textarea type="text" class="form-control"  cols="60" rows="3" disabled>{{order.value.comment}}</textarea>
                            </div>
                        </div>              
                    </div>
                </div>                    
            </div>
        </form>            
    </div>
    
    <hr>
    <form [formGroup]="formDistance" class="border-class">
        <p class="header">{{'ROUTLISTEDIT.ROUTE_DATA' | translate}}</p>
        <div class="d-flex justify-content-between text-center p-4">
            <div>
                <span class="font-weight-bold">{{'ROUTLISTEDIT.DELIVERIES' | translate}}</span>
                <div class=" mt-2">{{calculeteOrder?.orders.length}}</div>
            </div>
            <div>
                <span class="font-weight-bold">{{'ROUTLISTEDIT.DISTANCE' | translate}}</span>
                <div class=" mt-2">{{calculeteOrder?.distance}} {{'ROUTLISTEDIT.KM' | translate}}</div>
            </div>
            <div>
                <span class="font-weight-bold">{{'ROUTLISTEDIT.TURNOVER_TAKING_INTO_ACCOUNT_SHIPMENT' | translate}}</span>
                <div class=" mt-2"> {{ formatTime(calculeteOrder?.estimated_turnover) }} </div>
            </div>
            <div>
                <span class="font-weight-bold">{{'ROUTLISTEDIT.DATE_AND_TIME_OF_DEPARTURE' | translate}}</span>
                <input  (change)="onExecutionTimeChange($event)" type="datetime-local" formControlName="plan_time" class="form-control width  mt-2">
            </div>
            <div>
                <span class="font-weight-bold">{{'ROUTLISTEDIT.POSSIBLE_DELAYS' | translate}}</span>
                <div class=" mt-2">{{getLatenessOrderCount()}}</div>
            </div>
        </div>
    </form>

    <hr>
    <div class="border-class d-flex justify-content-center align-content-center">
        <button class="btn btn-primary mr-2" (click)="mirrorRoute()">{{'ROUTLISTEDIT.MIRROR_REBUILD_ROUTE' | translate}}</button>
        <button class="btn btn-success" (click)="readyToSend(calculeteOrder?.routelist_id, calculeteOrder?.distance, calculeteOrder?.estimated_turnover)">{{'ROUTLISTEDIT.ROUTE_READY_TO_GO' | translate}}</button>   
    </div>
    <hr>
    <div class="border-class">
        <p class="header">{{'ROUTLISTEDIT.ROUTE_MAP' | translate}}</p>
        <div class="map-container">
            <div class="map-frame">
            <div id="map">

            </div>            
            </div>
        </div>
    </div>
    <hr>
</div>

  <ng-template #showMapModal >
    <div class="modal-header">
        <h5 class="modal-title font-weight-bold">Виберіть місце на карті та поставте маркер після цього натисніть ОК</h5>
        <button type="button" class="close" (click)="closeMapModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="mapModal" style="height: 400px; width: 100%;"></div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeMapModal()">ОК</button>
      </div>
</ng-template>


