import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storagesettings',
  templateUrl: './storagesettings.component.html',
  styleUrls: ['./storagesettings.component.scss']
})
export class StoragesettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
