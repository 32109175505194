import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppApiUrls} from '../../../app.api.urls';
import {RequestService} from '../../request.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AllQuicksaleService} from "../../quick-sale/all-quick-sale/all-quicksale.service";

@Injectable({providedIn: 'root'})

export class PackagePanelService {
    loader: boolean;
    public saleData: any;
    public arrayCookers: any[];
    public arrayPackagers: any[];
    packagerId: string;
    packagerName: string;
    searchTerm: any;


    constructor(
        private request: RequestService,
        private modal: NgbModal,
        httpClient: HttpClient,
        private service: AllQuicksaleService
    ) {
    }

    changeSaleData = () => {
        this.packagerId = null;
        this.packagerName = null;
    }

    fetchOrder = (id, content?) => {
        this.loader = true;
        const order =
            this.request.getRequest(AppApiUrls.GetInfoPackages(id)).subscribe(json => {
                    for (const input of json.products) {
                        if (input.set_products.length) {
                            input.collapsed = true;
                            input.cooker_id = null;
                        }
                    }
                    this.saleData = json;
                    this.changeSaleData();
                },
                () => {
                    this.loader = false;
                },
                () => {
                    if (content) {
                        this.modal.open(content, {size: 'lg'});
                    }
                    this.loader = false;
                    order.unsubscribe();
                });
    }

    updateOrder = (packagerId) => {
        this.saleData.packager_id = packagerId;
        this.saleData.document_status = 4;
        this.saleData.place_id = localStorage.getItem('place');
        this.saleData.city_id = localStorage.getItem('city');
        this.loader = true;
        const data = {
            params: this.saleData
        };
        return this.request.postRequest(AppApiUrls.GetInfoPackages(), data);
    }

    unpackageOrder = () => {
        this.loader = true;
        const order =
            this.request.deleteRequest(AppApiUrls.GetInfoPackages(this.saleData.package_id)).subscribe(json => {
                },
                () => {
                    this.loader = false;
                },
                () => {
                    this.saleData.document_status = 1;
                    this.loader = false;
                    order.unsubscribe();
                    this.service.searchTerm = '';
                });
    }

    allCookers = () => {
        const body = new HttpParams()
            .set('place_id', localStorage.getItem('place'))
            .set('lang', 'ua')
            .set('city', localStorage.getItem('city'));
        const data = {
            params: body
        };
        const allCookers = this.request.getRequest(AppApiUrls.GetCookersPackagePanel, data).subscribe(json => {
            this.arrayCookers = json;
        }, () => {
        }, () => {
            this.allPackagers();
            allCookers.unsubscribe();
        });
    }

    allPackagers = () => {
        const body = new HttpParams()
            .set('place_id', localStorage.getItem('place'))
            .set('lang', 'ua')
            .set('city', localStorage.getItem('city'));
        const data = {
            params: body
        };
        const allPackagers = this.request.getRequest(AppApiUrls.GetPackagersPackagePanel, data).subscribe(json => {
            this.arrayPackagers = json;
        }, () => {
        }, () => {
            allPackagers.unsubscribe();
        });
    }
}

