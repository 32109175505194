<mat-sidenav-container style="background: transparent">
    <mat-sidenav [ngStyle]="{'width': '22rem'}" mode="over" [attr.role]="'navigation'" [fixedInViewport]="true" #sidenav>
        <mat-toolbar>{{'NAVBAR.HEADER' | translate}}</mat-toolbar>
        <div class="input-group-search col-sm-12">
            <div class="input-wrapper">
                <input type="text" class="input-control form-control" [(ngModel)]="searchQuery" (input)="filterTree(searchQuery)" placeholder="{{'GLOBAL.SEARCH' | translate}}">
                <span class="clear-icon" (click)="clearSearch()">&times;</span>
            </div>
        </div>
        <mat-tree *ngIf="dataSource?.data" [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node style="background: #f5f5f5" [ngStyle]="{'cursor': 'pointer', 'padding-left': '0px'}" (click)="sidenav.toggle()" *matTreeNodeDef="let node" matTreeNodePadding [routerLink]="node.url_ang">
                <img *ngIf="node.img" [ngStyle]="{'width': '2rem','padding-left': '0.5rem'}" [src]="'/assets/img/iconModule/' + node.img">
                <img *ngIf="!node.img" [ngStyle]="{'width': '2rem','padding-left': '0.5rem'}" [src]="'/assets/img/information.svg'">
                <button mat-icon-button disabled></button>
                {{node.name}}
            </mat-tree-node>
            <mat-tree-node [ngStyle]="{'padding-left':'0'}" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                {{node.name}}
            </mat-tree-node>
        </mat-tree>
    </mat-sidenav>
    <mat-sidenav-content [style.height]="'98vh'">
        <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top" *ngIf="Auth.isAuthenticated()">
            <button mat-mini-fab color="primary" style="outline: none; background: transparent"
                    (click)="sidenav.toggle()">
                <mat-icon aria-hidden="false" style="color: black">menu</mat-icon>
            </button>
            <a class="navbar-brand ml-3" routerLink="">
               <img src="/assets/img/logo.svg" style="width: 4rem"></a>
            <a class="nav-link"
               style="font-family: 'Roboto', sans-serif;color: black">{{userCity}}</a>
            <button class="navbar-toggler btn btn-outline-light" type="button" data-toggle="collapse"
                    data-target="#navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
                    (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="navbarTogglerDemo02">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse flex-row-reverse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed">
                <form class="form-inline  my-2 my-lg-0">
                    <ul class="navbar-nav media my-2 my-sm-0">

                        <li class="nav-item">
                            <img src="{{UserImg}}" class="round" width="40vh" height="40vh">
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="profile">{{UserName}}</a>
                        </li>
                        <div class="notification-bell" >
                            <mat-icon (click)="toggleNotifications()" class="mt-1">notifications</mat-icon>
                            <div *ngIf="showNotifications" class="notification-popup">
                                <div>
                                    <div class="news-header mb-0">
                                        <input type="checkbox" class="ios-switch" id="b" (change)="toggleUnreadFilter()" style="display: none;">
                                        <label for="b" (click)="toggleUnreadFilter()">
                                            <span class="sw" (click)="toggleUnreadFilter($event)"></span>
                                            {{'NAVBAR.MESSAGES'| translate}}
                                            <span class="small">{{ (!isUnreadFilterActive ? 'NAVBAR.UNREAD_MESSAGES' : 'NAVBAR.ALL') | translate }}</span>
                                        </label>
                                    </div>

                                    <hr class="news-divider mb-2">
                                    <div class="scroll">
                                        <ng-container *ngFor="let item of news?.news">
                                            <div *ngIf="!isUnreadFilterActive ? item?.confirmation.length === 0 : true"
                                                 class="news-item alert"
                                                 (click)="checkedNews(item.id, modal, item.confirmation_required)">
                                                <p>{{ item.title }}</p>
                                                <hr>
                                                <p>{{ item.created_at | date:'dd.MM.yyyy' }}</p>
                                            </div>

                                        </ng-container>
                                        <div *ngIf="showNoUnreadNotifications()" class="news-item d-flex align-items-center justify-content-center flex-column">
                                            <img class="" src="./assets/img/lazy.png">
                                            <p>{{'NAVBAR.THERE_ARE_NO_UNREAD_MESSAGES'| translate}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="unread-count" *ngIf="news?.unread > 0">{{ news?.unread }}</div>
                        </div>
                        <li ngbDropdown class="nav-item my-2 my-sm-0 w-113" [ngClass]="{'disabledNav': disableDropdown}">
                            <a href (click)="false" class="nav-link"
                               ngbDropdownToggle>{{ 'GLOBAL.LANGUAGE' | translate }}</a>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem #langSelect *ngFor="let lang of translate.getLangs()"
                                        (click)="changeLang(langSelect, lang)" [value]="lang"
                                        [class.active]="lang === translate.currentLang">{{ 'GLOBAL.' + lang | translate }}</button>
                            </div>
                        </li>
                    </ul>
                    <img class="nav-item my-2 my-sm-0 ml-3 mr-2" style="width: 1.5rem; cursor: pointer" src="/assets/img/logout.svg" alt="logout" (click)="Auth.logout()" routerLink="login">
                </form>
            </div>
        </nav>
        <button class="dev-tools" cdkDrag mat-fab color="primary" *ngIf="UserProfs == '1'" (click)="openBottomSheet()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #modal>
    <div class="modal-header">
        <h2 class="modal-title font-weight-bold" id="modal-basic-title"> {{newsInfo?.title}} </h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="form-group " >
                <label class="font-weight-bold">{{'NAVBAR.CREATED_BY'| translate}}:</label>
            <div>
                <span>{{newsInfo?.author.name}} {{newsInfo?.author.surname}} ({{newsInfo?.created_at | date:'dd.MM.yyyy' }})</span>
            </div>
        </div>
        <div class="form-group" >
            <label class="font-weight-bold">{{'NEWS_NOTIFICATION.DESCRIPTION' | translate}}:</label>
            <div [innerHTML]="replaceN()"></div>
        </div>
        <div class="form-group">
            <label class="font-weight-bold">{{'NAVBAR.TYPE_OF_NEWS'| translate}}:</label>
            <p *ngIf="newsInfo?.type === 'product'">{{'NEWS_NOTIFICATION.PROGRAMME' | translate}}</p>
            <p *ngIf="newsInfo?.type === 'organization'">{{'NEWS_NOTIFICATION.ORGANISATIONAL' | translate}}</p>
        </div>
        <div *ngIf="newsInfo?.attachments.length !== 0">
            <label  class="m-0" class="font-weight-bold">{{'NAVBAR.FILES_TO_VIEW'| translate}}:</label>
            <div *ngFor="let attachment of newsInfo?.attachments" class="d-flex flex-column">
                <a href="#" class="text-decoration-none" (click)="openAttachment(attachment.url)">{{ getFileName(attachment.url) }}</a>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-success" *ngIf="newsInfo?.confirmation_required === 1" (click)="actionConfirmed(newsInfo.id)">{{'NAVBAR.CONFIRM'| translate}}</button>
    </div>
</ng-template>