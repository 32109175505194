import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storage-settings-home',
  templateUrl: './storage-settings-home.component.html',
  styleUrls: ['./storage-settings-home.component.scss']
})
export class StorageSettingsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
