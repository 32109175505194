import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sumBills'
})
export class SumBillsPipe implements PipeTransform{
    transform(value: any, ...args: any[]): any {
        return value.reduce((a, b) => a + b.bill_rate * b.count, 0);
    }
}
