import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppApiUrls} from '../../app.api.urls';
import {DomSanitizer} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {Manuals, ManualsInterface} from './manuals.interface';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})

export class HelpPageService implements ManualsInterface {
    constructor(private http: HttpClient, private dom: DomSanitizer) {
    }
    fetchManuals(): Observable<Manuals[]> {
        return this.http.get<Manuals[]>(AppApiUrls.GetAllManuals, {
            params: {
                place: '1'
            }
        } ).pipe(map(elements => elements.map(element => {
                element.video_link = (element.video_link) ? this.dom.bypassSecurityTrustResourceUrl(element.video_link as any) : '' as any;
                return element;
            })));
    }
}

