import {Component, Input, OnInit} from '@angular/core';

import {ReportsRoutes} from '../../reports/reports-service.interface';

@Component({
  selector: 'app-reports-routes-table',
  templateUrl: './reports-routes-table.component.html',
  styleUrls: ['./reports-routes-table.component.scss']
})
export class ReportsRoutesTableComponent implements OnInit {

  @Input() reportsRoutes: ReportsRoutes[];
  constructor() { }

  ngOnInit(): void {
  }

}
