export interface Terminal {
    id: number;
    tname: string;
    city: number;
    place: number;
    paid_status: number;
    short_name: null;
    state: TerminalState;
    created_at: Date;
    updated_at: Date;
    city_info: Info;
    place_info: Info;
}

export interface Info {
    id: number;
    name: string;
}

export enum TerminalState {
    Archived = 'archived',
    Active = 'active'
}
