import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAll'
})
export class RoutListSearchPipe implements PipeTransform {

  transform(value: any, searchText: any): any {
    if (!searchText) {
      return value;
    }
    return value.filter((data) => this.matchValue(data, searchText));
  }

  matchValue = (data, value) => {
    const propertiesToSearch = ['order_number', 'street', 'house'];

    return propertiesToSearch.some((key) => {
      return new RegExp(value.replace(/\+/g, '\\+'), 'gi').test(data[key]);
    });
  }
}