import {Observable} from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {map, takeUntil} from 'rxjs/operators';

import {ClientCashAccountingService} from '../../@shared/@cash-accounting/@client-cash-accounting/client-cash-accounting.service';
import {AllQuicksaleService} from '../../@shared/quick-sale/all-quick-sale/all-quicksale.service';
import {Alltoasts} from '../../toasts/alltoasts';
import {Unsubscribe} from '../../@shared/unsubscribe';
import {TransfersCash} from '../../@shared/@cash-accounting/@transfers-cash/transfers-cash.interface';
import {
    CashInfo, CashInfoRL,
    Collections
} from '../../@shared/@cash-accounting/@client-cash-accounting/client-cash-accounting.interface';

@Component({
    selector: 'app-client-cash-accounting',
    templateUrl: './client-cash-accounting.component.html'
})
export class ClientCashAccountingComponent extends Unsubscribe implements OnInit {
    @Input() mode;
    @Output() shiftCollectionId = new EventEmitter<number>();
    buttonModal: string;
    shifts$: Observable<Collections[]>;
    nextCashier: number;
    cashFromBeforeCashier$: Observable<{ cash: number }>;
    returnedInfo: TransfersCash;
    billsType: string;

    constructor(public ClientCAService: ClientCashAccountingService,
                private config: NgbModalConfig,
                public modalService: NgbModal,
                private qservice: AllQuicksaleService,
                private allToasts: Alltoasts) {
        super();
        config.scrollable = true;
    }

    orderId: number;
    collectionId: number;
    collectionDate: Date;
    cashInfo: Observable<CashInfo | CashInfoRL>;

    get sumCard(): number {
        return this.ClientCAService.bills.reduce((a, b) => a + b.bills * b.count, 0);
    }

    chooseCollection(content): void {
        this.modalService.open(content);
    }

    ngOnInit(): void {
        this.shifts$ = this.ClientCAService.getCollections().pipe(map((element) =>
            element.map(value => {
                value.fullname = `${value.user_info.name} ${value.user_info.surname}`;
                return value;
            }).filter(item => item.created_at > this.collectionDate)
        ));
        this.getCollectionId();
    }

    getCashInfo(): void {
        this.cashInfo = this.ClientCAService.getCashInfo(this.mode).pipe(takeUntil(this.$destroy));
    }

    getCollectionId = () => {
        const mode = (this.mode !== 'pickup') ? 'route_list' : 'quick_sale';
        this.ClientCAService.getCollections()
            .pipe(
                map(results => results.find(t => t.type === mode && t.user_id === Number(localStorage.getItem('id'))
                    && t.state === 'on'
                    && t.place_id == Number(localStorage.getItem('place'))))
            ).subscribe(
            {
                next: value => {
                    this.collectionId = (value) ? value.id : null;
                    this.collectionDate = (value) ? value.created_at : null;
                    this.cashFromBeforeCashier$ = this.ClientCAService.getTransfer(this.collectionId);
                },
                complete: () => this.shiftCollectionId.emit(this.collectionId)
            },
        );
    }

    getBills = (content, type, billsType) => {
        this.ClientCAService.getBills()
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.buttonModal = type;
                    this.billsType = billsType;
                    this.modalService.open(content);
                }
            });
    }
    startShift = () => {
        this.ClientCAService.startShift(this.mode).subscribe(element => {
            if (element.message === 'No one is on shift') {
                this.allToasts.showDanger('Нажаль на зміні немає жодного бухгалтера');
            }
            this.getCollectionId();
        });
    }
    endShift = () => {
        this.ClientCAService.updateCollections(this.collectionId)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => this.getCollectionId()
            });
    }
    collection = () => {
        this.ClientCAService.createCollection(this.collectionId, this.billsType)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => this.modalService.dismissAll()
            });
    }

    cashTransfer(content): void {
        this.ClientCAService.createTransfer(this.collectionId, this.nextCashier)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.returnedInfo = value as any,
                complete: () => {
                    this.allToasts.showSuccess();
                    this.modalService.dismissAll();
                    this.modalService.open(content);
                }
            });
    }
}
