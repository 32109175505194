<div class="container-fluid">
    <div style="margin-top: 1rem; margin-bottom: 1rem">
        <form class="form-inline">
            <div class="form-group">
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd"
                           name="dp" [(ngModel)]="service.model" ngbDatepicker navigation="none" #d="ngbDatepicker"
                           (ngModelChange)="updateTable()">
                    <div class="input-group-append">

                        <button class="btn btn-outline-success" (click)="d.toggle()" type="button">

                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 class="bi bi-calendar2-date" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                                <path
                                        d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                            </svg>

                        </button>

                    </div>
                </div>

            </div>
            <div class="form-group ml-2">

                <div class="input-group">
                    <input class="form-control" id="searchTerm" type="text" name="searchTerm"
                           [(ngModel)]="service.searchTerm" placeholder="{{'GLOBAL.SEARCH' | translate}}"/>
                </div>

            </div>
            <app-client-cash-accounting mode="delivery"></app-client-cash-accounting>

                <button type="button" class="btn  btn-primary float-right" (click)="modalService.open(RLBuilder, {size: 'xl'})" mat-list-item>{{'GLOBAL.CREATE' | translate}}</button>
            <button [routerLink]="'create'" type="button" class="btn btn-primary float-right ml-2" mat-list-item>{{'GLOBAL.CREATE' | translate}} {{'GLOBAL.RL' | translate}}</button>
        </form>
    </div>
    <div style="height: 70vh; overflow-y: scroll; overflow-x: hidden">

        <table class="table table-striped table-sm" style="max-height: 100%" width="100%">
            <thead class="thead-light">
            <tr>
                <th class="ios"></th>
                <th scope="col" sortable="route_list_id" (sort)="onSort($event)">ID</th>
                <th scope="col" sortable="order_number"
                    (sort)="onSort($event)">{{'GLOBAL.ORDER_NUMBER' | translate}}</th>
                <th scope="col" sortable="date_creation" (sort)="onSort($event)">{{'GLOBAL.DATE' | translate}}</th>
                <th scope="col" sortable="courier_name"
                    (sort)="onSort($event)">{{'ROUTE-LIST.COURIER_NAME' | translate}}</th>
                <th scope="col" sortable="total_order"
                    (sort)="onSort($event)">{{'ROUTE-LIST.TOTAL_ORDER' | translate}}</th>
                <th scope="col" sortable="cash" (sort)="onSort($event)">{{'ROUTE-LIST.CASH' | translate}}</th>
                <th scope="col" sortable="cashless" (sort)="onSort($event)">{{'ROUTE-LIST.CASHLESS' | translate}}</th>
                <th scope="col" sortable="delivery_total"
                    (sort)="onSort($event)">{{'ROUTE-LIST.DELIVERY_TOTAL' | translate}}</th>
                <th scope="col" sortable="return_sum"
                    (sort)="onSort($event)">{{'ROUTE-LIST.RETURN_SUM' | translate}}</th>
                <th scope="col" sortable="distance" (sort)="onSort($event)">{{'ROUTE-LIST.DISTANCE' | translate}}</th>
                <th scope="col" sortable="task" (sort)="onSort($event)">{{'ROUTE-LIST.TASK' | translate}}</th>
                <th scope="col" >{{'ROUTE-LIST.BEING_LATE' | translate}}</th>
                <th scope="col">{{'WAYBILLS.DO_PAID' | translate}}</th>
                <th scope="col">{{'GLOBAL.TODO' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of countries$ | async; let i = index" (dblclick)="changeIndex(order.route_list_id, content)"
                [contextMenu]="basicMenu" [contextMenuSubject]="order"
                [ngClass]="{'table-warning': order.state === 'pending', 'table-success': order.pay_state === 'done'}">
                <th class="ios" (click)="contextMenu(basicMenuIos, order.route_list_id, order.order_number)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </th>
                <th>{{order.route_list_id}}</th>
                <td>{{order.order_number}}</td>
                <td>{{order.date_creation}}</td>
                <td>{{order.courier_name}}</td>
                <td>{{order.total_order}}</td>
                <td>{{order.cash}}</td>
                <td>{{order.cashless}}</td>
                <td>{{order.delivery_total}}</td>
                <td>{{order.return_sum}}</td>
                <td>{{order.distance}}</td>
                <td>{{order.task}}</td>
                <td>{{violationCount(order)}}</td>
                <td>
                    <svg *ngIf="order.checkbox_need_send == true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-square-fill" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <svg *ngIf="order.checkbox_need_send == false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                    </svg>
                    <button *ngIf="order.pay_state != 'done'" [disabled]="order.pay_state == 'debt'"
                            [ngbPopover]="rlTerminal"
                            [popoverTitle]="rlTerminalTitle"
                            triggers="manual"
                            #p1="ngbPopover"
                            [autoClose]="false"
                            (click)="openPopover(p1, order, 'debt')"
                            placement="left-bottom"
                            container="body"
                            class="ml-2 mr-2 btn btn-primary btn-sm">{{'RL.IN_DEBT' | translate}}</button>
                    <ng-container *ngIf="order.pay_state != 'debt'">
                        <button class="btn btn-sm"
                                [ngClass]="{'btn-success': order.pay_state === 'done', 'btn-danger': order.pay_state === 'pending'}"
                                [ngbPopover]="rlTerminal"
                                [popoverTitle]="rlTerminalTitle"
                                triggers="manual"
                                #p1="ngbPopover"
                                [autoClose]="false"
                                (click)="openPopover(p1, order, 'pay')"
                                placement="left-bottom"
                                container="body">
                            <span *ngIf="order.pay_state === 'pending'">{{'RL.PAY' | translate}}</span>
                            <span *ngIf="order.pay_state === 'done'">{{'RL.PAYED' | translate}}</span>
                        </button>
                    </ng-container>
                    <ng-template #rlTerminal>
                        <ng-select [items]="terminals$ | async"
                                   bindLabel="short_name"
                                   bindValue="id"
                                   tabIndex="999"
                                   placeholder="Оберіть термінал"
                                   notFoundText="Не знайдено"
                                   [(ngModel)]="terminalId">
                        </ng-select>
                        <button class="btn btn-sm btn-success"
                                [disabled]="!terminalId"
                                (click)="updatePaymentState(order)"
                                style="width: 100%; margin-top: .3rem">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
                    </ng-template>
                    <ng-template #rlTerminalTitle>
                        <b style="color: black">{{'RL.CHOOSE_TERMINAL' | translate}}</b>
                    </ng-template>
                </td>
                <td>
                    <button class="btn btn-sm btn-outline-success"
                            [ngClass]="{'btn-success': order.state === 'pending'}"
                            (click)="printThisRoute(order.route_list_id)">{{'GLOBAL.PRINT' | translate}}</button>
                    <button class="btn btn-danger edit-icon" [hidden]="order.editable === false" (click)="backToRL(order.route_list_id)">&#9998;</button>
                </td>
            </tr>

            </tbody>
        </table>
    </div>
    <form style="padding-top: 1rem">
        <div class="row">
            <div class="col">
                <ngb-pagination
                        [collectionSize]="(total$ | async)!" [maxSize]="5" [(page)]="service.page"
                        [pageSize]="service.pageSize">
                </ngb-pagination>
            </div>
            <div class="col">
                <ng-container *ngIf="service.loader$ | async;else loaded">
                    <button class="btn btn-primary" type="button" disabled>
                        {{'GLOBAL.UPDATE' | translate}}
                        <span class="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true"></span>
                    </button>
                </ng-container>
                <ng-template #loaded>
                    <button class="btn btn-primary" type="button" disabled>
                        {{'QUICK_SALE.DOWNLOADED' | translate}}
                    </button>
                </ng-template>
            </div>
            <div class="col">

            </div>
            <div class="col">

            </div>
            <div class="col">
                <select class="custom-select" id="pageSize" style="width: auto; float: right"
                        name="pageSize"
                        [(ngModel)]="service.pageSize">
                    <option [ngValue]="10">По 10</option>
                    <option [ngValue]="25">По 25</option>
                    <option [ngValue]="50">По 50</option>
                    <option [ngValue]="100">По 100</option>
                    <option [ngValue]="250">По 250</option>
                    <option [ngValue]="999999999999999999999">Все</option>
                </select>
            </div>
        </div>
    </form>
</div>


<ng-template #content let-modal>
    <div class="loading" *ngIf="loader === true">Loading&#8230;</div>
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" id="modal-basic-title">{{'RL.GENERAL_INFO' | translate}} <br> {{'ROUTE-LIST.START_TIME'| translate}}: {{service.AllOrders[index].start_time| date: 'dd.MM.yyyy HH:mm:ss'}} <br> {{'ROUTE-LIST.END_TIME'| translate}}: {{service.AllOrders[index].finish_time| date: 'dd.MM.yyyy HH:mm:ss'}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModalHandler(); modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <table class="table table-sm" width="100%" id="table-report-route-list">
                <thead class="thead-light">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">№</th>
                    <th scope="col">{{'GLOBAL.STREET' | translate}}</th>
                    <th scope="col">{{'ROUTE-LIST.PAYMENT_TYPE' | translate}}</th>
                    <th scope="col">{{'GLOBAL.PHONE' | translate}}</th>
                    <th scope="col">{{'GLOBAL.SUM' | translate}}</th>
                    <th scope="col">{{'GLOBAL.DATE' | translate}}</th>
                    <th scope="col">{{'GLOBAL.COMMENT' | translate}}</th>
                    <th scope="col"> {{'ROUTE-LIST.DETAILS' | translate}}</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let object of service.AllOrders[index].orders">
                        <tr [ngClass]="{'alert-danger': object.violation, 'alert-success': !object.violation}">
                            <td>{{object.order_id}}</td>
                            <td>{{object.order_number}}</td>
                            <td>{{object.address}}</td>
                            <td *ngIf="object.payment_type == 0">Готівка</td>
                            <td *ngIf="object.payment_type == 1">БН</td>
                            <td>{{object.phone}}</td>
                            <td>{{object.sum}}</td>
                            <td>{{object.created_at}}</td>
                            <td>{{object.comment}}</td>
                            <td>
                                <button (click)="toggleAdditionalInfo(object)" class="btn btn-link sel">&#9662;</button>
                            </td>
                            <td>
                                <button [hidden]="object.checkbox_need_send === false" (click)="sendRRO(object.order_id, checkBoxPrintByOrder, object.order_number)" class="btn btn-primary btn-sm">РРО</button>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-warning" *ngIf="object.status !== 0 && object.checkbox_status === 'DONE'"
                                        (click)="printCheckBox(object.checkbox_id, checkBoxPrintByOrder, object.order_number)">
                                    РРО
                                </button>
                            </td>

                        </tr>                    
                        <tr *ngIf="object.showAdditionalInfo">
                            <td colspan="10">
                                <table class="additional-info-table d-flex">
                                    <div>
                                        <p>{{'ROUTE-LIST.PLANTIME' | translate}}: {{object.plan_time | date: 'dd.MM.yyyy HH:mm:ss'}}</p>
                                        <p>{{'ROUTE-LIST.REALTIME' | translate}}: {{object.real_time | date: 'dd.MM.yyyy HH:mm:ss'}}</p>
                                        <p *ngIf="object.real_location !== null">{{'ROUTE-LIST.DEVIATION_FROM_THE_PLANNED_POINT' | translate}}: <br> {{distance}} {{'ROUTE-LIST.METERS' | translate}}.</p>
                                        <p *ngIf="object.status === 'delivered'" class="font-weight-bold"> {{'ROUTE-LIST.STATUS' | translate}}:{{'QUICK_SALE.DELIVERED' | translate}}</p>
                                        <p *ngIf="object.status === 'pending'" class="font-weight-bold"> {{'ROUTE-LIST.STATUS' | translate}}: {{'ROUTE-LIST.PENDING' | translate}}</p>
                                        <p *ngIf="object.status === 'canceled'" class="font-weight-bold"> {{'ROUTE-LIST.STATUS' | translate}}: {{'ROUTE-LIST.CANCELED' | translate}}</p>
                                    </div>
                                    
                                    <div class="map-container">
                                        <div class="map-frame" >
                                            <div id="map"></div>            
                                        </div>
                                    </div>
                                </table>
                                <div class="d-flex align-items-center">
                                    <div class="input-group-search my-2 p-0 flex-grow-1">
                                        <input class="form-control mr-2 " placeholder="{{'ROUTE-LIST.REASON_FOR_POSSIBLE_DELAY' |translate}}" [(ngModel)]="object.delay_comment"/>
                                        <span class="clear-icon" (click)="clear(object)">&times;</span>
                                    </div>
                                    <button class="btn btn-success mx-2 flex-shrink-0" (click)="postDelayComment(object.order_id, object.delay_comment)">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
                                </div>

                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <h5 style="width: 100%; text-align: center">{{service.AllOrders[index].courier_name}}</h5>
        </form>
    </div>
</ng-template>

<context-menu #basicMenu>
    <ng-template contextMenuItem let-order>
        <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
            ID:{{order.route_list_id}}/№{{order.order_number}}
        </button>
        <hr>
        <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                (click)="changeIndex(order.route_list_id, edit)">
            {{'GLOBAL.BUTTONEDIT' | translate}}
        </button>
        <hr>
        <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                (click)="deleteRoute(order.route_list_id)" >
            {{'ROUTE-LIST.CLOSE_RL' | translate}}
        </button>
        <hr *ngIf="prof == '1'">
        <button *ngIf="prof == '1'" class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                (click)="showPathModal(showPath, order.route_list_id)" >
            {{'ROUTE-LIST.SHOWPATH' | translate}}
        </button>
    </ng-template>
</context-menu>

<ng-template #edit let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'GLOBAL.BUTTONEDIT' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModalHandler(); modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal-body">
        <form>
            <div class="form-group ml-2 d-flex justify-content-between">

                <div class="input-group ">
                    <div>
                        <label>{{'ROUTE-LIST.DISTANCE' | translate}}: </label>
                    <input type="number" class="form-control" [(ngModel)]="service.AllOrders[index].distance"
                           [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="input-group">
                    <div>
                        <label>{{'ROUTE-LIST.PRICE_PER_ORDER' | translate}}: </label>
                        <input type="number" class="form-control" [(ngModel)]="service.AllOrders[index].courier_price"
                               [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="input-group">
                    <div>
                        <label>{{'ROUTE-LIST.PRICE_PER_KILOMETER' | translate}}: </label>
                    <input type="number" class="form-control" [(ngModel)]="service.AllOrders[index].city_price"
                           [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="input-group w-100">
                    <div>
                        <label>{{'ROUTE-LIST.CHOOSE_COURIER' | translate}}: </label>
                        <select class="form-control w-100" [(ngModel)]="service.AllOrders[index].id_courier" [ngModelOptions]="{standalone: true}">
                            <option [value]="null" disabled selected>{{ 'ROUTE-LIST-BUILDER.COURIERS' | translate }}</option>
                            <ng-container *ngFor="let courier of couriers">
                                <option  [value]="courier.id">
                                    {{ courier.name }} {{ courier.surname }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex mb-2 ml-2">
                <div>
                    <label>{{'ROUTE-LIST.CHOOSE_A_REASON' | translate}}: </label>
                    <select class="form-control w-100" [(ngModel)]="purposeId"  [ngModelOptions]="{standalone: true}">
                        <option [value]="null" selected>{{'ROUTE-LIST.CHOOSE_A_REASON' | translate}}</option>
                        <ng-container *ngFor="let purpose of editPurposes">
                            <option [value]="purpose.id">
                                {{ purpose.description.description }}
                            </option>
                        </ng-container>
                    </select>
                </div>
                <div class="ml-2"  style="width: 100%">
                        <label>{{'ROUTE-LIST.DESCRIBE_THE_REASON_IN_DETAIL' | translate}}: </label>
                        <input type="text" placeholder="{{'ROUTE-LIST.DESCRIBE_THE_REASON_IN_DETAIL' | translate}}" class="form-control mb-2" [(ngModel)]="comment"
                               [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <table class="table" width="100%">
                <thead class="thead-light">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">№</th>
                    <th scope="col">{{'GLOBAL.STREET' | translate}}</th>
                    <th scope="col">{{'GLOBAL.PHONE' | translate}}</th>
                    <th scope="col">{{'ROUTE-LIST.PAYMENT_TYPE' | translate}}</th>
                    <th scope="col">{{'GLOBAL.DATE' | translate}}</th>
                    <th scope="col">{{'GLOBAL.SUM' | translate}}</th>
                    <th scope="col">{{'GLOBAL.COMMENT' | translate}}</th>
                    <th scope="col">{{'GLOBAL.TODO' | translate}}</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let object of service.AllOrders[index].orders; let i = index">
                    <td>{{object.id}}</td>
                    <td>{{object.order_number}}</td>
                    <td>{{object.address}}</td>
                    <td>{{object.phone}}</td>
                    <td *ngIf="object.payment_type == 0">Готівка</td>
                    <td *ngIf="object.payment_type == 1">БН</td>
                    <td>{{object.created_at}}</td>
                    <td>{{object.sum}}</td>
                    <td>{{object.comment}}</td>
                    <td>
                        <button class="btn btn-danger btn-sm"
                                (click)="deleteFromRoute(i)">{{'GLOBAL.BUTTONDELETE' | translate}}</button>
                    </td>
                </tr>
                <div class="form-group ml-2 w-auto" >

                    <div class="input-group w-auto">
                        <input type="number" class="form-control custom-select-sm w-auto" [(ngModel)]="orderNumber"
                               [ngModelOptions]="{standalone: true}" placeholder="Введіть номер чека">
                    </div>

                </div>
                <div class="form-group ml-2">

                    <div class="input-group">
                        <button class="btn btn-success btn-sm" (click)="addToRoute()"
                                [disabled]="!orderNumber">{{'GLOBAL.BUTTONADD' | translate}}</button>
                    </div>

                </div>
                </tbody>
            </table>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" [disabled]="!purposeId || !comment "
                (click)="saveEditableRoute()">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
    </div>
</ng-template>

<app-loader *ngIf="service.loader === true"></app-loader>

    <ng-template #basicMenuIos>
        <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
            ID:{{order.route_list_id}}/№{{order.order_number}}
        </button>
        <hr>
        <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                (click)="changeIndex(order.route_list_id, edit)" >
            {{'GLOBAL.BUTTONEDIT' | translate}}
        </button>
        <hr>
        <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                (click)="deleteRoute(order.route_list_id)" >
            {{'ROUTE-LIST.CLOSE_RL' | translate}}
        </button>
        <button class="btn btn-danger" (click)="close()">{{'RL.CLOSE' | translate}}</button>
    </ng-template>

<ng-template #checkBoxPrintByOrder>
    <div id="checkboxByOrder" class="modal-body text-center">
        <p style="font-size: 28px">{{CheckBoxOrderNumber}}</p>
        <img style="width: 400px" src="https://api.checkbox.in.ua/api/v1/receipts/{{idCheckBoxByOrder}}/png?width=32&paperWidth=80" alt="">
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="close()">{{'RL.CLOSE' | translate}}</button>
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="checkboxByOrder"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>
<div class="loading" *ngIf="loader === true">Loading&#8230;</div>

<ng-template #RLBuilder let-modal>
    <div class="modal-header">
        {{'DEVTOOL.CONSTRUCTOR_RL' | translate}}
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <app-route-list-builder></app-route-list-builder>
    </div>
</ng-template>

<ng-template #showPath let-modal>
    <div class="modal-header">
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="map" style="height: 400px; width: 100%;"></div>
    </div>
</ng-template>

