import {Component, OnInit} from '@angular/core';
import {PackagePanelService} from '../../@shared/packagepanel/packagepanel-leftblock/packagepanel-leftblock.service';
import {PackagepanelMainblockComponent} from '../packagepanel-mainblock/packagepanel-mainblock.component';
import {itemPPMBService} from '../../@shared/packagepanel/packagepanel-mainblock/packagepanel-mainblock.service';
import {AllQuicksaleService} from '../../@shared/quick-sale/all-quick-sale/all-quicksale.service';
import {Observable} from 'rxjs';
import {Orders} from '../../quick-sale/all-quick-sale/interface';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-packagepanel-leftblock',
    templateUrl: './packagepanel-leftblock.component.html',
    styleUrls: ['./packagepanel-leftblock.component.scss']
})
export class PackagepanelLeftblockComponent implements OnInit {
    private data: any;
    constructor(public modalService: NgbModal, public pservice: PackagePanelService, public service: AllQuicksaleService, private  modal: NgbModal) {
        this.orders$ = service.orders$;
    }

    orders$: Observable<Orders[]>;
    selectedOrder: number;
    viewAllOrders = false;
    openOrder = false;
    orderId: number;
    ngOnInit(): void {
        this.service.pageSize = 9999999999;
        this.updateList();
    }

    onPrint = (id, content) => {
        this.orderId = id;
        this.modalService.open(content, {scrollable: true});
    }

    selectCookerFromFastPackage = id => {
        this.pservice.saleData.cooker_id = id;
        for (const input of this.pservice.saleData.products){
            input.cooker_id = id;
        }
        this.checkOnAllParams();
    }
    checkOnAllParams = () => {
       if (this.pservice.saleData.cooker_id && this.pservice.saleData.packager_id){
           this.modal.dismissAll();
           const order = this.pservice.updateOrder(this.pservice.saleData.packager_id).subscribe(json => {
               },
               () => {
                   this.pservice.loader = false;
               },
               () => {
                   this.pservice.loader = false;
                   this.pservice.saleData = null;
                   this.service.fetchAll();
                   order.unsubscribe();
               });
       }
    }
    selectPackagerFromFastPackage = id => {
        this.pservice.saleData.packager_id = id;
        this.checkOnAllParams();
        this.service.searchTerm = '';
    }
    changePlace = () => {
        this.service.paramsForOrders.lastUpdatedAt = '';
        this.service.paramsForOrders.lastId = '';
        this.updateList();
    }

    switchToViewAllOrders = () => {
        this.viewAllOrders = !this.viewAllOrders;
    }

    updateList = () => {
        this.service.fetchAll().subscribe();
    }

    openInfo = (id, content?) => {
        this.openOrder = true;
        this.selectedOrder = id;
        this.pservice.fetchOrder(id, content);
    }


}

