import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppApiUrls} from '../../../../app.api.urls';
import {AppService} from '../../../app.service';
import {Alltoasts} from '../../../../toasts/alltoasts';

@Injectable({providedIn: 'root'})

export class AccountantCashAccountingFull {

    // @ts-ignore
    model: NgbDateStruct = this.appService.GetDate()
        .subscribe(result => {
            // @ts-ignore
            this.model = result;
        });

    public allOrders: any;
    public AllCostType: any;
    public operation = '1';
    public comment = ' ';
    public cash: number;

// Получение списка всех операций с кассой Шефа
    fetchAll = () => {
        return this.http.get<any>(AppApiUrls.GetCashAccounting, {
            params: {
                place: localStorage.getItem('place'),
                payment_method: '2',
                date: this.model.year + '-' + this.model.month + '-' + this.model.day
            }
        }
            )
            .subscribe(
                (val) => {
                    this.allOrders = val as any;
                },
                response => {
                    this.Alltoasts.showError();
                },
                () => {
                });
    }

// Создание затрат или доходности
    InsertOrWasteCash = k => {
        const body = new HttpParams()
            .set('place', localStorage.getItem('place'))
            .set('payment_method', '2')
            .set('operation', String(this.operation))
            .set('comment', String(this.comment))
            .set('city', localStorage.getItem('city'))
            .set('cash', String(this.cash * k))
            .set('user_id', localStorage.getItem('id'))
            .set('other_data', '0')
            .set('status', '1')
            .set('date', this.model.year + '-' + this.model.month + '-' + this.model.day);

        return this.http.post<any>(AppApiUrls.InserOrWasteCash,
            body)
            .subscribe(
                (val) => {
                },
                response => {
                    console.log('POST call in error', response);
                    this.Alltoasts.showDanger();

                },
                () => {
                    this.modalService.dismissAll();
                    this.fetchAll();
                    this.Alltoasts.showSuccess();

                });
    }

// Подтверждение инвентаризации
    ApplyCollection = id => {
        const body = new HttpParams()
            .set('id', id)
            .set('status', '1');
        return this.http.post<any>(AppApiUrls.ApplyCollection,
            body)
            .subscribe(
                (val) => {

                },
                response => {
                    console.log('POST call in error', response);
                    this.Alltoasts.showDanger();

                },
                () => {
                    this.modalService.dismissAll();
                    this.allOrders[this.allOrders.findIndex(t => t.id === id)].status = 1;
                    this.Alltoasts.showSuccess();

                });
    }

// Получение списка возможных операций с кассой
    GetAllCostType = (content, type) => {
        const body = new HttpParams()
            .set('place', localStorage.getItem('place'))
            .set('type', type);
        return this.http.post<any>(AppApiUrls.GetCostType,
            body)
            .subscribe(
                (val) => {
                    this.AllCostType = val as any;
                },
                response => {
                    console.log('POST call in error', response);
                },
                () => {
                    this.modalService.open(content);
                });
    }

// Смена метода оплаты на БН
    UpdatePaymentMethod = id => {
        const body = new HttpParams()
            .set('id', id)
            .set('payment_method', '1');
        return this.http.post<any>(AppApiUrls.UpdatePaymentMethod,
            body)
            .subscribe(
                (val) => {
                },
                response => {
                    console.log('POST call in error', response);
                    this.Alltoasts.showDanger();
                },
                () => {
                    this.allOrders.splice(this.allOrders.findIndex(t => t.id === id), 1);
                    this.modalService.dismissAll();
                    this.Alltoasts.showSuccess();
                });
    }

    constructor(private http: HttpClient, private appService: AppService, private modalService: NgbModal, private Alltoasts: Alltoasts) {

    }

}
