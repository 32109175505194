import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {AppApiUrls} from '../../../app.api.urls';
import {AccountantCashAccountingFull} from '../../../@shared/@cash-accounting/accountant-cash-accounting/accountant-cash-accounting-full/accountant-cash-accounting-full';

@Component({
    selector: 'app-accountant-cash-accounting-full',
    templateUrl: './accountant-cash-accounting-full.component.html',
    styleUrls: ['./accountant-cash-accounting-full.component.scss']
})
export class AccountantCashAccountingFullComponent implements OnInit {

    operation_id: number;
    url: string;
    ids_var: number;
    cash: number;
    place: string;
    status: number;

    constructor(public ACAFService: AccountantCashAccountingFull,
                private calendar: NgbCalendar, private modalService: NgbModal, config: NgbModalConfig) {
        config.backdrop = 'static';
        config.keyboard = false;
        config.centered = true;
    }


    open = (content, operation, url, id, cash, status) => {
        this.operation_id = operation;
        this.status = status;
        this.ids_var = id;
        this.cash = cash;
        this.url = AppApiUrls.NewDomain + url;
        this.modalService.open(content);
    }

    ngOnInit(): void {

        this.place = localStorage.getItem('place');
        if (this.ACAFService.model.year !== undefined) {
            this.ACAFService.fetchAll();
        } else {
            setTimeout(() => {
                this.ngOnInit();
            }, 1000);
        }
    }

    changePaymentMethod = () => {
        this.ACAFService.UpdatePaymentMethod(this.ids_var);
    }

    insertOrWaste = k => {
        this.ACAFService.InsertOrWasteCash(k);
    }

    ApplyCollection = () => {
        this.ACAFService.ApplyCollection(this.ids_var);
    }
    WasteMoney = (content, type) => {
        this.ACAFService.operation = '4';
        this.ACAFService.GetAllCostType(content, type);
    }
    ArrivalMoney = (content, type) => {
        this.ACAFService.operation = '1';
        this.ACAFService.GetAllCostType(content, type);
    }


}
