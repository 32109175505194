<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-4"></div>
    <div class="col-4">
        <img class="rounded-circle mx-auto d-block mb-3" style="width: 10rem; border-radius: 10rem"
             src="{{UserImg}}" alt="IMG">
          <h2 class="text-center">{{UserName}}</h2>
          <h3 class="text-center">{{UserProf}}</h3>
          <h3 class="text-center">{{phone}}</h3>
      <form [formGroup]="form">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
        <button class="btn btn-dark" type="button" (click)="viewPassword()">
          <img style="width: 1rem; filter: invert()" *ngIf="showPassword == 'text'" src ="/assets/img/eyes_on.png">
          <img style="width: 1rem; filter: invert()" *ngIf="showPassword == 'password'" src ="/assets/img/eyes_off.png">
        </button>
        </div>
        <input [type]="showPassword" class="form-control" formControlName="password" placeholder="{{'PROFILE.ENTER_PASSWORD' | translate}}">
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" [disabled]="!form.value.password" (click)="updatePassword()">{{'PROFILE.CHANGE_PASSWORD' | translate}}</button>
        </div>
      </div>
      <div class="input-group">
        <select class="custom-select" formControlName="place" id="inputGroupSelect04">
          <option disabled selected>{{'PROFILE.CHOOSE_PLACE' | translate}}</option>
          <ng-container *ngFor="let places of places$ | async">
          <option  [value]=places.id>{{places.name}}</option>
          </ng-container>
        </select>
        <div class="input-group-append">
          <button class="btn btn-primary" [disabled]="!form.value.place" type="button" (click)="updatePlace()">{{'PROFILE.CHANGE_PLACE' | translate}}</button>
        </div>
      </div>
      </form>
      <div *ngIf="prof === '23'" class="input-group my-3">
        <input (change)="fieldsChange($event)" [checked]="groupId != '0'" value="angular" class="form-check m-auto" type="checkbox" id="checkbox">
        <label class="pl-1 m-auto" for="checkbox">{{'PROFILE.CALL_CENTER' | translate}}</label>
        <select [(ngModel)]="select" [disabled]="checkBox == false" class="custom-select ml-2">
          <option *ngFor="let groups of group" [value]="groups.id">{{groups.cities_names}}</option>
        </select>
        <div class="input-group-append">
          <button class="btn btn-primary" (click)="updateGroup()">{{'PROFILE.CHANGE' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="col-4"></div>
  </div>
</div>
