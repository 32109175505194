<div class="container-fluid">
  <div style="margin-top: 1rem; margin-bottom: 1rem">
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                 name="dp" [(ngModel)]="ACAFService.model" ngbDatepicker navigation="none" #d="ngbDatepicker"
                 (ngModelChange)="ACAFService.fetchAll()">
          <div class="input-group-append">

            <button class="btn btn-outline-success" (click)="d.toggle()" type="button">

              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-calendar2-date" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                <path
                  d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
              </svg>

            </button>

          </div>
        </div>

        <button class="btn btn-outline-success" style="margin-left: 1rem"
                (click)="ArrivalMoney(arrivalOfMoney, 'arrival')">{{'CASH_ACCOUNTING.ARRIVAL' | translate}}

        </button>
        <button class="btn btn-outline-success" style="margin-left: 1rem"
                (click)="WasteMoney(wasteOfMoney, 'waste')">{{'CASH_ACCOUNTING.WASTE' | translate}}
        </button>
      </div>
    </form>

  </div>
  <table class="table" width="100%">
    <thead class="thead-light">
    <tr>
      <th scope="col">ID</th>
      <th scope="col">{{'GLOBAL.PAY_TYPE' | translate}}</th>
      <th scope="col">{{'GLOBAL.TIME' | translate}}</th>
      <th scope="col">{{'GLOBAL.SUM' | translate}}</th>
      <th scope="col">{{'GLOBAL.COMMENT' | translate}}</th>
      <th scope="col">{{'GLOBAL.PAYER' | translate}}</th>
      <th scope="col">{{'GLOBAL.PLACE' | translate}}</th>
      <th scope="col">{{'GLOBAL.TODO' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr  *ngFor="let object of ACAFService.allOrders;i as index">
      <th scope="row">{{object.id}}</th>
      <td>{{object.operation_name}}</td>
      <td>{{object.time}}</td>
      <td>{{object.cash}}</td>
      <td>{{object.comment}}</td>
      <td>{{object.name}} {{object.surname}}</td>
      <td>{{object.placename}}</td>
      <td>
        <button class="btn btn-outline-success"
                (click)="open(modal, object.operation, object.url,object.id, object.cash,object.status)">{{'GLOBAL.TODO' | translate}}</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>


<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'CASH_ACCOUNTING.COLLECTION' | translate}}</h4>
    <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngSwitch]="operation_id">
    <ng-container *ngSwitchCase="operation_id === '1'">
      <button class="btn btn-outline-success" style="width: 100%; margin-top: .5rem" [disabled]="status === '1' || status === 1"
              (click)="ApplyCollection()">
        {{'CASH_ACCOUNTING.APPLY_COLLECTION' | translate}}
      </button>
      <button class="btn btn-outline-success" style="width: 100%; margin-top: .5rem" [disabled]="status === '1' || status === 1">
        {{'GLOBAL.BUTTONEDIT' | translate}}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="operation_id">
      <a
        [href]="url+'?cash='+cash+'&date='+ACAFService.model.year+'-'+ACAFService.model.month+'-'+ACAFService.model.day+'&place='+place"
        class="btn btn-outline-success" target="_blank" style="width: 100%; margin-top: .5rem">
        {{'CASH_ACCOUNTING.GET_REPORT' | translate}}
      </a>
      <button class="btn btn-outline-success" (click)="changePaymentMethod()" style="width: 100%; margin-top: .5rem">{{'CASH_ACCOUNTING.CHANGE_PAYMENT_METHOD' | translate}}</button>
    </ng-container>
  </div>
</ng-template>


<ng-template #arrivalOfMoney let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'CASH_ACCOUNTING.ARRIVAL' | translate}}</h4>
    <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="number" class="form-control" [(ngModel)]="ACAFService.cash"
           placeholder="{{'CASH_ACCOUNTING.INSERT_SUM' | translate}}" style="width: 100%">
    <select class="form-control" [(ngModel)]="ACAFService.operation" id="exampleFormControlSelect1"
            style="width: 100%; margin-top: 1rem">
      <option *ngFor="let object of ACAFService.AllCostType; i as index" [value]="object.operation_id"
      >{{object.name}}</option>
    </select>
    <div class="form-floating" style="margin-top: 1rem">
      <textarea class="form-control" [(ngModel)]="ACAFService.comment" placeholder="{{'GLOBAL.COMMENT' | translate}}"
                style="height: 100px; width: 100%"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-success" (click)="insertOrWaste(1)">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
  </div>
</ng-template>


<ng-template #wasteOfMoney let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'CASH_ACCOUNTING.WASTE' | translate}}</h4>
    <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="number" class="form-control" [(ngModel)]="ACAFService.cash"
           placeholder="{{'CASH_ACCOUNTING.INSERT_SUM' | translate}}" style="width: 100%">
    <select class="form-control" [(ngModel)]="ACAFService.operation"
            style="width: 100%; margin-top: 1rem">
      <option *ngFor="let object of ACAFService.AllCostType; i as index" [value]="object.operation_id"
      >{{object.name}}</option>
    </select>

    <div class="form-floating" style="margin-top: 1rem">
      <textarea class="form-control" [(ngModel)]="ACAFService.comment" placeholder="{{'GLOBAL.COMMENT' | translate}}"
                style="height: 100px; width: 100%"></textarea>
    </div>


  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-success" (click)="insertOrWaste(-1)">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
  </div>
</ng-template>



