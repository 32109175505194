<div>
    <div class="form-group">
        <form class="form-inline" style="margin-top: 1rem; margin-bottom: 1rem">
            <div class="form-row">
                <div class="form-group col-sm-6">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd"
                               name="dp" [(ngModel)]="service.date" (ngModelChange)="changePlace()" ngbDatepicker
                               #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-success calendar" (click)="d.toggle()"
                                    type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-calendar2-date" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                                    <path
                                            d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <button class="btn btn-success w-100" (click)="switchToViewAllOrders()">
                        <span *ngIf="viewAllOrders">{{'PACKAGE_PANEL.NOT_PACKAGE' | translate}}</span>
                        <span *ngIf="!viewAllOrders">{{'PACKAGE_PANEL.ALL' | translate}}</span>
                    </button>
                </div>
            </div>
        </form>
<!--        <label for="search">{{'PACKAGE_PANEL.SEARCH' | translate}}</label>-->
<!--        <input type="text" class="form-control" id="search" [(ngModel)]="service.searchTerm"-->
<!--               aria-describedby="countAllOrder">-->
        <small id="countAllOrder" class="form-text text-muted">{{'PACKAGE_PANEL.TOTAL_ORDERS' | translate}} {{service.total$ | async}}</small>
    </div>
    <ng-container *ngFor="let object of (orders$ | async ); i as index">
        <ng-container *ngIf="object.status === 1">
            <div class="alert alert-light" role="alert" (click)="openInfo(object.id)"
                 [ngClass]="{'alert-dark': object.id == selectedOrder}">
                <p>
                    <button type="button" class="btn btn-success btn-sm"
                            style="float: right" (click)="openInfo(object.id, fastPackage)">{{'PACKAGE_PANEL.QUICK_PACKAGE' | translate}}
                    </button>
                </p>
                <span>№ {{object.order_number}} | ID: {{object.id}}</span>
                <hr>
                <div class="d-flex">
                    <div class="p-2">
                        <span>{{object.street}}/{{object.house}}/{{object.attic}}/{{object.apt}}</span>
                    </div>
                    <div class="ml-auto p-2">
                        <button class="btn btn-primary" (click)="onPrint(object.id, receipt)">{{ 'PACKAGE_PANEL.PRINT' | translate }}</button>
                    </div>
                </div>
                <hr>
                <span>{{object.phone}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="viewAllOrders">
            <ng-container *ngIf="object.status === 0 || object.status === 2 || object.status === 3">
                <div class="alert alert-danger" role="alert"
                     [ngClass]="{'alert-dark': object.id == selectedOrder}" (click)="openInfo(object.id)">
                    <span>№ {{object.order_number}} | ID: {{object.id}}</span>
                    <hr>
                    <span>{{object.street}}/{{object.house}}/{{object.attic}}/{{object.apt}}</span>
                    <hr>
                    <span>{{object.phone}}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="object.status === 4">
                <div class="alert alert-success" role="alert" (click)="openInfo(object.id)"
                     [ngClass]="{'alert-dark': object.id == selectedOrder}"
                >
                    <span>№ {{object.order_number}} | ID: {{object.id}}</span>
                    <hr>
                    <div class="d-flex">
                        <div class="p-2">
                            <span>{{object.street}}/{{object.house}}/{{object.attic}}/{{object.apt}}</span>
                        </div>
                        <div class="ml-auto p-2">
                            <button class="btn btn-primary" (click)="onPrint(object.id, receipt)">{{'PACKAGE_PANEL.PRINT' | translate}}</button>
                        </div>
                    </div>
                    <hr>
                    <span>{{object.phone}}</span>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #noOrders>
    <div class="loading">Loading&#8230;</div>
</ng-template>


<ng-template #loadOrders>
    <div class="alert alert-warning" role="alert">
        <p>{{'PACKAGE_PANEL.SENDING_ORDER' | translate}}</p>
        <hr>
        <div class="text-center">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #fastPackage let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'PACKAGE_PANEL.WARNING' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6" *ngFor="let packager of pservice.arrayPackagers?.data"
                         style="margin-bottom: 0.3rem">
                        <button class="btn btn-outline-primary w-100 h-100"
                                (click)="selectPackagerFromFastPackage(packager.id)"
                                [ngClass]="{'btn-primary': pservice.saleData.packager_id == packager.id}">{{packager.name}}</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6" *ngFor="let cooker of pservice.arrayCookers?.data" style="margin-bottom: 0.3rem">
                        <button class="btn btn-outline-dark w-100 h-100"
                                (click)="selectCookerFromFastPackage(cooker.id)"
                                [ngClass]="{'btn-dark': pservice.saleData.cooker_id == cooker.id}"
                                *ngIf="cooker.alias"> {{cooker.name}} {{cooker.surname}} {{cooker.alias}}</button>
                        <button class="btn btn-outline-dark w-100 h-100"
                                (click)="selectCookerFromFastPackage(cooker.id)"
                                [ngClass]="{'btn-dark': pservice.saleData.cooker_id == cooker.id}"
                        *ngIf="!cooker.alias">{{cooker.name}} {{cooker.surname}}</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #receipt let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-recipt orderId="{{orderId}}"></app-recipt>
    </div>
    <div class="modal-footer">
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="receipt"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>
