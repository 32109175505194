import {Pipe, PipeTransform} from '@angular/core';
import {arrayOrders} from "./array.orders";

@Pipe({
  name: 'ordersFilter'
})
export class OrdersFilterPipe implements PipeTransform{
  transform(obj: arrayOrders[], search:string=''): arrayOrders[] {
    if (!search.trim()){
      return obj
    }
    return  obj.filter(order => {
      return order.order_number.toLowerCase().indexOf(search.toLowerCase()) !== -1
    })
  }

}
