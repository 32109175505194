import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'reduceCA'
})
export class ReduceCAPipe implements PipeTransform {
    transform(objects: any, ...args: any[]): any {

        objects.map(object => {
            object.infoSum = {
                sumCost: 0,
                sumArrival: 0,
            };
            if (object.operation_type == 'turnover' && object.turn_over_info.type == 'arrival') {
                object.infoSum.sumArrival = object.turn_over_info.cash;
            } else if (object.operation_type == 'collection') {
                object.infoSum.sumArrival = object.collection_info.cash_history.reduce((a, b) => {
                    let sum = 0;
                    if (b.state == 'done') {
                        sum = b.cash;
                    }
                    return a + sum;
                }, 0);
            } else if ((object.operation_type == 'debt' && object.debt_info.company_debt)) {
                object.infoSum.sumArrival = object.debt_info.company_debt;
            }

            if (object.operation_type && object.operation_type == 'invoice') {
                object.infoSum.sumCost = object.invoice_history.sum;
            } else if (object.operation_type == 'wage') {
                object.infoSum.sumCost = object.wage_info.reduce((a, b) => {
                    let sum = 0;
                    if (b.state === 'done') sum = b.wage;
                    return a + sum;
                }, 0);
            } else if ((object.operation_type == 'turnover' && object.turn_over_info.type == 'cost')) {
                object.infoSum.sumCost = object.turn_over_info.cash;
            } else if ((object.operation_type == 'debt' && object.debt_info.user_debt)) {
                object.infoSum.sumCost = object.debt_info.user_debt;
            }
            return object;
        });
        return {
            sumCost: objects.filter(el => el.payment_method == 'cash').reduce((a, b) => a + b.infoSum.sumCost, 0),
            sumArrival: objects.filter(el => el.payment_method == 'cash').reduce((a, b) => a + b.infoSum.sumArrival, 0),
            sumCashlessCost: objects.filter(el => el.payment_method == 'card').reduce((a, b) => a + b.infoSum.sumCost, 0),
            sumCashlessArrival: objects.filter(el => el.payment_method == 'card').reduce((a, b) => a + b.infoSum.sumArrival, 0),
            operation_type: 'sumInfo'
        } as any;
    }

}
