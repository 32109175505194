import { Injectable } from '@angular/core';
import {WinnerList, WinnerListInterface} from './winner-list.interface';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppApiUrls} from '../../app.api.urls';

@Injectable()
export class WinnerListService  implements WinnerListInterface{

  constructor(
      private http: HttpClient
  )
  {}

  get(): Observable<WinnerList[]> {
    return this.http.get<WinnerList[]>(AppApiUrls.getWinnerList(), {
      params: {
        city_id: localStorage.getItem('city')
      }
    });
  }

  create(winnerInfo): Observable<WinnerList[]> {
    return this.http.post<WinnerList[]>(AppApiUrls.getWinnerList(), winnerInfo);
  }

  update(id: number, activateId): Observable<WinnerList[]> {
    return this.http.put<WinnerList[]>(AppApiUrls.getWinnerList() + id, activateId);
  }
}
