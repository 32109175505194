import { Component, OnInit } from '@angular/core';
import {RoutelistService} from "../../@shared/routelist/routelist.service";

@Component({
  selector: 'app-printed-page-route-list',
  templateUrl: './printed-page-route-list.component.html',
  styleUrls: ['./printed-page-route-list.component.scss']
})
export class PrintedPageRouteListComponent implements OnInit {

  constructor(public service: RoutelistService) { }

  ngOnInit(): void {
  }


}
