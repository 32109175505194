import {Directive, HostListener, Input} from '@angular/core';
import {NgxCaptureService} from 'ngx-capture';

@Directive({
    selector: '[appPrint]',
})
export class PrintDirective {

    @Input() idPrint: string;

    img: string;

    constructor(
        private captureService: NgxCaptureService,
    ) {
    }

    @HostListener('click') onMouseEnter(): any {
        this.captureService.getImage(document.getElementById(this.idPrint), true)
            .subscribe({
                next: value => this.img = value,
                complete: () => {
                    const newWindow = window.open('', '');
                    const imageElement = document.createElement('img');
                    imageElement.src = this.img;
                    newWindow.document.body.appendChild(imageElement);
                    newWindow.onafterprint = newWindow.close;
                    setInterval(() => {
                        newWindow.print();
                    }, 1000);
                }
            });
    }
}
