import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../@shared/login-page/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Alltoasts} from '../toasts/alltoasts';
import {NavbarService} from "../@shared/navbar/navbar.service";


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
    form: FormGroup;
    aSub: Subscription;


    constructor(private auth: AuthService,
                public translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private AllToasts: Alltoasts,
                private translateService: TranslateService,
                private navbarService: NavbarService

    ) {
    }

    ngOnInit(): void {
        localStorage.setItem('lang', 'ua');
        this.form = new FormGroup({
            phone: new FormControl(null, [Validators.required, Validators.minLength(10)]),
            password: new FormControl(null, [Validators.required, Validators.minLength(4)])
        });

        this.route.queryParams.subscribe((params: Params) => {
            if (params.accessDenied) {
                // Сперва авторизуйтесь
            }
        });

    }

    changeLang(langSelect, lang): void {
        localStorage.setItem('lang', lang);
        this.translate.use(langSelect.value);
    }

    ngOnDestroy = () => {
        if (this.aSub) {
            this.aSub.unsubscribe();
        }
    }

    getCityInfo(): void {
        this.navbarService.getCityInfo().subscribe({
            next: value => {
                localStorage.setItem('cityInfo', JSON.stringify(value.data));
            }
        });
    }

    onSubmit = () => {
        this.form.disable();
        this.aSub = this.auth.login(this.form.value).subscribe(
            () =>
            {
                this.router.navigate(['/']);
                this.getCityInfo();
            },
            response => {
                    switch (response.error.message) {
                        case 'no such user found':
                            this.AllToasts.showDanger(this.translateService.instant('LOGIN.USER_NOT_FOUND'));
                            break;
                        case 'user is fired':
                            this.AllToasts.showDanger(this.translateService.instant('LOGIN.USER_HAS_BEEN_FIRED'));
                            break;
                        case 'password is wrong':
                            this.AllToasts.showDanger(this.translateService.instant('LOGIN.INCORRECT_PASSWORD'));
                            break;
                        case 'visit required':
                            this.AllToasts.showDanger(this.translateService.instant('LOGIN.ARRIVAL_STAMP_REQUIRED'));
                            break;
                        case 'confirmation required':
                            this.AllToasts.showDanger(this.translateService.instant('LOGIN.LOGIN_CONFIRMATION_REQUIRED'));
                            break;
                        default:
                            this.AllToasts.showDanger(`Зверніться до адміністратора з помилкою: ${response.error.message}`);
                    }
            }
        );
        this.form.enable();
    }
}
