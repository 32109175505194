import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}, icon) {
    this.toasts.push({ textOrTpl, ...options, icon });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
