import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent  {
  fromDate: NgbDate;
  toDate: NgbDate;
  @Output() dateSelect = new EventEmitter<{ fromDate: NgbDate, toDate: NgbDate }>();
  hoveredDate: NgbDate | null = null;
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }



  onDateSelection = (date: NgbDate, d: any) => {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date) {
      this.toDate = date;
      this.dateSelect.emit({ fromDate: this.fromDate, toDate: this.toDate } );
      d.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = (date: NgbDate) => this.fromDate && !this.toDate &&
      this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)

  isInside = (date: NgbDate) => this.toDate && date.after(this.fromDate) &&
      date.before(this.toDate)

  isRange = (date: NgbDate) => date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date)

  validateInput = (currentValue: NgbDate | null, input: string): NgbDate | null => {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
