import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchAllFieldsPipe'
})
export class SearchAllFieldsPipe implements PipeTransform {
    transform(items: any[], searchObj: { search: string, searchBy: string[] }): any[] {
        if (!items) { return []; }
        if (!searchObj || !searchObj.search) { return items; }

        const search = searchObj.search.toLowerCase();
        const searchBy = searchObj.searchBy;

        return items.filter(item => {
            return searchBy.some(field => {
                const fieldValue = this.getFieldValue(item, field);
                return fieldValue && fieldValue.toString().toLowerCase().includes(search);
            });
        });
    }

    private getFieldValue(item: any, field: string): any {
        return field.split('.').reduce((value, key) => value[key], item);
    }
}