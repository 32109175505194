import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { NgxCaptureModule } from 'ngx-capture';
import {SpeedTestModule} from 'ng-speed-test';
import {ChartsModule} from 'ng2-charts';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ContextMenuModule} from 'ngx-contextmenu';
import {NgxPrintModule} from 'ngx-print';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {RoutelistComponent} from './route-lists/routelist/routelist.component';
import {PackagepanelComponent} from './packagepanel/packagepanel.component';
import {PackagepanelLeftblockComponent} from './packagepanel/packagepanel-leftblock/packagepanel-leftblock.component';
import {PackagepanelMainblockComponent} from './packagepanel/packagepanel-mainblock/packagepanel-mainblock.component';
import {OrdersFilterPipe} from './@shared/packagepanel/packagepanel-leftblock/packagepanel-leftblock.pipe';
import {DemoNumber} from './@shared/packagepanel/packagepanel-mainblock/packagepanel-mainblock.pipe';
import {StoragesettingsComponent} from './storagesettings/storagesettings.component';
import {techcardsFilterPipe} from './@shared/storagesettings/techcards-product/techcards-product.pipe';
import {TechcardEditPriceComponent} from './storagesettings/techcard-edit-price/techcard-edit-price.component';
import {techcardEditPriceFilter} from './@shared/storagesettings/techcard-edit-price/techcard-edit-price.pipe';
import {HelpPageComponent} from './help-page/help-page.component';
import {ScrollSpyDirective} from './help-page/scroll-spy.directive';
import {NavbarComponent} from './navbar/navbar.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {ToastsContainer} from './toasts/toasts-container.component';
import {ProfileComponent} from './profile/profile.component';
import {StorageSettingsHomeComponent} from './storagesettings/storage-settings-home/storage-settings-home.component';
import {LoaderComponent} from './loader/loader.component';
import {PrintedPageRouteListComponent} from './route-lists/printed-page-route-list/printed-page-route-list.component';
import {RouteListsComponent} from './route-lists/route-lists.component';
import {ReportsStorageComponent} from './reports/reports-home/reports-storage/reports-storage.component';
import {BenchmarkComponent} from './benchmark/benchmark.component';
import {QuickSaleModule} from './quick-sale/quick-sale.module';
import {HttpErrorsInterceptor} from './@shared/http-errors.interceptor';
import { DevToolsComponent } from './dev-tools/dev-tools.component';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatSelectModule} from '@angular/material/select';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatTreeModule} from '@angular/material/tree';
import {ModulesResolver} from './@shared/navbar/resolvers/modules.resolver';
import {MatInputModule} from '@angular/material/input';
import {sharedModule} from './@shared/shared.module';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {LangInterceptor} from './@shared/lang.interceptor';
import { ChangePrinterComponent } from './change-printer/components/change-printer.component';
import { SiteIndexComponent } from './site-index/components/site-index.component';
import { CreateRouteListComponent } from './route-lists/create-route-list/create-route-list.component';
import { EditRouteListComponent } from './route-lists/create-route-list/edit-route-list/edit-route-list.component';
import { RoutListSearchPipe } from './route-lists/create-route-list/pipe/routlist-search.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CustomSearchPipe} from "./change-printer/customSearchPipe/custom-search.pipe";

@NgModule({
    declarations: [
        AppComponent,
        PackagepanelComponent,
        RoutelistComponent,
        NotfoundComponent,
        HomeComponent,
        PackagepanelLeftblockComponent,
        PackagepanelMainblockComponent,
        OrdersFilterPipe,
        DemoNumber,
        StoragesettingsComponent,
        techcardsFilterPipe,
        TechcardEditPriceComponent,
        techcardEditPriceFilter,
        HelpPageComponent,
        ScrollSpyDirective,
        NavbarComponent,
        LoginPageComponent,
        ToastsContainer,
        ProfileComponent,
        StorageSettingsHomeComponent,
        LoaderComponent,
        PrintedPageRouteListComponent,
        RouteListsComponent,
        ReportsStorageComponent,
        BenchmarkComponent,
        DevToolsComponent,
        ChangePrinterComponent,
        SiteIndexComponent,
        CreateRouteListComponent,
        EditRouteListComponent,
        RoutListSearchPipe,
        CustomSearchPipe
    ],
    exports: [
    ],
    imports: [
        MatTreeModule,
        SpeedTestModule,
        BrowserModule,
        AngularEditorModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule,
        ReactiveFormsModule,
        NgxPrintModule,
        ContextMenuModule.forRoot(),
        ScrollingModule,
        ChartsModule,
        sharedModule,
        QuickSaleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        NgxCaptureModule,
        DragDropModule,
        MatSelectModule,
        NgSelectModule,
        MatTreeModule,
        MatInputModule,
        NgxQRCodeModule,
        LeafletModule,
        // should be the last
        NoopAnimationsModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
    ],
    providers: [
        DecimalPipe,
        ModulesResolver,
        MatBottomSheet,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true
        },
        DatePipe,
        RoutListSearchPipe
        ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
