import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppApiUrls} from '../../app.api.urls';
import {Printers, PrintersData} from "../interfaces/printers.interfaces";

@Injectable({
    providedIn: 'root'
})

export class ChangePrinterService {
    constructor(private http: HttpClient) {
    }

    // changePrinter(name: string): Observable<any> {
    //     return this.http.post(AppApiUrls.changePrinter(), {
    //         place_id: localStorage.getItem('place'),
    //         name
    //     });
    // }

    getPrinters(): Observable<PrintersData> {
        return this.http.get<PrintersData>(AppApiUrls.printers());
    }

    updatePrinters(id: number, body: Printers): Observable<Printers>{
        return this.http.put<Printers>(AppApiUrls.printers() + '/' + id , body);
    }
}
