<div class="form-group d-flex justify-content-center align-items-center flex-column" [ngClass]="{'col-sm-6': source !== 'tv-media'}">
    <span class="font-bold my-2">{{ 'GLOBAL.UPLOAD_AN_IMAGE' | translate }}</span>
    <div class="file-upload d-flex align-items-center">
        <input type="file" class="d-none"
               (change)="handleFileInput($event)" #fileUpload accept="image/webp">
        <ng-container *ngIf="fileName">
            <img class="mx-2" [src]="imageInput" alt="img" width="75vh" height="75vh">
            {{ fileName }}
        </ng-container>
           <img *ngIf="source === 'main' && !fileName" class="mx-2" [src]="imageSrc" alt="img" width="75vh" height="75vh">
            <img *ngIf="source === 'tv-media' && !fileName"  [src]="imageSrc" alt="img" width="345px" height="auto">
        <button class="mx-2 btn btn-info" (click)="fileUpload.click()">
            <img width="20vh" height="20vh" src="././assets/img/download-arrow.png">
        </button>
    </div>
</div>
