import {
  Component, EventEmitter,
  Input,
  OnInit, Output
} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

import {Unsubscribe} from "../../../@shared/unsubscribe";
import {LanguageLogicService} from "../language-logic.service";
import {IdentifierModules, LANG} from "../lang.interface";



@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss']
})
export class SelectLangComponent extends Unsubscribe implements OnInit {

  langs?: LANG[];
  @Input() form!: FormGroup;
  @Input() index!: number;
  @Output() addItemEvent = new EventEmitter<void>();
  @Output() removeItemEvent = new EventEmitter<number>();
  @Input() source!: string;
  @Output() update = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Input() info!: any;
  @Input() sourceModule!: string;
  moduleSource = IdentifierModules;


  constructor(private languageLogicService: LanguageLogicService) {
    super();
  }

  ngOnInit(): void {
    this.getLang();
    console.log(this.sourceModule)
  }

  getLang(): void {
    this.languageLogicService.getLang()
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: value => this.langs = value.data
        });
  }

  get formArrayNameDescription(): FormArray {
    return this.form.get('descriptions') as FormArray;
  }

  addItem(): void {
    this.addItemEvent.emit();
  }

  removeItem(index: number): void {
    this.removeItemEvent.emit(index);
  }

  createDescription(): void {
    this.create.emit();
  }

  updateDescription(id: number): void {
    this.update.emit(id);
  }

  getFilteredLanguages(index: number): LANG[] {
    return  this.languageLogicService.getFilteredLanguages(this.formArrayNameDescription, this.langs, index);
  }


  isAddButtonDisabled(): boolean {
    return this.languageLogicService.isAddButtonDisabled(this.formArrayNameDescription, this.langs);
  }
}
