import { Component, OnInit } from '@angular/core';
import {HomeService} from './home.service';
import {HomeInterface} from './home.interface';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  module: HomeInterface[];

  constructor(private service: HomeService) { }

  ngOnInit(): void {
    this.get();
  }

  get(): void {
    this.service.get().subscribe({
    next: value => this.module = value,
  });
  }
}
