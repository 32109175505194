import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {SiteIndexService} from '../services/siteIndex.service';
import {Unsubscribe} from '../../@shared/unsubscribe';
import {Alltoasts} from '../../toasts/alltoasts';

@Component({
    selector: 'app-site-index',
    templateUrl: './site-index.component.html',
    styleUrls: ['./site-index.component.scss']
})
export class SiteIndexComponent extends Unsubscribe implements OnInit {

    indexText: string;

    constructor(
        private service: SiteIndexService,
        private toast: Alltoasts
    ) {
        super();
    }

    ngOnInit(): void {
        this.getIndex();
    }

    getIndex(): void {
        this.service.get().pipe(takeUntil(this.$destroy)).subscribe({
            next: value => {
                this.indexText = value.data;
            }
        });
    }

    changeIndex(): void {
        this.service.put(this.indexText).pipe(takeUntil(this.$destroy)).subscribe({
            complete: () => {
                this.getIndex();
                this.toast.showSuccess('All OK!');
            }
        });
    }

}
