export interface SelectedLang {
    name: string;
    code: string;
}


export interface LangData {
    success: boolean;
    data: LANG[];
}

export interface LANG {
    id: number;
    name: string;
    code: string;
    created_at: string;
    updated_at: string;
}


export const IdentifierModules = {
    categories: 'categories',
    ingredients: 'ingredients',
    banners: 'banners',
    semifinish: 'semifinish',
    marks: 'marks',
    answers: 'answers',
    city: 'city',
    cityMain: 'cityMain',
    techcards: 'techcards',
} as const;

export type IdentifierModules = typeof IdentifierModules[keyof typeof IdentifierModules];
