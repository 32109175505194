<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
<!--            <div class="form-group mb-2">-->
<!--                <div class="input-group input-group-sm">-->
<!--                    <input class="form-control" placeholder="yyyy-mm-dd"-->
<!--                           name="dp" [(ngModel)]="dateService.model"  ngbDatepicker-->
<!--                           (ngModelChange)="get()"-->
<!--                           #d="ngbDatepicker">-->
<!--                    <div class="input-group-append">-->
<!--                        <button class="btn btn-outline-success calendar" (click)="d.toggle()"-->
<!--                                type="button">-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"-->
<!--                                 class="bi bi-calendar2-date" viewBox="0 0 16 16">-->
<!--                                <path fill-rule="evenodd"-->
<!--                                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>-->
<!--                                <path-->
<!--                                        d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>-->
<!--                            </svg>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <select class="form-control mb-2" [(ngModel)]="paymentType" (ngModelChange)="get()">-->
<!--                <option value="cash">{{'ROUTE-LIST-BUILDER.ONLY_CASH' | translate}}</option>-->
<!--                <option value="cashless">{{'ROUTE-LIST-BUILDER.CASHLESS' | translate}}</option>-->
<!--                <option [value]="null">{{'ROUTE-LIST-BUILDER.ALL_ORDERS' | translate}}</option>-->
<!--            </select>-->
            <div
                    cdkDropList
                    #todoList="cdkDropList"
                    [cdkDropListData]="orders"
                    [cdkDropListConnectedTo]="[doneList]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of orders"
                     [attr.aria-details]="item.id"
                     aria-valuetext="{{item.id}}"
                     cdkDrag>
                    <div class="row w-100">
                        <div class="col-sm-2 text-center">
                            №{{item.order_number}}
                        </div>
                        <div class="col-sm-6 text-center">
                            <span *ngIf="item.pickupcheck == 0">{{item.street + ' ' + item?.house}}</span>
                            <span *ngIf="item.pickupcheck == 1">{{item.street}}</span>
                        </div>
                        <div class="col-sm-2">
                            {{item.total}} UAH
                        </div>
                        <div class="col-sm-2">
                            <span *ngIf="item.terminalcheck == 0">Готівка</span>
                            <span *ngIf="item.terminalcheck == 1">БГ</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading" *ngIf="loader === true">Loading&#8230;</div>
        </div>
        <div class="col-sm-6">
            <form [formGroup]="form">
                

                <select *ngIf="isAdmin" type="text" class="form-control mb-2" formControlName="id_manager" placeholder="ID касира">
                    <option [value]="null" disabled selected>{{'ROUTE-LIST-BUILDER.ALL_USERS' | translate}}</option>
                   <ng-container *ngFor="let user of users">
                      <option [value]="user.id">{{user.name}} {{user.surname}}</option>
                  </ng-container>
               </select>
                
               <select formControlName="id_courier" class="form-control my-2">
                <option [value]="null" disabled selected>{{'ROUTE-LIST-BUILDER.COURIERS' | translate}}</option>
                  <ng-container *ngFor="let courier of couriers">
                       <option [value]="courier.id">{{courier.name}} {{courier.surname}}</option>
                   </ng-container>
               </select>
                <input type="number" class="form-control mb-2" formControlName="distance" placeholder="Дистанція"> 
            </form>
            <div
                    cdkDropList
                    #doneList="cdkDropList"
                    [cdkDropListData]="ordersRL"
                    [cdkDropListConnectedTo]="[todoList]"
                    class="example-list mb-2"
                    (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of ordersRL"
                     aria-valuetext="{{item.id}}"
                     cdkDrag>
                    <div class="row w-100">
                        <div class="col-sm-2 text-center">
                            №{{item.order_number}}
                        </div>
                        <div class="col-sm-6 text-center">
                            <span *ngIf="item.pickupcheck == 0">{{item.street + ' ' + item?.house}}</span>
                            <span *ngIf="item.pickupcheck == 1">{{item.street}}</span>
                        </div>
                        <div class="col-sm-2">
                            {{item.total}} UAH
                        </div>
                        <div class="col-sm-2">
                            <span *ngIf="item.terminalcheck == 0">Готівка</span>
                            <span *ngIf="item.terminalcheck == 1">БГ</span>
                        </div>
                    </div>
                </div>
            </div>
           <button class="btn btn-primary"
                 [disabled]="form.invalid || !ordersRL.length" (click)="save()">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
        </div>
    </div>
</div>
