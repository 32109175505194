<mat-nav-list>
    <a (click)="findAllTablesOnPage(content)" mat-list-item>
        <span mat-line>Excel</span>
        <mat-icon>cloud_download</mat-icon>
        <span mat-line>{{'DEVTOOL.SEARCH_ALL_TABS' | translate}}</span>
    </a>
    <a mat-list-item>
        <span mat-line>{{'GLOBAL.PLACE' | translate}}</span>
        <mat-select [(ngModel)]="UserPlace" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changePlace()">
            <mat-option *ngFor="let object of navbarService.AllPlaces; i as index"
            [value]="object.id">
                {{object.description.name}}
            </mat-option>
        </mat-select>
        <mat-icon>swap_horizontal_circle</mat-icon>
    </a>
</mat-nav-list>


<ng-template #content let-modal>
    <div class="modal-header">
        EXCEL
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-4" *ngFor="let object of exportExcel">
                <div class="card" style="width: 100%; height: 18rem">
                    <img [src]="object.img" class="card-img-top" style="width: 100%; max-height: 100%; object-fit: cover; object-position: top;">
                    <div class="card-body">
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-primary" (click)="excel(object.id)">{{'BENCH.DOWNLOAD' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #RLBuilder let-modal>
    <div class="modal-header">
        {{'DEVTOOL.CONSTRUCTOR_RL' | translate}}
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <app-route-list-builder></app-route-list-builder>
    </div>
</ng-template>
