import {Component, OnInit} from '@angular/core';
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { takeUntil} from 'rxjs/operators';
import { interval } from 'rxjs';

import {Couriers, FilterType, OrderEvent, Orders, SetOrdersToRoute} from '../create-route-list/interface/routlist.interface';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { RouteListEditService } from './route-list-edit.service';
import { Unsubscribe } from 'src/app/@shared/unsubscribe';
import { PendingOrders, PendingRoutes} from './interface/routlist.interface';
import { Alltoasts } from 'src/app/toasts/alltoasts';
import { RoutListSearchPipe } from './pipe/routlist-search.pipe';
import * as L from 'leaflet';

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 10000
};

@Component({
    selector: 'app-create-route-list',
    templateUrl: './create-route-list.component.html',
    styleUrls: ['./create-route-list.component.scss'],
    providers: [{provide: CDK_DRAG_CONFIG, useValue: DragConfig}]
})

export class CreateRouteListComponent extends Unsubscribe implements OnInit {

    hoveredDate: NgbDate | null = null;
    pendingOrder: PendingOrders[];
    pendingRoutes: PendingRoutes[];
    searchInput = '';
    filteredOrders: PendingOrders[] = [];
    couriers: Couriers[];
    selectedCourierId: number;
    routeId: number;
    orderEventEnum = OrderEvent;
    filterTypeEnum = FilterType;
    selectedFilter: string = this.filterTypeEnum.All ;
    loader = false;
    mapModal: L.Map;
    customMarker: any;

    ModalOrderId: number;
    ModalLocation: string | null = null;


    constructor(
        public formatter: NgbDateParserFormatter,
        private service: RouteListEditService,
        private toast: Alltoasts,
        private router: Router,
        private searchPipe: RoutListSearchPipe,
        private modalService: NgbModal,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getOrders();
        this.getRoutes();
        interval(60000)
          .pipe(takeUntil(this.$destroy))
          .subscribe(() => {
            this.getOrders();
            this.getRoutes();
          });
      }

    getOrders(fromDate?: NgbDateStruct, toDate?: NgbDateStruct): void {
        this.service.getPendingOrders(this.formatter.format(fromDate), this.formatter.format(toDate))
            .pipe(takeUntil(this.$destroy))
            .subscribe({
            next: value => {
                this.pendingOrder = value;
                this.filteredOrders = value;
            },
            complete: () => {
                setTimeout(() => {
                    this.loader = false;
                  }, 1000);
                this.sortOrdersByFutureCheck();
            }
        });
    }

    getRoutes(): void {
        this.service.getPendingRoutes().pipe(takeUntil(this.$destroy)).subscribe({
            next: value => this.pendingRoutes = value.data,
            complete: () => {
                setTimeout(() => {
                    this.loader = false;
                  }, 1000);
            }
        });
    }

    refreshData(): void{
        this.toast.showSuccess();
        this.loader = true;
        this.getOrders();
        this.getRoutes();
    }

    onDateSelectionChanged(datesRange): void {
        this.getOrders(datesRange.fromDate, datesRange.toDate);
    }

    drop(event: CdkDragDrop<Orders[]>, orderEvent: OrderEvent): void {
        let id;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
            );
        }
        if (orderEvent === OrderEvent.LeftBlock) {
            id = event.container.data[event.currentIndex].order_id;
            this.deleteFromRoute(id);

        } else if (orderEvent === OrderEvent.RightBlock) {

            if (!event.container.data[event.currentIndex].order_id){
                id = event.container.data[event.currentIndex].id;
                this.findCurrentId(id, OrderEvent.Add);
            }else{
                id = event.container.data[event.currentIndex].order_id;
                this.findCurrentId(id, OrderEvent.Edit);
            }
        }
    }
    findCurrentId(id: number, request: OrderEvent): void {
    this.pendingRoutes.forEach((route) => {
        if (route.orders) {
            route.orders.forEach((order) => {
                let finalId;
                if (request === OrderEvent.Add){
                    finalId = order.id;
                }else{
                    finalId = order.order_id;
                }
                if (finalId === id) {
                    const setOrdersData: SetOrdersToRoute = {
                        order_id: id,
                        routelist_id: route.id
                    };
                    this.addToRoute(setOrdersData);
                    return;
                }
            });
        }
    });
}


    addToRoute(id: any): void{
        this.service.addToRoute(id)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
            complete: () => {
                this.refreshData();
            },
        });
    }


    deleteFromRoute(id: number): void{
        if (!id) {
            return;
        }
        this.service.deleteFromRoute(id)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
            complete: () => {
                this.refreshData();
            },
        });
    }

    createNewRoutes(): void{
        this.loader = true;
        this.service.createNewRoutes()
        .pipe(takeUntil(this.$destroy))
        .subscribe({
            complete: () => {
                this.refreshData();
            },
        });
    }

    autoComplete(id: number): void{
        this.loader = true;
        this.service.autoComplete(id)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
            complete: () => {
                this.refreshData();
            },
        });
    }


    sortOrdersByFutureCheck(): void {

        switch (this.selectedFilter) {
            case FilterType.All:
              // Reload and display all receipts
            this.filteredOrders = this.pendingOrder;
            break;
            case FilterType.FutureCheck1:
              // Display only checks with futurecheck = 1
            this.filteredOrders = this.pendingOrder.filter(order => order.futurecheck === 1);
            break;
            case FilterType.FutureCheck0:
              // Display only checks with futurecheck = 0
            this.filteredOrders = this.pendingOrder.filter(order => order.futurecheck === 0);
            break;
        }

        this.filteredOrders = this.searchPipe.transform(this.filteredOrders, this.searchInput);
        this.sortOrdersBySelectedSort();

    }

    sortOrdersBySelectedSort(): void {
            // Sort orders by date ascending
            this.filteredOrders = this.filteredOrders.slice().sort((a, b) => {
                const dateA = new Date(a.date_added);
                const dateB = new Date(b.date_added);
                return dateA.getTime() - dateB.getTime();
            });
    }

    navigateToEdit(routeId: number): void {
        this.router.navigate(['routelist/edit'], { queryParams: { id: routeId } });
    }

    getCouriers(): void{
        this.service.getCouriers().pipe(takeUntil(this.$destroy)).subscribe({
        next: value => {this.couriers = value; },
        });
    }

    sendToWork(modal: any, id: number): void {
        this.routeId = id;
        this.modalService.open(modal);
        this.getCouriers();
    }

    add(): void {
        const courierId = this.selectedCourierId;
        const routeListId = this.routeId;
        this.sendRouteToWork(courierId, routeListId);
        this.close();
    }

    sendRouteToWork(courierId: number, routeListId: number): void {
        this.service.sendToWork(routeListId , courierId )
            .pipe(takeUntil(this.$destroy))
            .subscribe({
            complete: () => {
                this.refreshData();
            },
        });
    }

    openMapModal(showMapModal: any, index: number): void {
        this.ModalLocation = this.filteredOrders[index].google_location;
        this.ModalOrderId = this.filteredOrders[index].id;
        this.modalService.open(showMapModal, {size: 'xl'});
        this.initializeMapModal();
    }

    updateCoordinate(id: number, location: string): void {
        const coordinates = location.replace(' ', '');

        this.service.updateLocation(id, coordinates)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.getOrders();
                    this.closeMapModal();
                }
            });
    }

    close(): void {
        this.selectedCourierId = null;
        this.modalService.dismissAll();
    }

    closeMapModal(): void {
        this.modalService.dismissAll();
    }

    clearSearch(): void {
        this.searchInput = '';
        this.sortOrdersByFutureCheck();
    }

    initializeMapModal(): void {

        this.mapModal = L.map('mapModal').setView([47.8277464, 35.1562575], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'OpenStreetMap'
        }).addTo(this.mapModal);


        const customIcon = L.icon({
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            iconUrl: '/assets/img/marker-icon-purple.png',
            shadowUrl: '/assets/img/marker-shadow.png'
        });

        if (this.ModalLocation !== null) {
            const [lat, lng] = this.ModalLocation.split(',');
            this.customMarker = L.marker([Number(lat) , Number(lng)], { icon: customIcon }).addTo(this.mapModal);

        // Focus on the placed marker
            this.mapModal.setView([Number(lat) , Number(lng)], 13);
        }

        // Adding a map click event handler
        this.mapModal.on('click', (event: L.LeafletMouseEvent) => {
            const latLng = event.latlng;
            this.ModalLocation = `${latLng.lat.toFixed(6)}, ${latLng.lng.toFixed(6)}`;

            // Deleting the previous marker if it exists
            if (this.customMarker) {
                this.mapModal.removeLayer(this.customMarker);
            }

            // Adding a new marker to the map
            this.customMarker = L.marker(latLng, { icon: customIcon }).addTo(this.mapModal);
        });
    }

    getTooltipText(object: any): string {
        if (!object.order?.google_location) {
            return 'Точка не обрана, оберіть точку вручну за допомогою кнопки з прапорцем';
        } else if (!object.order?.google_location) {
            return 'Зона не обрана, оберіть зону вручну в документі швидкого продажу';
        } else {
            return 'Точка не обрана, оберіть точку вручну за допомогою кнопки з прапорцем';
        }
    }
}
