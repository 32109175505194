import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Unsubscribe} from "../../../@shared/unsubscribe";
import {Alltoasts} from "../../../toasts/alltoasts";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AllQuicksaleService} from "../../../@shared/quick-sale/all-quick-sale/all-quicksale.service";
import {OrderStatus} from "../interface";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-quality-control',
  templateUrl: './quality-control.component.html',
  styleUrls: ['./quality-control.component.scss']
})
export class QualityControlComponent extends Unsubscribe implements OnInit, OnChanges {
  @Input() orderInfo!: any;
  form: FormGroup;
  orderStatusEnum = OrderStatus;
  ratings = [
    { value: 1, label: 'Все чудово' },
    { value: 2, label: 'Все нормально' },
    { value: 3, label: 'Є питання' },
    { value: 4, label: 'Жах, дико не задоволені' }
  ];

  constructor(
      private fb: FormBuilder,
      private toast: Alltoasts,
      private modalService: NgbModal,
      public allQuicksaleService: AllQuicksaleService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formInit();
    if (this.orderInfo?.quality_recall?.status === 'done') {
      this.form.patchValue(this.orderInfo.quality_recall);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  formInit(): void{
    this.form = this.fb.group({
      value: [null, [Validators.required]],
      comment: ['']
    });
  }

  openLink(): void {
    window.open('/reports/winner-list', '_blank');
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  postQualityControl(status: string): void {
    const requestBody = {
      quicksale_id: this.orderInfo.id,
      status,
      user_id: localStorage.getItem('id'),
      ...this.form.value
    };
    this.allQuicksaleService.postQualityControl(requestBody)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          complete: () => {
            this.toast.showSuccess();
            this.closeModal();
          }
        });
  }

  putCallBackRequest(newStatus: OrderStatus, id: number): void {
    const requestBody = { status: newStatus };
    this.allQuicksaleService.putCallBackRequest(id, requestBody)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          complete: () => {}
        });
  }


}
