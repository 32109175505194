import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() searchInput!: string;
  @Output() searchChange = new EventEmitter<string>();

  ngOnInit(): void {}

  onSearchChange(value: string): void {
    this.searchChange.emit(value);
  }

  clearSearch(): void {
    this.searchInput = '';
    this.searchChange.emit(this.searchInput);
  }

}
