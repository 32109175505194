<div id="receipt" *ngIf="allData" style="font-family: Helvetica">
    <font face="Helvetica">
        <table width="98%" style="border-collapse: collapse;">
            <tr>
                <td colspan="3">
                    <ng-container *ngIf="allData.source == 'sushi'">
                        <center><img width="100%" src="assets/logoAltCheck.jpg"></center>
                    </ng-container>
                    <ng-container *ngIf="allData.source == 'pizza'">
<!--                        <center><img width="100%" src="assets/pizza3303logo.png"></center>-->
                        <center><img width="100%" src="assets/ECO-pizza-gerb.png"></center>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td>
                    <b>{{'RECEIPT.PAYMENT_METHOD' | translate}}:</b>
                </td>
                <td colspan="2" style="text-align: right; font-size: 10pt">
                    <b>{{allData.terminal | translate}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: right; font-size: 9pt" colspan="3">
                    {{allData.comment}}
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>
                        <span>{{allData.street}}</span>
                        <span *ngIf="!allData.house">{{allData.house}}</span>
                        <span *ngIf="allData.house">/{{allData.house}}</span>
                        <span *ngIf="allData.attic != ''">/{{'RECEIPT.ATTIC' | translate}}{{allData.attic}}</span>
                        <span *ngIf="allData.floor != ' ' && allData.floor != '' && allData.floor != '0'">/{{'RECEIPT.FLOOR' | translate}}{{allData.floor}}</span>
                        <span *ngIf="allData.apt != ''">/{{'RECEIPT.APT' | translate}}{{allData.apt}}</span>
                    </b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{allData.phone}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>
                        <span>{{'RECEIPT.QUANTITY_PERSONS' | translate}}: </span>
                        <span *ngIf="allData.stick != '0'">{{allData.stick}}</span>
                        <span *ngIf="allData.stick != '0' && allData.stick_edu != '0'">+</span>
                        <span *ngIf="allData.stick_edu != '0'">{{allData.stick_edu}}{{'RECEIPT.EDU' | translate}}</span>
                    </b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{allData.date}}</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.time}}</b>
                </td>
            </tr>


            <ng-container *ngFor="let object of allData.products">
                <tr>
                    <td style="text-align: left; font-size: 9pt">
                        {{object.name}}
                    </td>
                    <td style="text-align: left; font-size: 9pt">
                        {{object.count}}x{{object.price}}
                    </td>
                    <td style="text-align: right; font-size: 9pt">
                        {{(object.price * object.count).toFixed(2)}}
                    </td>
                </tr>
                <ng-container *ngIf="object?.childs && object.childs.length > 0">
                    <tr *ngFor="let child of object.childs; let j = index ">
                        <td class="pl-3" style="text-align: left; font-size: 9pt">
                           <span> - </span> {{child.name}}
                        </td>
                        <td style="text-align: left; font-size: 9pt">
                            {{child.count}}x{{child.price}}
                        </td>
                        <td style="text-align: right; font-size: 9pt">
                            {{(child.price * child.count).toFixed(2)}}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
<ng-container  *ngIf="allData.totalSum != allData.total">
            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'RECEIPT.SUM' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.totalSum.toFixed(2)}}</b>
                </td>
            </tr>

            <tr *ngIf="allData.sale > 0">
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'RECEIPT.SALE' | translate}}(<span *ngIf="allData.salePercent == 0">1%</span>
                        <span *ngIf="allData.salePercent > 0 && allData.salePercent < 8">5%</span>
                        <span *ngIf="allData.salePercent >= 8 && allData.salePercent < 12">10%</span>
                        <span  *ngIf="allData.salePercent >= 12">{{allData.salePercent}}%</span>):</b>
                </td>
                <td style="text-align: right; font-size: 10pt">

                    <b>{{(allData.totalSum - allData.total - allData?.bonuses).toFixed(2)}}</b>
                </td>
            </tr>
</ng-container>

            <tr *ngIf="allData?.bonuses !== 0">
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'BONUSES.BONUSES' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData?.bonuses | number: '1.2-2'}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'RECEIPT.TOTAL_TO_PAY' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.total.toFixed(2)}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 9pt" colspan="3">
                    {{allData.district}}
                </td>
            </tr>

            <tr *ngIf="allData.qr == 1">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <br>
                    <b>{{'RECEIPT.WE_NEED_REVIEW' | translate}}</b>
                </td>
            </tr>

            <tr *ngIf="allData.qr == 0">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <br>
                    <b>{{'RECEIPT.QUALITY_CONTROL' | translate}}</b>
                </td>
            </tr>



            <tr *ngIf="allData.qr == 1">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{'RECEIPT.SCAN_QR' | translate}}</b>
                </td>
            </tr>

            <tr *ngIf="allData.qr == 0">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{allData.control_number}}</b>
                </td>
            </tr>

        </table>


        <ng-container *ngIf="allData.qr == 1">
        <table width="98%" style="border-collapse: collapse; margin-bottom: 0rem; margin-top: 0rem">
            <tr>
                <td width="45%">
                    <ngx-qrcode
                            [width]=100
                            [elementType]="elementType"
                            [errorCorrectionLevel]="correctionLevel"
                            [value]="value + allData.id"
                            cssClass="bshadow"></ngx-qrcode>
                </td>
                <td  width="55%">
                    <img src="assets/img/socials_qr.jpg" width="90%">
                </td>
            </tr>
        </table>
        </ng-container>

        <ng-container *ngIf="allData.qr == 0">
            <center><img width="80%" src="assets/img/socials.png"></center>
        </ng-container>

        <table width="98%" style="border-collapse: collapse;">


            <tr *ngIf="allData.qr == 1">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{'RECEIPT.QUALITY_CONTROL' | translate}}</b>
                </td>
            </tr>

            <tr *ngIf="allData.qr == 1">
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{allData.control_number}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <br>
                    <b>{{'RECEIPT.THANKS' | translate}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{'RECEIPT.BON_APPETIT' | translate}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <b>{{'RECEIPT.GLORY_TO_UKRAINE' | translate}}</b>
                </td>
            </tr>

            <tr>
                <td colspan="3">
                    <br>
                    <hr>
                    <br>
                </td>
            </tr>


            <!--    Холодный цех -->

            <tr *ngIf="allData.pre_order == 0">
                <td style="text-align: left; font-size: 13pt" colspan="3">
                    <b><u>{{'RECEIPT.ORDER_TIME' | translate}}:</u></b>
                </td>
            </tr>

            <tr *ngIf="allData.pre_order == 1">
                <td style="text-align: left; font-size: 13pt" colspan="3">
                    <b><u>{{'RECEIPT.PREORDER' | translate}}:</u></b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{allData.date}}</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.time}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: right; font-size: 12pt" colspan="3">
                    <b>{{allData.comment}}/{{'RECEIPT.COLD_WORKSHOP' | translate}}</b>
                </td>
            </tr>


            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <span>{{allData.street}}</span>
                    <span *ngIf="allData.house != ''">/{{allData.house}}</span>
                    <span *ngIf="allData.attic != ''">/{{'RECEIPT.ATTIC' | translate}}{{allData.attic}}</span>
                    <span *ngIf="allData.floor != '' && allData.floor != '0'">/{{'RECEIPT.FLOOR' | translate}}{{allData.floor}}</span>
                    <span *ngIf="allData.apt != ''">/{{'RECEIPT.APT' | translate}}{{allData.apt}}</span>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    {{allData.phone}}
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    <span>{{'RECEIPT.QUANTITY_PERSONS' | translate}}: </span>
                    <span *ngIf="allData.stick != '0'">{{allData.stick}}</span>
                    <span *ngIf="allData.stick != '0' && allData.stick_edu != '0'">+</span>
                    <span *ngIf="allData.stick_edu != '0'">{{allData.stick_edu}}{{'RECEIPT.EDU' | translate}}</span>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 10pt" colspan="3">
                    {{'RECEIPT.ORDER_PROCESSING' | translate}}
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 14pt" colspan="3">
                    {{'RECEIPT.ORDER_NUMBER' | translate}}: <b>{{allData.order_number}} (ID: {{allData.id}})</b>
                </td>
            </tr>


            <ng-container *ngFor="let object of allData.kitchen_products">
                <tr>
                    <td style="text-align: left; font-size: 10pt">
                        <b>{{object.name}}</b>
                    </td>
                    <td style="text-align: left; font-size: 10pt">
                        <b>{{object.count.toFixed(0)}}x{{object.bonus}}</b>
                    </td>
                    <td style="text-align: right; font-size: 10pt">
                        <b>{{object.weight.toFixed(2)}}</b>
                    </td>
                </tr>
                <ng-container *ngIf="object?.childs && object.childs.length > 0">
                    <tr *ngFor="let child of object.childs; let j = index ">
                        <td class="pl-3" style="text-align: left; font-size: 9pt">
                            <span> - </span> {{child.name}}
                        </td>
                        <td style="text-align: left; font-size: 9pt">
                            {{child.count.toFixed(0)}}x{{child.bonus}}
                        </td>
                        <td style="text-align: right; font-size: 9pt">
                            {{child.weight.toFixed(2)}}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'RECEIPT.TOTAL_POINTS' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.kitchen_bonus}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 9pt" colspan="3">
                    {{allData.district}}
                </td>
            </tr>




            <!--  Горячий цех -->

            <ng-container *ngIf="allData.hot_ind > 0">

                <tr>
                    <td colspan="3">
                        <br>
                        <hr>
                        <br>
                    </td>
                </tr>

                <tr *ngIf="allData.pre_order == 0">
                    <td style="text-align: left; font-size: 13pt" colspan="3">
                        <b><u>{{'RECEIPT.ORDER_TIME' | translate}}:</u></b>
                    </td>
                </tr>

                <tr *ngIf="allData.pre_order == 1">
                    <td style="text-align: left; font-size: 13pt" colspan="3">
                        <b><u>{{'RECEIPT.PREORDER' | translate}}:</u></b>
                    </td>
                </tr>

                <tr>
                    <td style="text-align: left; font-size: 10pt" colspan="2">
                        <b>{{allData.date}}</b>
                    </td>
                    <td style="text-align: right; font-size: 10pt">
                        <b>{{allData.time}}</b>
                    </td>
                </tr>

                <tr>
                    <td style="text-align: right; font-size: 12pt" colspan="3">
                        <b>{{allData.comment}}/{{'RECEIPT.HOT_WORKSHOP' | translate}}</b>
                    </td>
                </tr>


                <tr>
                    <td style="text-align: center; font-size: 10pt" colspan="3">
                        <span>{{allData.street}}</span>
                        <span *ngIf="allData.house != ''">/{{allData.house}}</span>
                        <span *ngIf="allData.attic != ''">/{{'RECEIPT.ATTIC' | translate}}{{allData.attic}}</span>
                        <span *ngIf="allData.floor != '' && allData.floor != '0'">/{{'RECEIPT.FLOOR' | translate}}{{allData.floor}}</span>
                        <span *ngIf="allData.apt != ''">/{{'RECEIPT.APT' | translate}}{{allData.apt}}</span>
                    </td>
                </tr>

                <tr>
                    <td style="text-align: center; font-size: 10pt" colspan="3">
                        {{allData.phone}}
                    </td>
                </tr>

                <tr>
                    <td style="text-align: center; font-size: 10pt" colspan="3">
                        <span>{{'RECEIPT.QUANTITY_PERSONS' | translate}}: </span>
                        <span *ngIf="allData.stick != '0'">{{allData.stick}}</span>
                        <span *ngIf="allData.stick != '0' && allData.stick_edu != '0'">+</span>
                        <span *ngIf="allData.stick_edu != '0'">{{allData.stick_edu}}{{'RECEIPT.EDU' | translate}}</span>
                    </td>
                </tr>

                <tr>
                    <td style="text-align: center; font-size: 10pt" colspan="3">
                        {{'RECEIPT.ORDER_PROCESSING' | translate}}
                    </td>
                </tr>

                <tr>
                    <td style="text-align: left; font-size: 14pt" colspan="3">
                        {{'RECEIPT.ORDER_NUMBER' | translate}}: <b>{{allData.order_number}} (ID: {{allData.id}})</b>
                    </td>
                </tr>


                <ng-container *ngFor="let object of allData.hot_kitchen_products">
                    <tr>
                        <td style="text-align: left; font-size: 10pt">
                            <b>{{object.name}}</b>
                        </td>
                        <td style="text-align: left; font-size: 10pt">
                            <b>{{object.count.toFixed(0)}}x{{object.bonus}}</b>
                        </td>
                        <td style="text-align: right; font-size: 10pt">
                            <b>{{object.weight.toFixed(2)}}</b>
                        </td>
                    </tr>
                    <ng-container *ngIf="object?.childs && object.childs.length > 0">
                        <tr *ngFor="let child of object.childs; let j = index ">
                            <td class="pl-3" style="text-align: left; font-size: 9pt">
                                <span> - </span> {{child.name}}
                            </td>
                            <td style="text-align: left; font-size: 9pt">
                                {{child.count.toFixed(0)}}x{{child.bonus}}
                            </td>
                            <td style="text-align: right; font-size: 9pt">
                                {{child.weight.toFixed(2)}}
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>


                <!--    <br>-->


                <tr>
                    <td style="text-align: left; font-size: 10pt" colspan="2">
                        <b>{{'RECEIPT.TOTAL_POINTS' | translate}}:</b>
                    </td>
                    <td style="text-align: right; font-size: 10pt">
                        <b>{{allData.hot_bonus.toFixed(2)}}</b>
                    </td>
                </tr>

                <tr>
                    <td style="text-align: left; font-size: 9pt" colspan="3">
                        {{allData.district}}
                    </td>
                </tr>
            </ng-container>

            <!--    Курьерский чек -->

            <tr>
                <td colspan="3">
                    <br>
                    <hr>
                    <br>
                </td>
            </tr>

            <tr *ngIf="allData.pre_order == 0">
                <td style="text-align: left; font-size: 13pt" colspan="3">
                    <b><u>{{'RECEIPT.ORDER_TIME' | translate}}:</u></b>
                </td>
            </tr>

            <tr *ngIf="allData.pre_order == 1">
                <td style="text-align: left; font-size: 13pt" colspan="3">
                    <b><u>{{'RECEIPT.PREORDER' | translate}}:</u></b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{allData.date}}</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.time}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: right; font-size: 9pt" colspan="3">
                    {{allData.comment}}
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 9pt" colspan="3">
                    <b>
                        <span>{{allData.street}}</span>
                        <span *ngIf="allData.house != ''">/{{allData.house}}</span>
                        <span *ngIf="allData.attic != ''">/{{'RECEIPT.ATTIC' | translate}}{{allData.attic}}</span>
                        <span *ngIf="allData.floor != '' && allData.floor != '0'">/{{'RECEIPT.FLOOR' | translate}}{{allData.floor}}</span>
                        <span *ngIf="allData.apt != ''">/{{'RECEIPT.APT' | translate}}{{allData.apt}}</span>
                    </b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 9pt" colspan="3">
                    <b>{{allData.phone}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: center; font-size: 9pt" colspan="3">
                    <b><span>{{'RECEIPT.QUANTITY_PERSONS' | translate}}: </span></b>
                    <b><span *ngIf="allData.stick != '0'">{{allData.stick}}</span></b>
                    <b><span *ngIf="allData.stick != '0' && allData.stick_edu != '0'">+</span></b>
                    <b><span *ngIf="allData.stick_edu != '0'">{{allData.stick_edu}}{{'RECEIPT.EDU' | translate}}</span></b>
                </td>
            </tr>

            <ng-container *ngFor="let object of allData.products">
                <tr>
                    <td style="text-align: left; font-size: 9pt">
                        {{object.name}}
                    </td>
                    <td style="text-align: left; font-size: 9pt">
                        {{object.count}}x{{object.price}}
                    </td>
                    <td style="text-align: right; font-size: 9pt">
                        {{object.price.toFixed(2)}}
                    </td>
                </tr>
                <ng-container *ngIf="object?.childs && object.childs.length > 0">
                    <tr *ngFor="let child of object.childs; let j = index ">
                        <td class="pl-3" style="text-align: left; font-size: 9pt">
                            <span> - </span> {{child.name}}
                        </td>
                        <td style="text-align: left; font-size: 9pt">
                            {{child.count}}x{{child.price}}
                        </td>
                        <td style="text-align: right; font-size: 9pt">
                            {{(child.price * child.count).toFixed(2)}}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>


            <!--    <br>-->

            <ng-container  *ngIf="allData.totalSum != allData.total">
                <tr>
                    <td style="text-align: left; font-size: 10pt" colspan="2">
                        <b>{{'RECEIPT.SUM' | translate}}:</b>
                    </td>
                    <td style="text-align: right; font-size: 10pt">
                        <b>{{allData.totalSum.toFixed(2)}}</b>
                    </td>
                </tr>

                <tr *ngIf="allData.sale > 0">
                    <td style="text-align: left; font-size: 10pt" colspan="2">
                        <b>{{'RECEIPT.SALE' | translate}}(<span *ngIf="allData.salePercent == 0">1%</span>
                            <span *ngIf="allData.salePercent > 0 && allData.salePercent < 8">5%</span>
                            <span *ngIf="allData.salePercent >= 8 && allData.salePercent < 12">10%</span>
                            <span  *ngIf="allData.salePercent >= 12">{{allData.salePercent}}%</span>):</b>
                    </td>
                    <td style="text-align: right; font-size: 10pt">
                        <b>{{(allData.totalSum - allData.total - allData?.bonuses).toFixed(2)}}</b>
                    </td>
                </tr>
            </ng-container>

            <tr *ngIf="allData?.bonuses !== 0">
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'BONUSES.BONUSES' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData?.bonuses | number: '1.2-2'}}</b>
                </td>
            </tr>


            <tr>
                <td style="text-align: left; font-size: 10pt" colspan="2">
                    <b>{{'RECEIPT.TOTAL_TO_PAY' | translate}}:</b>
                </td>
                <td style="text-align: right; font-size: 10pt">
                    <b>{{allData.total.toFixed(2)}}</b>
                </td>
            </tr>

            <tr>
                <td>
                    <b>{{'RECEIPT.PAYMENT_METHOD' | translate}}:</b>
                </td>
                <td colspan="2" style="text-align: right; font-size: 10pt">
                    <b>{{allData.terminal | translate}}</b>
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 9pt" colspan="2">
                    {{allData.district}}
                </td>
                <td style="text-align: right; font-size: 9pt">
                    {{allData.district_sum}}
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 9pt" colspan="3">
                    {{allData.point}}
                </td>
            </tr>

            <tr>
                <td style="text-align: left; font-size: 14pt" colspan="3">
                    {{'RECEIPT.ORDER_NUMBER' | translate}}: <b>{{allData.order_number}}</b>
                </td>
            </tr>

        </table>
    </font>
        <!--   <p *ngIf="allData.phone">{{allData.phone}}</p>-->
        <!--    <ng-container  *ngFor="let object of allData.hot_kitchen_products">-->
        <!--    </ng-container>-->
</div>
<div class="loading" *ngIf="loader === true">Loading&#8230;</div>
