import {Pipe, PipeTransform} from '@angular/core';
import {arrayTechCards} from './interface/techcard-edit-price.interface';

@Pipe({
  name: 'techcardEditPriceFilter'
})
export class techcardEditPriceFilter implements PipeTransform{
  transform(arrayAllTechCards: arrayTechCards[], search: string = ''): arrayTechCards[] {
    if (!search.trim()){
      return arrayAllTechCards;
    }
    return  arrayAllTechCards.filter(order => {
      return order.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

}
