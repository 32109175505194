import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {searchPipe} from './search.pipe';
import {ReducePipeTPipe} from './reduce-pipe-t.pipe';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import {SumBillsPipe} from '../cash-accounting/chief-cook-cash-accounting/operations-list/sum-bills.pipe';
import {ReduceCAPipe} from './@cash-accounting/reduce.pipe';
import {PrintDirective} from '../print.directive';
import { RouteListBuilderComponent } from './route-list-builder/route-list-builder.component';
import {DemoMaterialModule} from '../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentFilterPipe} from './route-list-builder/pipes/payment-filter.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerComponent} from './date-picker/date-picker.component';
import { PromocodeTableComponent } from './tables/promocode-table/promocode-table.component';
import {ReportsRoutesTableComponent} from './tables/reports-routes-table/reports-routes-table.component';
import {SearchInputComponent} from "./search-input-component/search-input.component";
import {searchTwoArgPipe} from "./searchTwoArg.pipe";
import {SearchAllFieldsPipe} from "./searchAllFields.pipe";
import {LanguageLogicService} from "./language/language-logic.service";
import {SelectLangComponent} from "./language/select-lang/select-lang.component";
import { ImageUploaderComponent} from "./ImageUpload/uploader/image-uploader.component";
import {FormControlPipe} from "./ImageUpload/formControl.pipe";

@NgModule({
    declarations: [
        searchPipe,
        ReducePipeTPipe,
        EmptyDataComponent,
        SumBillsPipe,
        ReduceCAPipe,
        PrintDirective,
        RouteListBuilderComponent,
        PaymentFilterPipe,
        DatePickerComponent,
        PromocodeTableComponent,
        searchPipe,
        SearchAllFieldsPipe,
        ReportsRoutesTableComponent,
        SearchInputComponent,
        searchTwoArgPipe,
        SelectLangComponent,
        ImageUploaderComponent,
        FormControlPipe
    ],
    imports: [
        CommonModule,
        DemoMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
    ],
    exports: [
        searchPipe,
        ReducePipeTPipe,
        EmptyDataComponent,
        SumBillsPipe,
        ReduceCAPipe,
        PrintDirective,
        RouteListBuilderComponent,
        DatePickerComponent,
        PromocodeTableComponent,
        ReportsRoutesTableComponent,
        SearchInputComponent,
        searchTwoArgPipe,
        SearchAllFieldsPipe,
        SelectLangComponent,
        ImageUploaderComponent,
        FormControlPipe
    ],
    providers: [
        LanguageLogicService
    ]
})
export class sharedModule {}
