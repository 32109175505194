import {Component, OnInit} from '@angular/core';
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map, takeUntil} from 'rxjs/operators';

import {AllQuicksaleService} from '../quick-sale/all-quick-sale/all-quicksale.service';
import {Orders} from '../../quick-sale/all-quick-sale/interface';
import {Unsubscribe} from '../unsubscribe';
import {DateService} from '../@date/date.service';
import {RouteListBuilderService} from '../@route-list-builder/route-list-builder.service';
import {Alltoasts} from '../../toasts/alltoasts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Users } from '../@users/interfaces/users.interface';
import { Observable } from 'rxjs';

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 10000
};

@Component({
    selector: 'app-route-list-builder',
    templateUrl: './route-list-builder.component.html',
    styleUrls: ['./route-list-builder.component.scss'],
    providers: [{provide: CDK_DRAG_CONFIG, useValue: DragConfig}]
})

export class RouteListBuilderComponent extends Unsubscribe implements OnInit {
    orders: Partial<Orders[]> = [];
    ordersRL: Partial<Orders[]> = [];
    couriers: Users[];
    users: Users[];
    paymentType: string = null;
    form: FormGroup;
    loader: boolean = false;
    isAdmin: boolean = localStorage.getItem('profs') === '1' ? true : false;

    constructor(private ordersService: AllQuicksaleService,
                private fb: FormBuilder,
                public modal: NgbModal,
                public dateService: DateService,
                private rlBuilderService: RouteListBuilderService,
                private toast: Alltoasts) {
        super();
    }

    ngOnInit(): void {
        this.initialForm();
        this.get();
        this.getUsersCourier();
    }

    initialForm(): void {
        this.form = this.fb.group({
            id_manager: [localStorage.getItem('id') ,Validators.required],
            id_courier: [null, Validators.required],
            distance: [0, Validators.required]
        });
    }

    get(): void {
        this.loader = true;
        this.rlBuilderService.getPendingOrders()
            .pipe(
                takeUntil(this.$destroy),
                map(orders => {
                    orders = orders.filter((item) => item.document_status != 0);
                    if (this.paymentType == null) {
                        return orders;
                    }
                    return orders.filter(item => item.terminalcheck == (this.paymentType === 'cash') ? 0 : 1);
                })
            )
            .subscribe({
                next: value => {
                    this.orders = value;
                },
                error: () => this.loader = false,
                complete: () => this.loader = false
            });
    }

    save(): void {
        const body = {
            ...this.form.value,
            orders: this.ordersRL.map(item => {
                item['order_id'] = item.id;
                return item;
            }),
            place_id: localStorage.getItem('place'),
            city_id: localStorage.getItem('city')
        };
        this.rlBuilderService.create(body)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.toast.showSuccess();
                    window.open("http://crm.3303.ua/routelist", "_self");
            }});
            this.modal.dismissAll()
            this.rlBuilderService.setToNeedUpdateRoutList();
    }

    drop(event: CdkDragDrop<Orders[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    getUsersCourier(): void{
        this.rlBuilderService.getUsers().pipe(takeUntil(this.$destroy)).subscribe(
            {
                next: value => this.users =  value,
                complete: () => {
                    this.couriers = this.users.filter(item => item.professionId === 7 )                    
                }                
            }
        )       
    } 
}
