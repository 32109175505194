import {Component, Input, OnInit} from '@angular/core';

import {PromoCode} from '../../../promocode/interface/promocode.interface';

@Component({
  selector: 'app-promocode-table',
  templateUrl: './promocode-table.component.html',
  styleUrls: ['./promocode-table.component.scss']
})
export class PromocodeTableComponent implements OnInit {

  @Input() promoCodeReport: PromoCode[];
  constructor() { }

  ngOnInit(): void {
  }

}
