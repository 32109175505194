import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {NavbarService} from '../navbar.service';

@Injectable()
export class ModulesResolver implements Resolve<Observable<any>>{
    constructor(private modulesService: NavbarService) {
    }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.modulesService.fetchModels();
    }
}
