import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DecimalPipe} from '@angular/common';
import {Injectable, PipeTransform} from '@angular/core';
import {DelayCommentData, EditPurposesData, Routes} from '../../route-lists/routelist/interface';
import {SortColumn, SortDirection} from '../../quick-sale/all-quick-sale/sortable.directive';
import {AppApiUrls} from '../../app.api.urls';
import {AppService} from '../app.service';
import {DateService} from '../@date/date.service';
import {Users} from "../@users/interfaces/users.interface";


interface SearchResult {
    orders: Routes[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(orders: Routes[], column: SortColumn, direction: string): Routes[] {
    if (direction === '' || column === '') {
        return orders;
    } else {
        return [...orders].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}


// tslint:disable-next-line:typedef
function matches(order: Routes, term: string, pipe: PipeTransform) {
    return order.courier_name.toLowerCase().includes(term.toLowerCase())
        || pipe.transform(order.order_number).includes(term)
        || pipe.transform(order.cashless).includes(term)
        || pipe.transform(order.cash).includes(term)
        || pipe.transform(order.total_order).includes(term)
        || pipe.transform(order.return_sum).includes(term)
        || order.date_creation.toLowerCase().includes(term.toLowerCase())
        || order.manager_name.toLowerCase().includes(term.toLowerCase())
        || order.task.toLowerCase().includes(term.toLowerCase());
}

@Injectable({providedIn: 'root'})

export class RoutelistService {
    loader = false;
    public ordersFromWebSite: any = [];
    public placeFromSelect: any = localStorage.getItem('place');
    public AllOrders: any = [];
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _loader$ = new BehaviorSubject<boolean>(true);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _search$ = new Subject<void>();
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _orders$ = new BehaviorSubject<Routes[]>([]);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _total$ = new BehaviorSubject<number>(0);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _state: State = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };
    // @ts-ignore
    routeList: Routes;

    constructor(public pipe: DecimalPipe,
                private http: HttpClient,
                private formatter: NgbDateParserFormatter,
                private appService: AppService,
                private dateService: DateService) {
        this._search$.pipe(
            tap(() => this._loader$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this._loader$.next(false))
        ).subscribe(result => {
            this._orders$.next(result.orders);
            this._total$.next(result.total);
        });

        this._search$.next();
    }

    get model(): NgbDate {
        return this.dateService.model;
    }

    set model(date) {
        this.dateService.model = date;
    }

    // tslint:disable-next-line:typedef
    get orders$() {
        return this._orders$.asObservable();
    }

    // tslint:disable-next-line:typedef
    get total$() {
        return this._total$.asObservable();
    }

    // tslint:disable-next-line:typedef
    get loader$() {
        return this._loader$.asObservable();
    }

    // tslint:disable-next-line:typedef
    get page() {
        return this._state.page;
    }

    // tslint:disable-next-line:typedef
    get pageSize() {
        return this._state.pageSize;
    }

    // tslint:disable-next-line:typedef
    get searchTerm() {
        return this._state.searchTerm;
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set page(page: number) {
        this._set({page});
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set pageSize(pageSize: number) {
        this._set({pageSize});
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set searchTerm(searchTerm: string) {
        this._set({searchTerm});
    }

    set sortColumn(sortColumn: SortColumn) {
        this._set({sortColumn});
    }

    set sortDirection(sortDirection: SortDirection) {
        this._set({sortDirection});
    }

    createDebt(id: number): Observable<void> {
        return this.http.get<void>(AppApiUrls.createDebtRL + id);
    }

    fetchAll = () => {
        let orders: any = [];
        const params: any = {
            from: this.formatter.format(this.model),
            to: this.formatter.format(this.model),
            place_id: localStorage.getItem('place'),
            city_id: localStorage.getItem('city')
        };
        return this.http.get(AppApiUrls.getAllRouteLists, {
            params
        }).pipe(tap(json => orders = json, reposnse => console.log(reposnse), () => {
            for (const item of orders) {
                item.task = '';
                for (let i = 0; i < item.orders.length; i++) {
                    if (!item.orders[i]) {
                        item.orders.splice(i, 1);
                    }
                    item.task += (i < item.orders.length - 1) ?
                        `${item.orders[i].order_number}, ` : item.orders[i].order_number;
                }
            }
            this.AllOrders = orders.sort((a, b) => b.order_number - a.order_number);
            this._search$.next();
        }));
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

        // 1. sort
        let orders = sort(this.AllOrders, sortColumn, sortDirection);

        // 2. filter
        orders = orders.filter(order => matches(order, searchTerm, this.pipe));
        const total = orders.length;

        // 3. paginate
        orders = orders.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({orders, total});
    }

    addToRoute = (order, index) => {
        return this.http.get<Routes>(AppApiUrls.getOrderForEditInRouteList, {
            params: {
                date: this.formatter.format(this.model),
                city_id: localStorage.getItem('city'),
                order_number: order
            }
        }).pipe(tap(json => {
                this.AllOrders[index].orders.push(json);
            },
            reposnse => console.log(reposnse), () => {

            }));
    }
    getInfoAboutRoute = (id) => {
        this.loader = true;
        return this.http.get<Routes>(AppApiUrls.getRouteList + id, {}).pipe(tap(json => this.routeList = json,
            reposnse => console.log(reposnse), () => {
                if (id == this.routeList.route_list_id) {
                    this.routeList.task = '';
                    for (let i = 0; i < this.routeList.orders.length; i++) {
                        this.routeList.task += (i < this.routeList.orders.length - 1) ?
                            `${this.routeList.orders[i].order_number}, ` : this.routeList.orders[i].order_number;
                    }
                    this.updateState(id).subscribe();
                    setTimeout(() => {
                        window.print();
                        this.loader = false;
                    }, 2000);
                }
            }));
    }

    updateState = (id) => {
        this.AllOrders[this.AllOrders.findIndex(t => t.route_list_id == id)].state = 'printed';
        return this.http.put<Routes>(AppApiUrls.getRouteList + id, this.AllOrders[this.AllOrders.findIndex(t => t.route_list_id == id)])
            .pipe(tap(json => {
                },
                reposnse => console.log(reposnse), () => {
                }));
    }

    updatePaymentState = (id) => {
        const index = this.AllOrders.findIndex(t => t.route_list_id == id);
        this.AllOrders[index].pay_state = 'done';

        return this.http.put<Routes>(AppApiUrls.getRouteList + id, this.AllOrders[index])
            .pipe(tap(json => {
                },
                reposnse => console.log(reposnse), () => {
                }));
    }
    saveEditableRoute = (index, comment, purposeId) => {
        return this.http.put<Routes>(AppApiUrls.getRouteList + this.AllOrders[index].route_list_id, {
            ...this.AllOrders[index],
            user_id: localStorage.getItem('id'),
            purpose_id: purposeId,
            comment}
        )
            .pipe(tap(json => {
                },
                reposnse => console.log(reposnse), () => {
                    this.fetchAll();
                }));
    }
    sendCheckBox(id): Observable<any> {
        const body = {
            place_id: localStorage.getItem('place'),
            user_id: localStorage.getItem('id')
        };
        return this.http.post<any>(AppApiUrls.sendCheckOrder() + id, body);
    }

    StatusRRO(checkboxId): Observable<any> {
        return this.http.get<any>(AppApiUrls.checkboxCheckOrder() + checkboxId);
    }

    updateRL(routeListId: number): Observable<any> {
        const body = {};
        return this.http.put<any>(AppApiUrls.updateTemplate()  + routeListId, body);
      }

      deleteRoute(id: number): Observable<any>{
          return this.http.delete<any>(AppApiUrls.routeClose() + '/?routelist_id=' + id);
      }

    getEditPurposes(): Observable<EditPurposesData> {
        return this.http.get<EditPurposesData>(AppApiUrls.routeListEditPurposes());
    }

    postDelayComment(orderId: number, message: string): Observable<DelayCommentData>{
        const body = {
            user_id: localStorage.getItem('id'),
            order_id:  orderId,
            message
        };
        return this.http.post<DelayCommentData>(AppApiUrls.delayComment(), body);
    }

    deleteDelayComment(orderId: number): Observable<DelayCommentData>{
        return this.http.delete<DelayCommentData>(AppApiUrls.delayComment(), {
            params: {
                order_id:  orderId,
                user_id: localStorage.getItem('id'),
            }
        });
    }
}
