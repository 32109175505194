import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppApiUrls} from '../../../app.api.urls';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Alltoasts} from '../../../toasts/alltoasts';
import { Observable } from 'rxjs';
import { PendingPrice, PendingPriceData, arrayCity, arrayTechCards} from './interface/techcard-edit-price.interface';

@Injectable({providedIn: 'root'})

export class allItemsPEService {
  public arrayAllCity: arrayCity[] = [];
  public arrayAllTechCards: arrayTechCards[] = [];
  public originalArray: arrayTechCards[] = [];
  public loaderCity = false;
  public loaderTech = false;
  public inputSearch: string;



  GetAllPriceTechCards() {
    this.loaderCity = true;
    const body = new HttpParams()
      .set('lang', 'ru');
    return this.http.get<arrayCity>(AppApiUrls.CitiesInfo(),
      {
        params: body
      })
      .subscribe(
        (val) => {

          this.arrayAllCity = val as any;
          
        },
        response => {
          console.log('POST call in error', response);
          this.Alltoasts.showError();
        },
        () => {
            this.loaderCity = false;
        });

  }


  updatePriceDataBase(id, cityId?){
    const index = this.arrayAllTechCards.findIndex(t => t.id === id);
    const body = new HttpParams()
      .set('price_id', String(this.arrayAllTechCards[index].price_id))
      .set('price', String(this.arrayAllTechCards[index].price))
      .set('regular_price', String(this.arrayAllTechCards[index].regular_price))
      .set('sale_enabled', Number(!this.arrayAllTechCards[index].showInput));
    return this.http.put<arrayCity>(AppApiUrls.enableProduct(),
      body)
      .subscribe(
        (val) => {
        },
        response => {
          console.log('POST call in error', response);
          this.Alltoasts.showDanger();

        },
        () => {
          this.Alltoasts.showSuccess();
          this.GetAllPriceCards(cityId);
        });
  }

  openModal(content){
    this.modalService.open(content);
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 3000);

  }

  GetAllPriceCards(id) {
    this.loaderTech = true;
    return this.http.get<arrayCity>(AppApiUrls.ProductCity + "?city_id=" + id + "&nocache=1")
      .subscribe(
        (val) => {

          this.arrayAllTechCards =  val.data as any;
            for ( let i of  this.arrayAllTechCards){
                i.showInput = i.price === i.regular_price;
            }
        },
        response => {
          console.log('POST call in error', response);
        },
        () => {
          this.loaderTech = false;
        });
  }

  constructor(private http: HttpClient, public modalService: NgbModal, private Alltoasts: Alltoasts) {

  }

  getSemifinishAndIngredients(id: number): Observable<PendingPriceData> {
    return this.http.get<PendingPriceData>(AppApiUrls.pendingPrice() + "?city_id=" + id);
  }

  createPriceForNewSemAndIngr(id: number, cityId: number , price: number): Observable<PendingPrice>{
    return this.http.post<PendingPrice>(AppApiUrls.createPrice(), {
        product_id: id,
        city: cityId,
        price: price
    });
  }

}

