<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-6">

      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{'STORAGE.TECHCARDS_PRODUCT' | translate}}</h5>
        </div>
        <div class="card-body">
          <img src="../../../assets/img/box.png" width="30%">
        </div>
        <div class="card-footer">
          <a [routerLink]="['product']" class="btn btn-success">{{'GLOBAL.BUTTONEDIT' | translate}}</a>
        </div>
      </div>

    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{'STORAGE.TECHCARDS_PRICE' | translate}}</h5>
        </div>
        <div class="card-body">
          <img src="../../../assets/img/note.png" width="30%">

        </div>
        <div class="card-footer">
          <a [routerLink]="['price']" class="btn btn-success">{{'GLOBAL.BUTTONEDIT' | translate}}</a>

        </div>
      </div>
    </div>


    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{'STORAGE.TECHCARDS_ING&SEMI' | translate}}</h5>
        </div>
        <div class="card-body">
        </div>
        <div class="card-footer">
          <button class="btn btn-success" disabled>{{'GLOBAL.BUTTONEDIT' | translate}}</button>
        </div>
      </div>
    </div>


  </div>
</div>
