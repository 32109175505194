import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../@shared/profile/profile.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Md5} from 'ts-md5/dist/md5';
import {Alltoasts} from '../toasts/alltoasts';
import {Unsubscribe} from '../@shared/unsubscribe';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends Unsubscribe implements OnInit {


    checkBox: boolean;
    prof = localStorage.getItem('profs');
    select: number;
    group: any[];
    places$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    form: FormGroup = new FormGroup({
        password: new FormControl(null, Validators.required),
        place: new FormControl(null, Validators.required),
        select: new FormControl({disabled: false})
    });
    md5 = new Md5();

    constructor(public service: ProfileService,
                private toasts: Alltoasts,
    ) {
        super();
    }

    UserName = localStorage.getItem('name');
    UserImg = localStorage.getItem('image');
    UserProf = localStorage.getItem('prof_name');
    phone = localStorage.getItem('phone');
    id = localStorage.getItem('id');
    showPassword = 'password';
    groupId = localStorage.getItem('group');


    ngOnInit(): void {
        this.getPlacesById();
        if (this.groupId === '0') {
            this.checkBox = false;
        } else {
            this.checkBox = true;
        }
        this.getCityGroup();
    }

    fieldsChange(values: any): void {
        this.checkBox = values.currentTarget.checked;
    }

    getCityGroup(): void {
        this.service.getCityGroup()
            .pipe(takeUntil((this.$destroy)))
            .subscribe({
                next: value => this.group = value,
                complete: () => this.select = this.group[0].id
            });
    }

    updateGroup(): void {
        let groupId = 0;
        if (this.checkBox === true) {
            groupId = this.select;
        }
        this.service.updateCityGroup(this.id, groupId)
            .pipe(takeUntil((this.$destroy)))
            .subscribe({
                complete: () => {
                    if (this.checkBox === true) {
                        localStorage.setItem('group', this.select.toString());
                    } else {
                        localStorage.setItem('group', '0');
                    }
                    this.toasts.showSuccess();
                }
            });
    }

    viewPassword = () => {
        if (this.showPassword === 'password') {
            this.showPassword = 'text';
        } else {
            this.showPassword = 'password';
        }
    }

    getPlacesById = () => {
        this.service.get()
            .pipe(takeUntil((this.$destroy)))
            .subscribe({
                next: value => this.places$.next(value),
            });
    }

    updatePassword = () => {
        const body = {
            password: this.md5.appendStr(this.form.value.password).end(),
        };
        this.service.update(this.id, body)
            .pipe(takeUntil((this.$destroy)))
            .subscribe({
                complete: () => this.toasts.showSuccess()
            });
    }
    updatePlace = () => {
        const body = {
            place: this.form.value.place
        };
        this.service.update(this.id, body)
            .pipe(takeUntil((this.$destroy)))
            .subscribe({
                next: value => {
                    localStorage.setItem('place', this.form.value.place);
                    localStorage.setItem('city', value.city_id);
                    localStorage.setItem('type', value.check_type);
                },
                complete: () => {
                    this.toasts.showSuccess();
                }
            });
    }

}
