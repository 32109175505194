<div class="container-fluid">

    <button class="btn btn-success mt-3" [routerLink]="'/routelist'">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        {{'ROUTLISTCREATE.TO_ROUTE_SHEETS' | translate}}</button>
        <div class="d-flex align-items-center ">
            <app-date-picker  (dateSelect)="onDateSelectionChanged($event)"  ></app-date-picker>
            <button (click)="createNewRoutes()" class="btn btn-primary  ml-2 " >{{'ROUTLISTCREATE.CREATE_A_ROUTE' | translate}}</button>
        </div>
        <div class=" d-flex">
            <div class="input-group-search">
                <input class="form-control mr-2 widthInptSearch" id="searchTerm" type="text" name="searchTerm"
                    [(ngModel)]="searchInput" (input)="sortOrdersByFutureCheck()" placeholder="{{'GLOBAL.SEARCH' | translate}}"/>
                <span class="clear-icon" (click)="clearSearch()">&times;</span>
            </div>

            <select class="form-control widthSelect"  [(ngModel)]="selectedFilter"  (change)="sortOrdersByFutureCheck()">
                <option value="{{ filterTypeEnum.All }}">{{ 'ROUTLISTCREATE.ALL_CHECKS' | translate }}</option>
                <option value="{{ filterTypeEnum.FutureCheck1 }}">{{ 'ROUTLISTCREATE.PREORDER' | translate }}</option>
                <option value="{{ filterTypeEnum.FutureCheck0 }}">{{ 'ROUTLISTCREATE.CURRENT_ORDER' | translate }}</option>
            </select>
        </div>

    <div class="row mt-4" cdkDropListGroup>    

        <div class="col-4 overflow"
             cdkDropList (cdkDropListDropped)="drop($event, orderEventEnum.LeftBlock)"
             [cdkDropListData]="filteredOrders">
            <div *ngFor="let order of filteredOrders ; index as i" class="alert alert-light mx-2" cdkDrag>
                <div class="d-flex justify-content-between mb-2">
                    <div class="alert-warning p-2 rounded">
                        <span class="font-weight-bold">{{'ROUTLISTCREATE.CHECK' | translate}}: </span>
                        <span>{{order.order_number }}</span>
                    </div>
                    <div class="alert-warning p-2 rounded">
                        <span class="font-weight-bold">{{'DEBTORS.DATE' | translate}}: </span>
                        <span>{{order.date_added | date:'dd.MM.yyyy HH:mm'}}</span>
                    </div>
                    <div class="alert-warning p-2 rounded">
                        <span class="font-weight-bold">{{'ROUTLISTCREATE.ZONE' | translate}}: </span>
                        <span>{{order.delivery_zone?.name}}</span>
                    </div>
                </div>
                <hr>
                <div class="mb-2">
                    <span class="font-weight-bold">{{'REPORT_DUPLICATE_ORDERS.ADDRESS' | translate}}: </span>
                    <span>{{'QUICK-SALE.STREET' | translate}}.{{order.street}} {{'QUICK-SALE.HOUSE' | translate}} {{order.house}}</span>
                    <span *ngIf="!order.delivery_zone?.name" class="float-right mb-2" role="button"  title="Зона не обрана, оберіть зону вручну в документі швидкого продажу">⚠️</span>
                </div>
                <div class="mb-2">
                    <span class="font-weight-bold">{{'GLOBAL.SUM' | translate}}: </span>
                    <span>{{order.total}}₴</span>
                        <button *ngIf="order.google_location" class="btn btn-sm btn-success float-right mb-2 " (click)="openMapModal(showMapModal, i)">🚩</button>
                        <button *ngIf="!order.google_location" class="btn btn-sm btn-danger float-right mb-2 " (click)="openMapModal(showMapModal, i)">🚩</button>
                </div>

            </div>
        </div>

        <div class="col-8 overflow">
            <div *ngFor="let routes of pendingRoutes" cdkDropList (cdkDropListDropped)="drop($event, orderEventEnum.RightBlock)"
                 [cdkDropListData]="routes.orders"
                 >
                <div class="rl--container my-2" [ngClass]="{'alert-warning': routes.route_state === 'ready'}">
                    <span *ngIf="routes.orders.length == 0"  class="d-flex justify-content-center text-primary">{{'ROUTLISTCREATE.TRANSFER_THE_ORDER_TO_THIS_FIELD' | translate}} </span>
                    <span [hidden]="routes.plan_time == null" class="text-center text-primary">{{routes.id}} / {{'ROUTLISTEDIT.DATE_AND_TIME_OF_DEPARTURE' | translate}}: {{routes.plan_time | date:'dd.MM.yyyy HH:mm'}}</span>
                    <div class="content-container second-block">
                        <div *ngFor="let object of routes.orders; index as i" class="alert alert-primary mx-2 " 
                            [ngClass]="{                 
                                'alert-success': object.packed === 1,
                                'alert-danger': object.packed === 0 }"
                        cdkDrag>
                            <div class="d-flex justify-content-between mb-2">
                                <div class="alert-warning p-2 rounded">
                                    <span class="font-weight-bold">{{'ROUTLISTCREATE.CHECK' | translate}}: </span>
                                    <span>{{object.order?.order_number}}</span>
                                </div>
                                <div class="alert-warning p-2 rounded">
                                    <span class="font-weight-bold">{{'DEBTORS.DATE' | translate}}: </span>
                                    <span>{{object.order?.date_added | date:'dd.MM.yyyy HH:mm'}}</span>    
                                </div>
                                <div class="alert-warning p-2 rounded">
                                    <span class="font-weight-bold">{{'ROUTLISTCREATE.ZONE' | translate}}: </span>
                                    <span>{{object.order?.delivery_zone?.name}}</span>

                                </div>
                            </div>
                            <hr>
                            <div class="mb-2">
                                <span class="font-weight-bold">{{'REPORT_DUPLICATE_ORDERS.ADDRESS' | translate}}: </span>
                                <span>{{'QUICK-SALE.STREET' | translate}}.{{object.order?.street}} {{'QUICK-SALE.HOUSE' | translate}} {{object.order?.house}}</span>
                            </div>
                            <div class="mb-2">
                                <span class="font-weight-bold">{{'GLOBAL.SUM' | translate}}: </span>
                                <span>{{object.order?.total}}₴</span>
<!--                                <span *ngIf="!object.order?.google_location" class="float-right mb-2 icon-font-size">⚠️</span>-->
                                <span *ngIf="!object.order?.google_location || !object.order?.delivery_zone?.name " role="button" class="float-right mb-2 icon-font-size"
                                      [title]="getTooltipText(object)">⚠️</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="footer-container my-2" >
                        <div class="button-group">
                            <button class="btn btn-primary mr-2" (click)="navigateToEdit(routes.id)" [disabled]="routes.orders.length == 0">{{'ROUTLISTCREATE.CALCULATE_THE_ROUTE' | translate}}</button>
                            <button class="btn btn-success" (click)="sendToWork(modal, routes.id)" [hidden]="routes.route_state !== 'ready'">{{'ROUTLISTCREATE.TO_WORK' | translate}}</button>
                            <button class="btn btn-primary rotate-icon ml-3" (click)="autoComplete(routes.id)" >🗘</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loading" *ngIf="loader === true">Loading&#8230;</div>
</div>

<ng-template #modal >
    <div class="modal-header">
        <h2 class="modal-title font-weight-bold" id="modal-basic-title">{{'ROUTLISTCREATE.CHOOSE_A_COURIER_AND_SEND_THE_ROUTE_TO_WORK' | translate}}</h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>    
    <div class="modal-body ">
            <span >{{'ROUTLISTCREATE.CHOOSE_A_COURIER' | translate}}:</span>
            <select   class="form-control my-2 width" [(ngModel)]="selectedCourierId" >
                <ng-container *ngFor="let courier of couriers">
                    <option [value]="courier.id">{{courier.name}}</option>
                </ng-container>                        
            </select> 
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success " [disabled]="!selectedCourierId"  (click)="add()">{{'PAYROLL.CREATE' | translate}}</button>
    </div>   
</ng-template>

<ng-template #showMapModal >
    <div class="modal-header">
        <h5 class="modal-title font-weight-bold">Виберіть місце на карті та поставте маркер після цього натисніть ОК</h5>
        <button type="button" class="close" (click)="closeMapModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'ROUTLISTEDIT.SPECIFY_THE_COORDINATES_FOR_ACCURATE_DELIVERY' | translate}}:</p>
        <input class="form-control mb-2" [(ngModel)]="ModalLocation">
        <div id="mapModal" style="height: 400px; width: 100%;"></div>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="updateCoordinate(ModalOrderId, ModalLocation)">ОК</button>
    </div>
</ng-template>
