<div class="container-fluid">
    <table class="table table-sm custom-table" width="100%">
        <thead class="sticky-top">
        <tr class="sticky-top table-secondary">
            <th class="sticky-top">№</th>
            <th class="sticky-top">{{'QUICK_SALE.ORDER_NUMBER' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.DATE' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TIME' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.PLACE' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.SALES' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_SALES' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_WITH_SALE_PICKUP' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_WITH_SALE_PICKUP_NOT_CASH' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_WITH_SALE' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_WITH_SALE_NOT_CASH' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_SALE' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.DELIVERY_BY_DISTRICT' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.DELIVERY_CC' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_IN_CASHIER' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.TOTAL_NOT_CASH' | translate}}</th>
            <th class="sticky-top">{{'QUICK_SALE.LIQPAY' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let order of orders$ | async; let i = index">
        <tr *ngIf="order.status == 1 || order.status == 4">
            <td>{{i + 1}}</td>
            <td>{{order.order_number}}</td>
            <td>{{order.futuredate}}</td>
            <td>{{order.futuretime}}</td>
            <td>{{order.place_name}}</td>
            <td>{{order.sale}}</td>
            <td>{{(order.total / (100-order.sale) * 100) * (order.sale/100) | number: '1.0-0'}}</td>
            <td>
                <span *ngIf="order.pickupcheck == 1 && order.terminalcheck == 0">{{order.total}}</span>
            </td>
            <td><span *ngIf="order.pickupcheck == 1 && order.terminalcheck != 0">
                {{order.total}}
            </span>
            </td>
            <td><span *ngIf="order.pickupcheck == 0 && order.terminalcheck == 0">{{order.total}}</span></td>

            <td><span *ngIf="order.pickupcheck == 0 && order.terminalcheck != 0">
                {{order.total}}
            </span></td>
            <td>
                {{order.total}}
            </td>
            <td>{{order.district_price}}</td>
            <td>
            </td>
            <td></td>
            <td>
            </td>
            <td *ngIf="order.paid_status === 1">{{order.total}}</td>
            <td *ngIf="order.paid_status !== 1"></td>
        </tr>
        </ng-container>
        <tr>
            <th colspan="6">{{'QUICK_SALE.TOTAL' | translate}}:</th>
            <th>{{sumSale}}</th>
            <th>{{sumPickup}}</th>
            <th>{{sumPickupTerminal}}</th>
            <th>{{sumDelivery}}</th>
            <th>{{sumDeliveryTerminal}}</th>
            <th>{{sumFull}}</th>
            <th>{{sumDistrictPrice}}</th>
            <th>{{sumCourier}}</th>
            <th>{{sumDelivery + sumPickup - sumCourier}}</th>
            <th>{{sumPickupTerminal + sumDeliveryTerminal }}</th>
            <th>{{totalLiqPay}}</th>
        </tr>
        </tbody>
    </table>
</div>

<div class="loading" *ngIf="request.loader === true">Loading&#8230;</div>

