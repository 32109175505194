import {Component, OnInit} from '@angular/core';
import {ChangePrinterService} from '../services/change-printer.service';
import {Unsubscribe} from '../../@shared/unsubscribe';
import {takeUntil} from 'rxjs/operators';
import {Printers} from "../interfaces/printers.interfaces";
import {Alltoasts} from "../../toasts/alltoasts";

@Component({
    selector: 'app-change-printer',
    templateUrl: './change-printer.component.html',
    styleUrls: ['./change-printer.component.scss']
})
export class ChangePrinterComponent extends Unsubscribe implements OnInit {

    printers: Printers[];
    inputSearch = '';
    constructor(
        private service: ChangePrinterService,
        private toast: Alltoasts) {
        super();
    }
    ngOnInit(): void {
        this.getPrinters();
    }

    getPrinters(): void {
        this.service.getPrinters().pipe(takeUntil(this.$destroy)).subscribe({
            next: value => this.printers = value.data
        });
    }

    updatePrinters( id: number , obj: { check_type: number; name: string; }): void {
        const requestBody = {
            check_type: obj.check_type,
            name: obj.name,
        };
        this.service
            .updatePrinters(id, requestBody)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.toast.showSuccess();
                },
            });
    }

    clearSearch(): void {
        this.inputSearch = '';
    }
}
