import { Component, OnInit } from '@angular/core';
import {Unsubscribe} from '../../@shared/unsubscribe';
import {HistoryOrdersService} from './history-orders.service';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {CallBack, HistoryOrderById, HistoryOrders} from './history-orders.interface';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AllQuicksaleService} from '../../@shared/quick-sale/all-quick-sale/all-quicksale.service';
import {OrderStatus} from "../all-quick-sale/interface";

@Component({
  selector: 'app-history-orders',
  templateUrl: './history-orders.component.html',
  styleUrls: ['./history-orders.component.scss']
})
export class HistoryOrdersComponent extends Unsubscribe implements OnInit {

  orders$: BehaviorSubject<Partial<HistoryOrders[]>> = new BehaviorSubject<Partial<HistoryOrders[]>>([]);
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchInput: string;
  orderInfo$: BehaviorSubject<Partial<HistoryOrderById>> = new BehaviorSubject<Partial<HistoryOrderById>>({});
  callBack: CallBack[];
  orderStatusEnum = OrderStatus;
  constructor(
      private modalService: NgbModal,
      private service: HistoryOrdersService,
      private serviceQS: AllQuicksaleService,
      private calendar: NgbCalendar,
      public formatter: NgbDateParserFormatter,

  ) {
    super();
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.getOrders();
    this.getCallBack();
  }

  onDateSelection = (date: NgbDate) => {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date) {
      this.toDate = date;
      this.getOrders();
      this.getCallBack();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered = (date: NgbDate) => this.fromDate && !this.toDate &&
      this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)

  isInside = (date: NgbDate) => this.toDate && date.after(this.fromDate) &&
      date.before(this.toDate)

  isRange = (date: NgbDate) => date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date)

  validateInput = (currentValue: NgbDate | null, input: string): NgbDate | null => {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  getOrders(): void {
    this.service.getOrders(this.formatter.format(this.fromDate), this.formatter.format(this.toDate))
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: value => this.orders$.next(value),
          complete: () => this.orders$.value.reverse()
        });
  }

  confirmFromSiteOrders = (id?, city?, place?) => {
    if (city && place) {
      localStorage.setItem('city', city);
      localStorage.setItem('place', place);
    }
    this.serviceQS.checkOrderFromWS(id, 'history').subscribe();
  }

  getOrderById(id): void {
    this.service.getOrderById(id)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: value => this.orderInfo$.next(value),
          complete: () => console.log(this.orderInfo$.value)
        });
  }

  close = () => {
    this.modalService.dismissAll();
  }
  open = (content, size, id) => {
    this.getOrderById(id);
    this.modalService.open(content, {size});
  }

  openCallBack = (content, size) => {
    this.modalService.open(content, {size});
  }

  getCallBack(): void{
      this.service.getCallBack(this.formatter.format(this.fromDate), this.formatter.format(this.toDate))
          .pipe(takeUntil(this.$destroy))
          .subscribe({
            next: (value) => {
              this.callBack = value.data;
            }
          });
  }

  putCallBackRequest(newStatus: OrderStatus, id: number): void {
    const requestBody = { status: newStatus };
    this.serviceQS.putCallBackRequest(id, requestBody)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          complete: () => {
            this.getCallBack();
          }
        });
  }


  get allSum(): number {
    return this.orderInfo$.value.order.reduce((a, b) => a + Number(b.price) * (b.quantity), 0);
  }

}
