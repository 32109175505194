<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4" style="border-radius: 4px">
      <app-packagepanel-leftblock></app-packagepanel-leftblock>
    </div>
    <div class="col-sm-8 ">
      <app-packagepanel-mainblock></app-packagepanel-mainblock>
    </div>
  </div>
</div>
