import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Calculate, Couriers, PendingOrders, PendingRoutesData, SetOrders, SetOrdersData, SetOrdersToRoute, Location, PendingRoutes } from './interface/routlist.interface';
import { Observable } from 'rxjs';
import { AppApiUrls } from 'src/app/app.api.urls';


@Injectable({
  providedIn: 'root'
})

export class RouteListEditService {

  constructor(private http: HttpClient) { }

  getPendingOrders(dateFrom: string, dateTo: string): Observable<PendingOrders[]> {
    return this.http.get<PendingOrders[]>(AppApiUrls.pendingOrders(),
        {
            params: {
                place_id: localStorage.getItem('place'),
                date_from: dateFrom,
                date_to: dateTo
            }
        });
  }

  getPendingRoutes(): Observable<PendingRoutesData> {
    return this.http.get<PendingRoutesData>(AppApiUrls.pendingRoutes(),
        {
            params: {
                place_id: localStorage.getItem('place')
            }
        });
  }

  updateOrderNumber(body: SetOrders): Observable<SetOrdersData>{
    return this.http.post<SetOrdersData>(AppApiUrls.setOrders(), body);
  }

  updateLocation(id: number, location: string): Observable<Location>{
    return this.http.post<Location>(AppApiUrls.updateOrderLocation(),
    {      
      quicksale_id: id,
      google_location: location
    }
    );
  }

  addToRoute(body: SetOrdersToRoute): Observable<SetOrdersToRoute>{
    return this.http.post<SetOrdersToRoute>(AppApiUrls.addToRoute(), body);
  }

  deleteFromRoute(id: number): Observable<SetOrdersToRoute>{
    return this.http.delete<SetOrdersToRoute>(AppApiUrls.addToRoute() + '?order_id=' + id);
  }

  createNewRoutes(){
    return this.http.post(AppApiUrls.addNewOrder(),
    {      
      id_manager: localStorage.getItem('id')
    });
  }

  calculate(routeId: number, customOrder: number, datechanged: boolean , planTime: string ): Observable<Calculate>{
    if(datechanged){
      return this.http.post<Calculate>(AppApiUrls.calculate(),
      {      
        routelist_id: routeId,
        custom_order: customOrder,
        plan_time: planTime
      });
    }else{
      return this.http.post<Calculate>(AppApiUrls.calculate(),
    {      
      routelist_id: routeId,
      custom_order: customOrder
    });
    }
    
  }

  getCouriers(): Observable<Couriers[]> {
    return this.http.get<Couriers[]>(AppApiUrls.couriers(),
        {
            params: {
                place_id: localStorage.getItem('place')
            }
        });
  }

  readyToSend(routeId: number, distance: number, estimatedTurnover: number): Observable<PendingRoutes>{
    return this.http.post<PendingRoutes>(AppApiUrls.ready(),
    {      
      routelist_id: routeId,
      distance: distance,
      estimated_turnover: estimatedTurnover
    });
  }

  sendToWork(routeId: number, courierId: number): Observable<PendingRoutes>{
    return this.http.post<PendingRoutes>(AppApiUrls.sendToWork(),
    {      
      routelist_id: routeId,
      id_courier: courierId
    });
  }

  autoComplete(routeId: number): Observable<PendingRoutes>{
    return this.http.post<PendingRoutes>(AppApiUrls.autoComplete(),
    {      
      id: routeId,
    });
  }
}
