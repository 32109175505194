import {Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {allItemsPEService} from '../../@shared/storagesettings/techcard-edit-price/techcard-edit-price.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExcelExportService} from '../../@shared/excel.export.service';
import { Unsubscribe } from 'src/app/@shared/unsubscribe';
import { takeUntil} from 'rxjs/operators';
import {
  arrayTechCards,
  PendingPrice
} from 'src/app/@shared/storagesettings/techcard-edit-price/interface/techcard-edit-price.interface';
import { Alltoasts } from 'src/app/toasts/alltoasts';

@Component({
  selector: 'app-techcard-edit-price',
  templateUrl: './techcard-edit-price.component.html',
  styleUrls: ['./techcard-edit-price.component.scss'],
})
export class TechcardEditPriceComponent extends Unsubscribe implements OnInit {
  selectedId: number | null = null;
  inputValue: number;
  cityId: number;
  currency = JSON.parse(localStorage.getItem('cityInfo'))?.country?.currency;
  pendingPrice: PendingPrice[];
  showPromotionalPrice = false;
  originalArray: arrayTechCards[] = [];
  isFormValid: boolean = false;
  constructor(
      public peService: allItemsPEService,
      public modalService: NgbModal,
      private excel: ExcelExportService,
      private Alltoasts: Alltoasts,
  )
  {
    super();
  }

  ngOnInit(): void {
    this.peService.GetAllPriceTechCards();
  }
  getAllTechCards(id): void{
    this.peService.GetAllPriceCards(id);
    this.cityId = id;
  }
  open(content): void {
      this.peService.openModal(content);
  }
  updatePriceDataBase(obj): void{
    this.showPromotionalPrice = false;
    this.peService.updatePriceDataBase(obj.id, this.cityId);
  }

  excelExport = (excel) => {
    this.excel.excel(excel);
  }

  addNewProductPrice(modal): void{
    this.getRoutes();
    this.modalService.open(modal);
  }

  close(): void {
    this.selectedId = null;
    this.inputValue = null;
    this.modalService.dismissAll();
  }


  getRoutes(): void {
    this.peService.getSemifinishAndIngredients(this.cityId).pipe(takeUntil(this.$destroy)).subscribe({
        next: value => {this.pendingPrice = value.data}
    });
  }


  addIngredients(): void{
    this.peService.createPriceForNewSemAndIngr( this.selectedId, this.cityId, this.inputValue)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        complete: () => {
          this.getAllTechCards(this.cityId);
          this.Alltoasts.showSuccess();
          this.close();
        },
      });
  }

  toggleInput(obj: arrayTechCards): void {
    obj.showInput =  false;
  }

  disableInput(object: arrayTechCards): void {
    this.showPromotionalPrice = false;
    object.showInput = true;
    object.price = object.regular_price;
    this.peService.updatePriceDataBase(object.id , this.cityId);
  }

  filterPromotionalPrice(): void {
    this.showPromotionalPrice = !this.showPromotionalPrice;
    if (this.showPromotionalPrice) {
      this.originalArray = [...this.peService.arrayAllTechCards];
      this.peService.arrayAllTechCards = this.peService.arrayAllTechCards
          .filter(object => object.regular_price !== object.price);
    } else {
      this.peService.arrayAllTechCards = [...this.originalArray];
    }
  }

  checkFormValidity(): void {
    this.isFormValid = this.selectedId !== null && this.inputValue !== null;
  }
}
