import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppApiUrls} from '../app.api.urls';
import {map} from 'rxjs/operators';


@Injectable({providedIn: 'root'})

export class AppService {
  date: any;
  GetDate = request => this.http.get(AppApiUrls.GetDate, request).pipe(map(
      (response) => {
        return response;
      }
  ))

  constructor(private http: HttpClient) {
  }
}
