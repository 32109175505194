<div class="row mt-5">
    <div class="col-md-12">
        <div class="error-template">
            <div class="error-details">
                <h2>
                    Немає даних!
                </h2>
            </div>
            <div class="error-actions">
                <img src="assets/img/sadsushi.png" width="100vh">
            </div>
        </div>
    </div>
</div>
