import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class ExcelWhithoutPhoneService {
    constructor(private calendar: NgbCalendar) {
    }

    excel = content => {
        const fileName = `${content}_${this.calendar.getToday().day}.${this.calendar.getToday().month}.${this.calendar.getToday().year}.xlsx`;
        const element = document.getElementById(content);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        ws['!cols'] = [
            {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}, {wpx: 120}
        ];

        // Regular expression to match phone numbers
        const phoneNumberRegex = /^[+]?[0-9\s-().]{7,}$/;

        // Identify the column containing phone numbers
        const range = XLSX.utils.decode_range(ws['!ref']);
        let phoneColumnIndex = 1;

        // First pass: identify the column with phone numbers
        for (let C = range.s.c; C <= range.e.c; ++C) {
            for (let R = range.s.r; R <= range.e.r; ++R) {
                const cellAddress = {c: C, r: R};
                const cellRef = XLSX.utils.encode_cell(cellAddress);
                if (ws[cellRef] && phoneNumberRegex.test(String(ws[cellRef].v))) {
                    phoneColumnIndex = C;
                    break;
                }
            }
            if (phoneColumnIndex !== 1) {
                break;
            } // Stop if we've found the phone number column
        }

        // Second pass: convert the phone number column cells to string
        if (phoneColumnIndex !== 1) {
            for (let R = range.s.r; R <= range.e.r; ++R) {
                const cellAddress = {c: phoneColumnIndex, r: R};
                const cellRef = XLSX.utils.encode_cell(cellAddress);
                if (ws[cellRef]) {
                    ws[cellRef].t = 's';
                    ws[cellRef].v = String(ws[cellRef].v);
                }
            }
        }

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    }
}
