import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'customSearchPipe',
    pure: false
})
export class CustomSearchPipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(items: any[], args: { search: string, searchBy: string[] }): any[] {
        if (!items || !args.search || !args.searchBy) {
            return items;
        }

        const searchText = args.search.trim().toLowerCase();

        return items.filter(item => {
            return args.searchBy.some(key => {
                const keys = key.split('.');
                let value = item;
                for (const k of keys) {
                    if (value) {
                        value = value[k];
                    } else {
                        return false;
                    }
                }
                if (key === 'check_type') {
                    // Сравниваем на основе текста, а не числа
                    const checkTypeName = this.getCheckTypeName(value);
                    return checkTypeName.toLowerCase().includes(searchText);
                }
                // Сравниваем строковые значения на вхождение
                return value?.toString().toLowerCase().includes(searchText);
            });
        });
    }

    // Метод для получения названия типа чека по числовому значению
    private getCheckTypeName(checkType: number): string {
        switch (checkType) {
            case 1: return this.translate.instant('PRINTERS.CLIENT_CHECK');
            case 2: return this.translate.instant('PRINTERS.KITCHEN_CHECK');
            case 4: return this.translate.instant('PRINTERS.HOT_KITCHEN_CHECK');
            case 8: return this.translate.instant('PRINTERS.DELIVERY_RECEIPT');
            default: return '';
        }
    }
}
