import {Component, OnInit} from '@angular/core';
import {PackagePanelService} from '../../@shared/packagepanel/packagepanel-leftblock/packagepanel-leftblock.service';
import {RequestService} from '../../@shared/request.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Alltoasts} from '../../toasts/alltoasts';
import {AllQuicksaleService} from '../../@shared/quick-sale/all-quick-sale/all-quicksale.service';

@Component({
    selector: 'app-packagepanel-mainblock',
    templateUrl: './packagepanel-mainblock.component.html',
    styleUrls: ['./packagepanel-mainblock.component.scss']
})
export class PackagepanelMainblockComponent implements OnInit {

    numbers: number[] = [];
    searchText = '';
    searchResults: string[] = [];
    digitRows: number[][] = [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [0]
    ];


    constructor(public service: PackagePanelService,
                public pservice: AllQuicksaleService,
                public Request: RequestService,
                private modalService: NgbModal,
                private toasts: Alltoasts, public qservice: AllQuicksaleService) {
    }


    removeLastDigit(): void {
            this.pservice.searchTerm = this.pservice.searchTerm.slice(0, -1);
            this.performLiveSearch();
    }
    addDigitToSearch(digit: string): void {
        this.pservice.searchTerm += digit;
        this.performLiveSearch();
    }

    performLiveSearch(): void {
        this.searchResults = this.digitRows
            .filter(digit => digit.toString().includes(this.searchText))
            .map(digit => digit.toString());
    }



    open = (content, size?) => {
        this.modalService.open(content, {size: (size) ?? 'lg'});
    }

    ngOnInit(): void {
        this.service.allCookers();
        this.pservice.onlyOrderNumber = true;
    }
    selectCooker = id => {
       for (const input of this.service.saleData.products){
           input.cooker_id = id;
           if (input.collapsed === false){
               for (const inputConsist of input.set_products){
                   inputConsist.cooker_id = id;
               }
           }
       }
       this.modalService.dismissAll();
    }
    selectPackager = (id, name) => {
        this.service.packagerId = id;
        this.service.packagerName = name;
        this.modalService.dismissAll();
    }
    packageOrder = () => {
        if (this.validation() && this.service.packagerId){
           const order = this.service.updateOrder(this.service.packagerId).subscribe(json => {
                },
                () => {
                    this.service.loader = false;
                },
                () => {
                    this.service.loader = false;
                    this.service.saleData = null;
                    this.qservice.fetchAll();
                    order.unsubscribe();
                    this.pservice.searchTerm = '';
                });
        }else{
            this.toasts.showDanger('Заповніть всі поля!');
        }
    }
    unpackageOrder = () => {
        this.service.unpackageOrder();
    }
    validation = () => {
        for (const input of this.service.saleData.products){
            if (input.collapsed === true && input.cooker_id){
                return true;
            }else if (input.collapsed === false){
                for (const inputConsist of input.set_products){
                    if (inputConsist.cooker_id){
                        return true;
                    }
                }
            }else if (!input.collapsed && !input.set_products.length &&  input.cooker_id) {
                return true;
            }
        else {
                return false;
            }
        }
    }

    closeModal(): void {
        this.service.saleData = null; // или любое значение, которое делает *ngIf="!service.saleData" истинным
    }


}
